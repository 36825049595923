import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { setEntityId } from 'src/app/_state/entity/entity.actions';

@Injectable()
export class EntityGuard implements CanActivate {
	constructor(private store: Store) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		this.store.dispatch(setEntityId({ entityId: route.params.entityId }));

		return new Observable(observer => {
			observer.next(true);
			return observer.complete();
		});
	}
}
