<div class="m-portlet m-portlet--mobile m-portlet--head-sm" style="min-height: 312px">
	<div class="m-portlet__head">
		<div class="m-portlet__head-caption">
			<div class="m-portlet__head-title">
				<h3 class="m-portlet__head-text">Accounts Exports</h3>
			</div>
		</div>
	</div>
	<div class="m-portlet__body">
		<div class="m-stack m-stack--ver m-stack--general" style="min-height: 180px">
			<div class="m-stack__item m-stack__item--center m-stack__item--middle">
				<div class="pb-3">
					<button
						*ngIf="!isLoadingGeneralLedger"
						type="button"
						class="btn btn-outline-brand btn-sm m-btn btn-block col-6"
						data-toggle="modal"
						data-target="#general_ledger_modal"
					>
						General Ledger
					</button>
					<button *ngIf="isLoadingGeneralLedger" type="button" disabled class="btn btn-brand btn-sm m-btn btn-block col-6 m-loader m-loader--light m-loader--right">
						Loading...
					</button>
				</div>
				<div class="pb-3">
					<button
						*ngIf="!isLoadingTrialBalance"
						type="button"
						class="btn btn-outline-brand btn-sm m-btn btn-block col-6"
						data-toggle="modal"
						data-target="#trial_balance_modal"
						(click)="isLoadingTrialBalance = !isLoadingTrialBalance; toggleModal()"
					>
						Trial Balance
					</button>
					<button *ngIf="isLoadingTrialBalance" type="button" disabled class="btn btn-brand btn-sm m-btn btn-block col-6 m-loader m-loader--light m-loader--right">
						Loading...
					</button>
				</div>
				<div>
					<button *ngIf="!isLoadingTermsAndConditions" type="button" class="btn btn-outline-brand btn-sm m-btn btn-block col-6" (click)="generateTermsAndConditions()">
						Terms & Conditions
					</button>
					<button *ngIf="isLoadingTermsAndConditions" type="button" disabled class="btn btn-brand btn-sm m-btn btn-block col-6 m-loader m-loader--light m-loader--right">
						Processing...
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="general_ledger_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
	<app-accounts-report-general-ledger-form></app-accounts-report-general-ledger-form>
</div>

<div class="modal fade" id="trial_balance_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header m--bg-brand">
				<h5 class="modal-title text-white" id="exampleModalLabel">Trial Balance</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="isLoadingTrialBalance = false"><span aria-hidden="true">&times;</span></button>
			</div>
			<div class="modal-body">
				<form [formGroup]="trailBalanceForm">
					<div class="form-group m-form__group">
						<label for="exampleSelect1">Month</label>
						<select class="form-control m-input m-input--square" name="month" formControlName="month">
							<option value="" selected>Please select...</option>
							<option *ngFor="let month of monthsList" [value]="month.value">{{ month.name }}</option>
						</select>
					</div>
				</form>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" (click)="isLoadingTrialBalance = false">Close</button>
				<span>
					<button type="submit" class="btn btn-brand btn-sm" data-dismiss="modal" (click)="generateTrialBalance()">Submit</button>
					<!-- <button disabled class="btn btn-brand m-loader m-loader--light m-loader--right">
            Submitting
          </button> -->
				</span>
			</div>
		</div>
	</div>
</div>
