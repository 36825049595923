export interface Statement {
	invoiceDetails?: InvoiceDetails;
	clientDetails?: ClientDetails;
	clientStatementEntries?: ClientStatementEntries;
	occDetails?: OccDetails;
	template?: string;
	statementType?: string;
}

interface OccDetails {
	occName?: string;
	occPhone?: string;
	occFax?: string;
	occEmail?: string;
}

interface InvoiceDetails {
	complexName?: string;
	unitNumber?: string;
	unitPhysical1?: string;
	unitPhysical2?: string;
	statementDate?: string;
	invoiceNum?: string;
	complexVatNumber?: string;
	accountNum?: string;
	addressee?: string;
	dueDate?: string;
	unitVatNumber?: string;
	amountDue?: string;
	vat_number?: any;
}

interface ClientDetails {
	emails?: any;
	vat_number?: any;
	postal1?: string;
	postal2?: string;
	postal3?: string;
}

interface ClientStatementEntries {
	entries?: StatementEntry[];
	totals?: StatementTotals;
}

interface StatementEntry {
	transNow?: string;
	reference?: string;
	credit?: string;
	debit?: string;
	description?: string;
	transdate?: string;
	property?: string;
}

interface StatementTotals {
	amountDue?: string;
	transNow?: string;
	balanceBFDebit?: string;
	balanceBFCredit?: string;
}

export class StatementClass {
	invoiceDetails = {
		complexName: '',
		unitNumber: '',
		unitPhysical1: '',
		unitPhysical2: '',
		statementDate: '',
		invoiceNum: '',
		complexVatNumber: '',
		accountNum: '',
		addressee: '',
		dueDate: '',
		unitVatNumber: '',
		amountDue: '',
		vat_number: '',
	};
	clientDetails = {
		emails: [],
		vat_number: '',
		postal1: '',
		postal2: '',
		postal3: '',
	};
	clientStatementEntries = {
		entries: [],
		totals: {
			amountDue: '',
			transNow: '',
			balanceBFDebit: '',
		},
	};
	occDetails = {
		occName: '',
		occPhone: '',
		occFax: '',
		occEmail: '',
	};
	template: 'default';
}
