import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { FirebaseApp } from '@angular/fire';
import { Group } from './group.model';
import { Member } from '../members/member.model';
import { User } from '../../plex/users/user.model';
import { AuditLogService } from '../../plex/audit-log/audit-log.service';

import * as firebase from 'firebase/app';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare let toastr: any;

@Injectable()
export class GroupsService {
	entityMembersCollection: AngularFirestoreCollection<Member[]>;
	memberDoc: AngularFirestoreDocument<Member>;
	groupDoc: AngularFirestoreDocument<Group>;
	usersCollection: AngularFirestoreCollection<User[]>;
	entityId: string;

	constructor(public afs: AngularFirestore, public router: Router, public fb: FirebaseApp, private auditLogService: AuditLogService, private store: Store) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	// TEAMS

	fetchEntityGroups() {
		const groupsRef = this.afs.collection(`entities/${this.entityId}/groups`, ref => ref.where('active', '==', true).orderBy('name', 'asc'));

		return groupsRef.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Group;
					data.uid = a.payload.doc.id;
					return data;
				});
			})
		);
	}

	fetchGroup(groupId: string) {
		return this.afs.doc(`entities/${this.entityId}/groups/${groupId}`).valueChanges();
	}

	fetchGroupMembers(groupId) {
		return this.afs.collection(`entities/${this.entityId}/groups/${groupId}/members`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'uid' });
	}

	fetchGroupMembersList(memberId) {
		return this.afs.doc(`entities/${this.entityId}/members/${memberId}`).valueChanges();
	}

	fetchAllMembers() {
		return this.afs
			.collection(`entities/${this.entityId}/members`, ref => ref.where('active', '==', true))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data: any = a.payload.doc.data();
						data.uid = a.payload.doc.id;
						data.fullName = data.firstname + ' ' + data.lastname;
						return data;
					});
				})
			);
	}

	addGroup(group: Group, memberId) {
		const userID = sessionStorage.getItem('userId');

		group.createdBy = userID;
		if (memberId != '') {
			return this.validationHandler(group, memberId).then(result => {
				return result;
			});
		} else {
			group.active = true;
			group.created = Date.now();
			return this.afs
				.collection(`entities/${this.entityId}/groups`)
				.add(group)
				.then(result => {
					return result;
				});
		}
	}

	updateGroup(group: Group, groupId) {
		let groupData = {};
		if (group.type !== 'Other') {
			groupData = Object.assign({}, group, { typeOther: firebase.default.firestore.FieldValue.delete() });
		} else {
			groupData = group;
		}
		this.groupDoc = this.afs.doc(`entities/${this.entityId}/groups/${groupId}`);

		return this.groupDoc.update(groupData);
	}

	addMemberToGroup(groupName: string, groupId: string, memberId: string, member) {
		return this.afs
			.collection(`entities/${this.entityId}/members`)
			.doc(`${memberId}`)
			.collection('groups')
			.doc(groupId)
			.set({ uid: groupId, active: true }, { merge: true })
			.then(() => {
				return this.afs.collection(`entities/${this.entityId}/groups/${groupId}/members`).doc(`${memberId}`).set(
					{
						active: true,
						memberId: memberId,
						groupId: groupId,
						firstname: member.firstname,
						lastname: member.lastname,
						memberTypes: member.memberTypes,
					},
					{ merge: true }
				);
			});
	}

	removeMemberFromGroup(groupId: string, memberId: string) {
		const memberDoc = this.afs.collection(`entities/${this.entityId}/members`).doc(`${memberId}/groups/${groupId}`);
		const groupMemberDoc = this.afs.collection(`entities/${this.entityId}/groups/${groupId}/members`).doc(`${memberId}`);
		return memberDoc.set({ active: false }, { merge: true }).then(() => {
			return groupMemberDoc.set({ active: false }, { merge: true });
		});
	}

	handleAddWithMember(memberId) {}

	validationHandler(handleData, memberId) {
		const fetchGroups = () =>
			new Promise(resolve => {
				this.fetchEntityGroups().subscribe(groupsData => {
					let tmpGroupsArray = groupsData.map(group => {
						return group;
					});
					resolve(tmpGroupsArray);
				});
			});

		const handleCombineRelated = (groupsData, getData, memberID) =>
			new Promise((resolve, reject) => {
				let tmpGroupsData = groupsData;
				let formData = {
					active: true,
					created: Date.now(),
					name: getData.name,
				};

				const groupsCollection = this.afs.collection(`entities/${this.entityId}/groups`);
				formData.active = true;

				let tmpArr = tmpGroupsData.filter(item => {
					return item.name == formData.name;
				});
				if (tmpArr.length > 0) {
					reject(`Group ${formData.name} already exists!`);
				} else {
					// PLAYER CAN BE ADDED
					if (memberID != '') {
						// HAS TEAM ID - FIND TEAM
						this.fetchGroupMembersList(memberID)
							.pipe(take(1))
							.subscribe(memberData => {
								if (memberData != null) {
									// TEAM FOUND
									groupsCollection.add(formData).then(result => {
										let tmpGroupId = result.id;
										const groupsMembersDoc = this.afs.doc(`entities/${this.entityId}/groups/${tmpGroupId}/members/${memberID}`);
										return groupsMembersDoc
											.set({
												active: true,
												memberId: memberID,
												groupId: tmpGroupId,
											})
											.then(() => {
												const memberDoc = this.afs.doc(`entities/${this.entityId}/members/${memberID}/groups/${tmpGroupId}`);
												memberDoc
													.set(
														{
															active: true,
															name: getData.name,
															uid: tmpGroupId,
														},
														{ merge: true }
													)
													.then(() => {
														this.router.navigate([`/${this.entityId}/faith/members/edit/${memberID}`]);
														resolve('Group added to member');
													});
											});
									});
								} else {
									// TEAM NOT FOUND - REJECT
									reject(`Member ${memberID} was not found!`);
								}
							});
					} else {
						groupsCollection.add(formData).then(result => {
							resolve(result);
						});
					}
				}
			});

		const getResults = async (getData, memberId) => {
			let groupsData = await fetchGroups();
			return handleCombineRelated(groupsData, getData, memberId);
		};

		return getResults(handleData, memberId)
			.then(result => {
				return result;
			})
			.catch(err => {
				toastr.error(err);
				return err;
			});
	}

	updateGroupMember(groupId: string, member: Member) {
		const groupMember = this.afs.doc(`/entities/${this.entityId}/groups/${groupId}/members/${member.uid}`);
		return groupMember.update(member);
	}

	setGroupInactive(group: Group) {
		const groupRef = this.afs.doc(`/entities/${this.entityId}/groups/${group.id}`);

		const updateGroup = groupRef.update({
			active: false,
		});

		return Promise.all([updateGroup]).then(() => {
			toastr.success('Group removed successfully!');
			let logData = {
				name: group.name,
				type: 'remove',
				description: 'Group was removed',
				category: 'groups',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}
}
