import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Audit } from './audit.model';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { User } from '../users/user.model';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

@Injectable()
export class AuditLogService {
	auditTrailCollection: AngularFirestoreCollection<Audit>;
	auditTrial = new Subject<Audit[]>();
	audit = new Subject<Audit>();
	auditDoc: AngularFirestoreDocument<Audit>;
	auditTrialList: Observable<any>;
	currentAudits: Audit[];
	currentUser: User;
	userEntitiesCollection: AngularFirestoreCollection<any[]>;
	entityId: any;

	constructor(public auth: AuthenticationService, public afs: AngularFirestore, public router: Router, private store: Store) {
		//FETCH LOGGED IN USER
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		this.auth.user.subscribe(userDetails => {
			if (userDetails) {
				this.currentUser = userDetails;
			}
		});
	}

	//ADD AUDIT/S
	async addAudit(auditDetails: Audit, entityIdIn?: string) {
		//GET SCHEME ID
		if (entityIdIn) {
			this.entityId = entityIdIn;
		}

		const { category = '', userId } = auditDetails;
		const { firstname = '', surname = '', uid = '' } = this.currentUser;

		auditDetails.firstname = firstname;
		auditDetails.surname = surname;
		auditDetails.createdBy = uid;
		auditDetails.created = Date.now();

		if (this.entityId != null) {
			this.auditTrailCollection = this.afs.collection(`entities/${this.entityId}/auditTrail`);
			return this.auditTrailCollection.add(auditDetails);
		} else {
			if (category === 'user') {
				if (userId != '' || userId != undefined) {
					const entitySnaps = (await this.getUserEntities(userId)).docs;

					for (const entitySnap of entitySnaps) {
						this.auditTrailCollection = this.afs.collection(`/entities/${entitySnap.id}/auditTrail`);
						await this.auditTrailCollection.add(auditDetails);
					}
				}
			}
		}
	}

	getUserEntities(userId: string) {
		return this.afs.collection(`users/${userId}/entities`).ref.get();
	}

	//FETCH AUDITS
	fetchSchemeAuditTrail() {
		this.auditTrailCollection = this.afs.collection(`entities/${this.entityId}/auditTrail`, ref => ref.orderBy('created', 'desc').limit(50));

		this.auditTrialList = this.auditTrailCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Audit;
					data.uid = a.payload.doc.id;
					if (data.name) {
						data.description = `${data.description} - ${data.name}`;
					}
					return data;
				});
			})
		);

		this.auditTrialList.subscribe(AuditData => {
			this.auditTrial.next(AuditData);
			this.currentAudits = AuditData;
		});
	}
}
