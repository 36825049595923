import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { AuthenticationService } from '../../../../../../auth/_services';

@Injectable({
	providedIn: 'root',
})
export class ManagersService {
	entityId: any;
	constructor(public auth: AuthenticationService, public afs: AngularFirestore, private store: Store) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	getSchemeManagers() {
		return this.afs
			.collection(`entities/${this.entityId}/management/users/list`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true).where('isSchemeManager', '==', true))
			.valueChanges({ idField: 'id' });
	}
}
