import { FinService } from './../../fin/services/fin.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EntityAccountsViewComponent } from './entity-accounts-view/entity-accounts-view.component';
import { EntityAccountsAddComponent } from './entity-accounts-add/entity-accounts-add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
	declarations: [EntityAccountsViewComponent, EntityAccountsAddComponent],
	imports: [
		CommonModule,
		RouterModule,
		NgxPermissionsModule.forChild(),
		//LayoutModule,
		FormsModule,
		ReactiveFormsModule,
		NgPipesModule,
	],
	exports: [CommonModule, EntityAccountsViewComponent, EntityAccountsAddComponent],
	providers: [],
})
export class EntityAccountsModule {}
