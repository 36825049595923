import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TasksService } from '../../../plex/tasks/tasks.service';
declare const moment: any;

@Component({
	selector: 'app-activities-tab',
	templateUrl: './activities-tab.component.html',
	styleUrls: ['./activities-tab.component.scss'],
})
export class ActivitiesTabComponent implements OnInit, OnDestroy {
	// inputs from amiti-plex2-admin\www\src\app\theme\layouts\header-nav\header-nav.component.html
	@Input() activities: any;
	@Input() loading: boolean;

	public unreadActivities = [];
	public readActivities = [];

	constructor(private tasksService: TasksService) {}

	ngOnInit() {
		this.tasksService.getUserWhitfieldsLastReadDate().subscribe((lastRead: any) => {
			const lastReadDate = new Date(lastRead.date);

			this.activities.forEach((activity: any) => {
				const actDate = new Date(parseInt(`${activity.created.seconds}${activity.created.nanoseconds}`) / 1000000);

				if (actDate > lastReadDate) {
					activity.created = this.generateLastEdited(activity.created.toDate());
					this.unreadActivities.push(activity);
				} else {
					activity.created = this.generateLastEdited(activity.created.toDate());
					this.readActivities.push(activity);
				}
			});
		});
	}

	// create last edited text
	generateLastEdited(timestamp: any) {
		return `${moment(timestamp).fromNow()}`;
	}

	ngOnDestroy() {}
}
