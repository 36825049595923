import { Injectable } from '@angular/core';
import { Subject } from '../../../node_modules/rxjs';

@Injectable()
export class PopOverService {
	showPopover: boolean;
	showPopoverChange: Subject<boolean> = new Subject<boolean>();

	public constructor() {
		this.showPopoverChange.subscribe(value => {
			this.showPopover = value;
		});
	}

	toggleHelpVisibility() {
		this.showPopoverChange.next(!this.showPopover);
	}

	hideHelp() {
		this.showPopoverChange.next(false);
	}
}
