import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Note } from './note.model';
import { User } from '../users/user.model';
import { map } from 'rxjs/operators';
import { UsersService } from '../users/users.service';
import { AddedField } from '../users/models/index.barrel';
import { PropertiesService } from '../properties/properties.service';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
declare var toastr: any;

@Injectable()
export class NotesService {
	notesCollection: AngularFirestoreCollection<Note[]>;
	userDoc: AngularFirestoreDocument<User>;
	entityId: any;

	constructor(public afs: AngularFirestore, public router: Router, private usersService: UsersService, private propertiesService: PropertiesService, private store: Store) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	addNote(ref_path, content) {
		content.entity_id = this.entityId;
		this.notesCollection = this.afs.collection(`${ref_path}/notes`);

		return this.notesCollection
			.add(content)
			.then(() => {
				const splitPath: string[] = ref_path.split('/');

				const noteType = splitPath[0];
				const userId = splitPath[1];
				const propertyId = splitPath[3];

				if (noteType === 'users' || noteType === 'entities') this.addNoteHistory(content.content, userId, propertyId, noteType);

				toastr.success('Note was successfully added');
			})
			.catch(error => {
				toastr.error('Note could not be added! Please try again.');
			});
	}

	private addNoteHistory(content: string, userID: string, propertyId: string, noteType: 'users' | 'entities'): void {
		const changedField: AddedField = {
			field: 'Added note',
			value: content,
			fieldAction: 'added',
		};

		if (noteType === 'users') this.usersService.addHistoryLogToUser([changedField], 'notes', userID, true);
		if (noteType === 'entities') this.propertiesService.addHistoryLogToProperty([changedField], propertyId, 'note', true);
	}

	fetchNotes(ref_path) {
		this.notesCollection = this.afs.collection(`${ref_path}/notes`, ref => ref.orderBy('created', 'desc'));

		return this.notesCollection.valueChanges({ idField: 'uid' });
	}

	fetchNoteUser(user_path) {
		this.userDoc = this.afs.doc(`${user_path}`);
		return this.userDoc.valueChanges();
	}
}
