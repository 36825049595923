import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PreviousRouteService {
	private previousUrl: string;
	private currentUrl: string;

	constructor(private router: Router) {
		this.currentUrl = this.router.url;
		router.events.pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event: NavigationStart | NavigationEnd) => {
			if (event instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			}
		});
	}

	public getPrevUrl = (defaultRoute: string): string => {
		return this.previousUrl && this.previousUrl !== '/' && this.previousUrl !== this.currentUrl ? this.previousUrl : defaultRoute;
	};

	public goToPrevUrl = (defaultRoute: string): Promise<boolean> => this.router.navigateByUrl(this.getPrevUrl(defaultRoute));
}
