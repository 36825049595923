import { createAction, props } from '@ngrx/store';
import dayjs from 'dayjs';

// TODO: check if this is model is good?
import { Entity, IncomingEntity } from 'src/app/theme/pages/default/plex/entities/entities.model';
import { environment } from '../../../environments/environment';

function convertDate(date) {
	const idealDateFormat = 'DD/MM/YYYY';

	if (date) {
		if (date.seconds) {
			return dayjs(date.toDate()).format(idealDateFormat);
		}

		if (typeof date === 'string') {
			console.warn(`[Noldor Standards] Found "${date}" stored as a string, it is standard to save these as dates.`);

			if (dayjs(date, idealDateFormat).isValid()) {
				return date;
			}
		}

		console.warn(`[Noldor Standards] Could not convert "${date}", it is standard to save these as dates.`);
	}

	return '';
}

export const setEntityId = createAction('[Entity] Set entity id', props<{ entityId: string }>());
export const setEntityData = createAction('[Entity] Set enitity data', (incomingEntity: IncomingEntity | Entity) => {
	const entityData = {
		...incomingEntity,
		incorporation_date: convertDate(incomingEntity.incorporation_date),
		dateTakeOn: convertDate(incomingEntity.dateTakeOn),
		ownBankAccountDate: convertDate(incomingEntity.ownBankAccountDate),
		agmDate: convertDate(incomingEntity.agmDate),
		fireServiceDate: convertDate(incomingEntity.fireServiceDate),
		previousAgmDate: convertDate(incomingEntity.previousAgmDate),
		sgmDate: convertDate(incomingEntity.sgmDate),
		previousSgmDate: convertDate(incomingEntity.previousSgmDate),
		executiveMeetingDate: convertDate(incomingEntity.executiveMeetingDate),
		previousExecutiveMeetingDate: convertDate(incomingEntity.previousExecutiveMeetingDate),
		clientWebsiteUrl: `https://${incomingEntity.domain}.${environment.client}`,
	};
	return { entityData };
});
export const setEntityWhitfieldsPrefix = createAction('[Entity] Set entity whitfields prefix', props<{ whitfieldsPrefix: string }>());
export const setProductAllowedEntities = createAction('[Entity] Set product allowed entities', props<{ allowedEntities: string[] }>());
