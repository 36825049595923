import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { MReportsService } from '../../m-reports.service';
import { SchemesService } from '../../../entities/entities.service';
import { Entity } from '../../../../management/entities/entities.model';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare const moment: any;
declare const toastr: any;
declare const $: any;

@Component({
	selector: 'app-accounts-report',
	templateUrl: './accounts-report.component.html',
	styleUrls: ['./accounts-report.component.scss'],
})
export class AccountsReportComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	trailBalanceForm: FormGroup;
	isLoadingTrialBalance = false;
	isLoadingGeneralLedger = false;
	date;
	monthsList = [];
	currentMonth;
	items: FormArray;
	entity: any;
	entitySubscription: Subscription;
	isLoadingTermsAndConditions = false;
	entityId: string;

	constructor(private mReportsService: MReportsService, private formBuilder: FormBuilder, private schemeService: SchemesService, private store: Store) {}

	ngOnInit() {
		this.store
			.select(selectEntityId)
			.pipe(take(1))
			.subscribe(id => {
				this.entityId = id;
			});

		this.currentMonth = moment().format('M YYYY');
		this.date = moment().format('YYYY-MM-DD');

		this.trailBalanceForm = this.formBuilder.group({
			month: [this.currentMonth, [Validators.required]],
		});

		this.entitySubscription = this.schemeService.getEntityDetails(this.entityId).subscribe((entity: Entity) => {
			this.entity = entity;
		});

		let lastMonth = moment().subtract(8, 'months');
		for (let i = 0; i <= 8; i++) {
			this.monthsList.unshift({ name: moment(lastMonth).add(i, 'months').format('MMMM YYYY'), value: moment(lastMonth).add(i, 'months').format('M YYYY') });
		}
	}

	toggleModal() {
		if (this.isLoadingTrialBalance) {
			$('#trial_balance_modal').modal({ backdrop: 'static', keyboard: false });
		}
	}

	generateTrialBalance() {
		this.isLoadingTrialBalance = true;
		if (this.trailBalanceForm.valid) {
			this.subscriptions.add(
				this.mReportsService
					.getTrialBalance(this.entity.whitfieldsPrefix, moment().format('DD M YYYY'), this.trailBalanceForm.value['month'], this.entity['financial_year_end'])
					.pipe(take(1))
					.subscribe((data: any) => {
						if (!data.error) {
							const IncomeExpenses = data.ledgers.IncomeExpenses.map((item: any) => {
								return { account: item.account, debitTotal: item.debitTotal, creditTotal: item.creditTotal };
							});

							const BalanceSheet = data.ledgers.BalanceSheet.map((item: any) => {
								return { account: item.account, debitTotal: item.debitTotal, creditTotal: item.creditTotal };
							});

							const build = [
								{ account: 'Trial Balance - ' + data.complex + ' (' + data.beginDate + ' - ' + data.endDate + ')', debitTotal: '', creditTotal: '' },
								{ account: 'Income Statement', debitTotal: '', creditTotal: '' },
								{ account: 'Account', debitTotal: 'Debit', creditTotal: 'Credit' },
								...IncomeExpenses,
								{ account: '', debitTotal: data.finalIncomeExpensesDebitTotal, creditTotal: data.finalIncomeExpensesCreditTotal },

								{ account: '', debitTotal: '', creditTotal: '' },
								{ account: 'Balance Sheet', debitTotal: '', creditTotal: '' },
								{ account: 'Account', debitTotal: 'Debit', creditTotal: 'Credit' },
								...BalanceSheet,
								{ account: '', debitTotal: data.finalBalanceSheetDebitTotal, creditTotal: data.finalBalanceSheetCreditTotal },
							];

							new ngxCsv(build, `${data.complex}_Trial_Balance_${data.endDate}`);
							this.isLoadingTrialBalance = false;
						} else {
							this.isLoadingTrialBalance = false;
							toastr.error(data.error);
						}
					})
			);
		} else {
			toastr.error('Invalid form. Please try again!');
			this.isLoadingTrialBalance = false;
		}
	}

	generateTermsAndConditions() {
		this.isLoadingTermsAndConditions = true;
		this.subscriptions.add(
			this.mReportsService.getTermsAndConditions(this.entity.whitfieldsPrefix).subscribe(data => {
				let options = {
					showLabels: true,
					showTitle: true,
					title: 'Terms and Conditions',
					noDownload: false,
					headers: ['T&C Date', 'Primary Contact Email', 'Status', 'Account num.', 'Alt Contact Email', 'T&C By', 'Terms and Conditions'],
				};
				new ngxCsv(data, `Terms_and_Conditions_${this.date}`, options);
				this.isLoadingTermsAndConditions = false;
			})
		);
	}

	getUnique(arr, comp) {
		const unique = arr
			.map(e => e[comp])
			.map((e, i, final) => final.indexOf(e) === i && i)
			.filter(e => arr[e])
			.map(e => arr[e].type);
		return unique;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
