import { Component, OnInit, ViewEncapsulation, AfterViewInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Helpers } from '../../../helpers';
import { AuthenticationService } from '../../../auth/_services';
import { User } from '../../pages/default/plex/users/user.model';
import { EntitiesService } from '../../pages/default/plex/entities/entities.service';
import { SchemesService } from '../../pages/default/management/entities/entities.service';
import { Entity } from '../../pages/default/plex/entities/entities.model';
import { environment } from '../../../../environments/environment';
import { PopOverService } from '../../../_services/popovers.service';
import { ManagementService } from '../../pages/default/management/management.service';
import { config } from '../../../../../src/configs/configs';
import { TasksService } from '../../pages/default/plex/tasks/tasks.service';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { UsersService } from '../../pages/default/plex/users/users.service';

declare const mLayout: any;

@Component({
	selector: 'app-header-nav',
	templateUrl: './header-nav.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
	config = config[environment.product];
	user: User = {
		firstname: '',
		surname: '',
		email: '',
	};
	userDetails;
	userUid;
	entities: Entity[];
	navigationSubscription: Subscription;
	showHeaderMenu: boolean = false;
	showPopover: boolean;
	@Input() entity: Entity = { name: '' };
	@Input() entityId;

	showHelp: any;
	showHelpSubscription: Subscription;
	entitiesSubscription: Subscription;
	projectEntity: Entity;
	envType = environment.product;

	// for WHITFIELDS activities
	public myTaskCount = 0;
	public userActivities: any;
	public loadingNotificationTasks: boolean;
	private showingNotification = false;

	constructor(
		private tasksService: TasksService,
		private auth: AuthenticationService,
		private router: Router,
		public popOverService: PopOverService,
		private entitiesService: EntitiesService,
		private _router: Router,
		private managementService: ManagementService,
		private store: Store,
		private schemesService: SchemesService,
		private usersService: UsersService
	) {}
	ngOnInit() {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		this.auth.user.subscribe(userDetails => {
			this.user = userDetails;
			this.userUid = userDetails.uid ? userDetails.uid : '';
			this.fetchUserDetails();
		});
		this.showHeaderMenu = false;
		const urlChecker = () => {
			if (
				this.entityId !== 'whitfields' &&
				this.entityId !== 'can' &&
				this.entityId !== 'faith' &&
				this.entityId !== 'plex' &&
				this.entityId !== 'sports' &&
				this.entityId !== 'serve'
			) {
				this.showHeaderMenu = true;
			} else {
				if (this.entityId === 'can') {
					const restrictedRoutes = ['/can/can/entities', '/can/can/entities/add', '/profile'];
					if (this._router.url === '/can/entities/add') {
						this.router.navigate(['/can/can/entities']);
					}
					this.showHeaderMenu = restrictedRoutes.indexOf(this._router.url) != -1 ? false : true;
				} else {
					this.showHeaderMenu = false;
				}
			}
		};
		urlChecker();

		this.managementService.fetchProjectEntity().subscribe((entityData: Entity) => {
			this.projectEntity = entityData;
		});

		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				urlChecker();
			}
		});

		this.entitiesSubscription = this.entitiesService.getUsersEntities().subscribe(entitiesData => {
			this.entities = entitiesData;
		});

		//fetch task MY TASK count for this entitiy
		if (this.showHeaderMenu) {
			this.tasksService.getUserWhitfieldsLastReadDate().subscribe((lastRead: any) => {
				if (lastRead) {
					this.myTaskCount = 0;
					const lastReadDate = new Date(lastRead.date);

					this.tasksService.getUserWhitfieldsTasks().subscribe(tasks => {
						this.userActivities = tasks;

						tasks.forEach((activity: any) => {
							const actDate = new Date(parseInt(`${activity.created.seconds}${activity.created.nanoseconds}`) / 1000000);

							if (actDate > lastReadDate) {
								this.myTaskCount++;
							}
						});
					});
				}
			});
		}
	}
	fetchUserDetails() {
		this.usersService.fetchUserDetails(`/users/${this.userUid}`).subscribe((userData: User) => {
			this.userDetails = userData;
		});
	}

	clickedNotification() {
		this.showingNotification = !this.showingNotification;

		if (!this.showingNotification) {
			this.tasksService.updateUserWhitfieldsLastReadDate();
		}
	}

	navigateToTab(tabName) {
		this.router.navigate([`/${this.entityId}${this.config.entityEditUrl}`], { queryParams: { tab: tabName } });
	}

	checkTasks() {
		this.entitiesService.getTaskCounts().subscribe(taskcounts => {
			this.myTaskCount = taskcounts.length;
			if (this.envType == 'sports') {
				this.showHeaderMenu = false;
			}
		});
	}

	public async setEntityData(userEntity: Entity): Promise<void> {
		Helpers.setLoading(true);

		await this.entitiesService.forceEntityStateUpdate(userEntity.id);

		this.entitiesService
			.getEntityDetails(userEntity.id)
			.pipe(take(1))
			.subscribe(async entity => {
				await this.schemesService.setEntity({ ...entity, ...userEntity });
				this.router.navigate([`/${this.entityId}${this.config.dashboardUrl}`]);
				Helpers.setLoading(false);
			});
	}

	navigate() {
		this.router.navigate([`${this.config.entitiesUrl}`]);
	}

	ngAfterViewInit() {
		mLayout.initHeader();
	}

	navToClient() {
		window.open(`${this.entity.clientWebsiteUrl}`, '_blank');
	}

	navToWebsite(url) {
		window.open(`${url}`, '_blank');
	}

	toggleSidebar() {
		this.popOverService.toggleHelpVisibility();
	}

	logout() {
		this.auth.signOut();
	}
}
