import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { FirebaseApp } from '@angular/fire';
import { Team } from './team.model';
import { Player } from '../players/player.model';
import { User } from '../../plex/users/user.model';
import { AuditLogService } from '../../plex/audit-log/audit-log.service';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare let toastr: any;

@Injectable()
export class TeamsService {
	entityPlayersCollection: AngularFirestoreCollection<Player[]>;
	playerDoc: AngularFirestoreDocument<Player>;
	teamDoc: AngularFirestoreDocument<Team>;
	usersCollection: AngularFirestoreCollection<User[]>;
	entityId: string;

	constructor(public afs: AngularFirestore, public router: Router, public fb: FirebaseApp, private auditLogService: AuditLogService, private store: Store) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	// TEAMS

	fetchEntityTeamJoinRequests() {
		return this.afs.collection(`/entities/${this.entityId}/teamJoinRequests`).valueChanges({ idField: 'uid' });
	}

	toggleEntityTeamJoinRequest(uid: string, response: number) {
		const dir = `/entities/${this.entityId}/teamJoinRequests/${uid}`;
		return this.afs.doc(dir).set(
			{
				status: response,
				responseCreated: new Date().getTime(),
			},
			{
				merge: true,
			}
		);
	}

	fetchEntityTeams() {
		const teamsRef = this.afs.collection(`entities/${this.entityId}/teams`, ref => ref.where('active', '==', true).orderBy('name', 'asc'));

		return teamsRef.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Team;
					data.uid = a.payload.doc.id;
					return data;
				});
			})
		);
	}

	fetchEntityOpenTeams() {
		const teamsRef = this.afs.collection(`entities/${this.entityId}/teams`, ref => ref.where('active', '==', true).where('open', '==', true).orderBy('name', 'asc'));

		return teamsRef.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Team;
					data.uid = a.payload.doc.id;
					return data;
				});
			}),
			take(1)
		);
	}

	fetchTeam(teamId: string) {
		return this.afs.doc(`entities/${this.entityId}/teams/${teamId}`).valueChanges();
	}

	fetchTeamPlayers(teamId) {
		return this.afs.collection(`entities/${this.entityId}/teams/${teamId}/players`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'uid' });
	}

	fetchPlayerUsers(teamId, playerId) {
		return this.afs.collection(`entities/${this.entityId}/teams/${teamId}/players/${playerId}/users`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'uid' });
	}

	async fetchEmailAddressesForTeam(teamId) {
		const playerData = [];
		const playersList = await this.afs.collection(`entities/${this.entityId}/teams/${teamId}/players`).ref.where('active', '==', true).get();
		if (!playersList.empty) {
			for (const player of playersList.docs) {
				const playerUsers = await this.afs.collection(`entities/${this.entityId}/players/${player.id}/users`).ref.where('active', '==', true).get();
				if (!playerUsers.empty) {
					for (const user of playerUsers.docs) {
						const playerUser = user.data();

						if (playerUser['email']) {
							if (!playerData.some(e => e.email === playerUser['email'])) {
								playerData.push(playerUser);
							  }
							
						}
					}
				}
			}
		}
		return playerData;
	}

	async fetchCellNumbersForTeam(entityId, teamId) {
		const cellNumbers = [];
		const playersList = await this.afs.collection(`entities/${entityId}/teams/${teamId}/players`).ref.where('active', '==', true).get();
		if (!playersList.empty) {
			for (const player of playersList.docs) {
				const playerData = player.data();
				const playerUsers = await this.afs.collection(`entities/${this.entityId}/players/${player.id}/users`).ref.where('active', '==', true).get();
				if (!playerUsers.empty) {
					for (const user of playerUsers.docs) {
						const playerUser = user.data();
						const playerId = playerUser['uid'];
						const entityUser = await this.afs.doc(`entities/${this.entityId}/users/${playerId}`).ref.get();
						if (entityUser) {
							const userData = entityUser.data();
							if (userData['cell']) {
								if (cellNumbers.indexOf(userData['cell']) === -1) {
									cellNumbers.push(userData['cell']);
								}
							}
						}
					}
				}
			}
		}
		return Promise.resolve(cellNumbers);
	}

	async fetchCellNumbersForPlayers(entityId) {
		const cellNumbers = [];
		const playersList = await this.afs.collection(`entities/${entityId}/players`).ref.where('active', '==', true).get();
		if (!playersList.empty) {
			for (const player of playersList.docs) {
				const playerData = player.data();
				const playerUsers = await this.afs.collection(`entities/${this.entityId}/players/${player.id}/users`).ref.where('active', '==', true).get();
				if (!playerUsers.empty) {
					for (const user of playerUsers.docs) {
						const playerUser = user.data();
						const playerId = playerUser['uid'];
						const entityUser = await this.afs.doc(`entities/${this.entityId}/users/${playerId}`).ref.get();
						if (entityUser) {
							const userData = entityUser.data();
							if (userData['cell']) {
								if (cellNumbers.indexOf(userData['cell']) === -1) {
									cellNumbers.push(userData['cell']);
								}
							}
						}
					}
				}
			}
		}
		return Promise.resolve(cellNumbers);
	}

	fetchTeamPlayersList(playerId) {
		return this.afs.doc(`entities/${this.entityId}/players/${playerId}`).valueChanges();
	}

	fetchAllPlayers() {
		return this.afs
			.collection(`entities/${this.entityId}/players`, ref => ref.where('active', '==', true))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data: any = a.payload.doc.data();
						data.uid = a.payload.doc.id;
						data.fullName = data.firstname + ' ' + data.lastname;
						return data;
					});
				})
			);
	}

	addTeam(team: Team, playerId) {
		if (playerId != '') {
			return this.validationHandler(team, playerId).then(result => {
				return result;
			});
		} else {
			team.active = true;
			team.created = Date.now();
			return this.afs
				.collection(`entities/${this.entityId}/teams`)
				.add(team)
				.then(result => {
					return result;
				});
		}
	}

	updateTeam(team: Team, teamId) {
		this.teamDoc = this.afs.doc(`entities/${this.entityId}/teams/${teamId}`);

		return this.teamDoc.update(team);
	}

	addPlayerToTeam(teamName: string, teamId: string, playerId: string, player) {
		return this.afs
			.collection(`entities/${this.entityId}/players`)
			.doc(`${playerId}`)
			.collection('teams')
			.doc(teamId)
			.set({ name: teamName, uid: teamId, active: true }, { merge: true })
			.then(() => {
				return this.afs.collection(`entities/${this.entityId}/teams/${teamId}/players`).doc(`${playerId}`).set(
					{
						active: true,
						playerId: playerId,
						teamId: teamId,
						firstname: player.firstname,
						lastname: player.lastname,
						position: player.position,
						age: player.age,
						number: player.number,
					},
					{ merge: true }
				);
			});
	}

	removePlayerFromTeam(teamId: string, playerId: string) {
		const playerDoc = this.afs.collection(`entities/${this.entityId}/players`).doc(`${playerId}/teams/${teamId}`);
		const teamPlayerDoc = this.afs.collection(`entities/${this.entityId}/teams/${teamId}/players`).doc(`${playerId}`);
		return playerDoc.set({ active: false }, { merge: true }).then(() => {
			return teamPlayerDoc.set({ active: false }, { merge: true });
		});
	}

	handleAddWithPlayer(playerId) {}

	validationHandler(handleData, playerId) {
		const fetchTeams = () =>
			new Promise(resolve => {
				this.fetchEntityTeams().subscribe(teamsData => {
					let tmpTeamsArray = teamsData.map(team => {
						return team;
					});
					resolve(tmpTeamsArray);
				});
			});

		const handleCombineRelated = (teamsData, getData, playerID) =>
			new Promise((resolve, reject) => {
				let tmpTeamsData = teamsData;
				let formData = {
					active: true,
					created: Date.now(),
					name: getData.name,
				};

				const teamsCollection = this.afs.collection(`entities/${this.entityId}/teams`);
				formData.active = true;

				let tmpArr = tmpTeamsData.filter(item => {
					return item.name == formData.name;
				});
				if (tmpArr.length > 0) {
					reject(`Team ${formData.name} already exists!`);
				} else {
					// PLAYER CAN BE ADDED
					if (playerID != '') {
						// HAS TEAM ID - FIND TEAM
						this.fetchTeamPlayersList(playerID)
							.pipe(take(1))
							.subscribe(playerData => {
								if (playerData != null) {
									// TEAM FOUND
									teamsCollection.add(formData).then(result => {
										let tmpTeamId = result.id;
										const teamsPlayersDoc = this.afs.doc(`entities/${this.entityId}/teams/${tmpTeamId}/players/${playerID}`);
										return teamsPlayersDoc
											.set({
												active: true,
												playerId: playerID,
												teamId: tmpTeamId,
												position: getData.position,
												number: getData.number,
											})
											.then(() => {
												const playerDoc = this.afs.doc(`entities/${this.entityId}/players/${playerID}/teams/${tmpTeamId}`);
												playerDoc
													.set(
														{
															active: true,
															name: getData.name,
															uid: tmpTeamId,
														},
														{ merge: true }
													)
													.then(() => {
														this.router.navigate([`/${this.entityId}/sports/players/edit/${playerID}`]);
														resolve('Team added to player');
													});
											});
									});
								} else {
									// TEAM NOT FOUND - REJECT
									reject(`Player ${playerID} was not found!`);
								}
							});
					} else {
						teamsCollection.add(formData).then(result => {
							resolve(result);
						});
					}
				}
			});

		const getResults = async (getData, playerId) => {
			let teamsData = await fetchTeams();
			return handleCombineRelated(teamsData, getData, playerId);
		};

		return getResults(handleData, playerId)
			.then(result => {
				return result;
			})
			.catch(err => {
				toastr.error(err);
				return err;
			});
	}

	updateTeamPlayer(teamId: string, player: Player) {
		const teamPlayer = this.afs.doc(`/entities/${this.entityId}/teams/${teamId}/players/${player.id}`);

		return teamPlayer.update({
			position: player.position,
			number: player.number,
		});
	}

	setTeamInactive(team: Team) {
		const teamRef = this.afs.doc(`/entities/${this.entityId}/teams/${team.id}`);

		const updateTeam = teamRef.update({
			active: false,
		});

		return Promise.all([updateTeam]).then(() => {
			toastr.success('Team removed successfully!');
			let logData = {
				name: team.name,
				type: 'remove',
				description: 'Team was removed',
				category: 'teams',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}
}
