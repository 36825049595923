import { Injectable } from '@angular/core';
import { Attendee } from 'src/app/theme/pages/default/faith/events/events-list/_components/attendance-register/attendance-register.component';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthenticationService } from '@auth/_services/authentication.service';
import { Event, EventFile } from 'src/app/_shared/events/models';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/theme/pages/default/plex/users/user.model';
import { PanelActivitiesService } from 'src/app/_shared/panels/panel-activities/panel-activities.service';
import _ from 'lodash';
import { Entity } from 'src/app/theme/pages/default/plex/entities/entities.model';
import { filter, map } from 'rxjs/operators';
import { Moment } from 'moment';
import moment from 'moment';

@Injectable()
export class EventsService {
	entityType = environment.product;
	public saveEventForm = new BehaviorSubject<boolean>(false);
	public recordDepartureSubject = new BehaviorSubject(false);
	public lockedSubject = new BehaviorSubject(false);
	public lockedBookingSubject = new BehaviorSubject(false);

	constructor(public afs: AngularFirestore, private afStorage: AngularFireStorage, private auth: AuthenticationService, private panelActivitiesService: PanelActivitiesService) {}

	set recordDeparture(status: boolean) {
		this.recordDepartureSubject.next(status);
	}

	set locked(status: boolean) {
		this.lockedSubject.next(status);
	}

	set lockedBooking(status: boolean) {
		this.lockedBookingSubject.next(status);
	}

	get newFirestoreId(): string {
		return this.afs.createId();
	}

	getEvent(entityId: string, eventId: string) {
		const path = `entities/${entityId}/events/${eventId}`;
		return this.afs.doc(path).valueChanges() as Observable<any>;
	}

	lockEventRegister(entityId: string, eventId: string, registerLocked: boolean = true) {
		const path = `entities/${entityId}/events/${eventId}`;
		return this.afs.doc(path).update({ registerLocked });
	}

	lockEventBooking(entityId: string, eventId: string, bookingLocked: boolean = true) {
		const path = `entities/${entityId}/events/${eventId}`;
		return this.afs.doc(path).update({ bookingLocked });
	}

	getAttendees(entityId: string, eventId: string, registerType = 'registrations'): Observable<Attendee[]> {
		const path = `entities/${entityId}/events/${eventId}/${registerType}`;
		return this.afs.collection(path, ref => ref.orderBy('timeIn', 'asc')).valueChanges() as Observable<Attendee[]>;
	}

	modifyAttendee(entityId: string, eventId: string, { id, ...attendee }: Attendee, registerType = 'registrations'): Promise<void> {
		const path = `entities/${entityId}/events/${eventId}/${registerType}/${id}`;
		return this.afs.doc(path).set({ id, ...attendee, removed: false }, { merge: true });
	}

	removeAttendee(entityId: string, eventId: string, { id }: Attendee, registerType = 'registrations'): Promise<void> {
		const path = `entities/${entityId}/events/${eventId}/${registerType}/${id}`;
		return this.afs.doc(path).update({ removed: true });
	}

	getRegistrationEvents(entityId: string): Observable<any> {
		const path = `entities/${entityId}/events/`;
		return this.afs.collection(path, ref => ref.where('arrivalRegistration', '==', true).where('active', '==', true).orderBy('eventDate', 'asc')).valueChanges() as Observable<
			any[]
		>;
	}

	getBookingEvents(entityId: string): Observable<Event[]> {
		const path = `entities/${entityId}/events/`;
		return this.afs
			.collection<Event>(path, ref => ref.where('bookingRequired', '==', true).where('active', '==', true).orderBy('eventDate', 'asc'))
			.valueChanges();
	}

	generateFirebaseId() {
		return this.afs.createId();
	}

	getEvents(entityId) {
		const eventsRef = this.afs.collection<Event>(`entities/${entityId}/events`, ref => ref.where('active', '==', true).orderBy('eventDate', 'asc'));
		return eventsRef.valueChanges({ idField: 'uid' });
	}

	public getFilteredEvents(entityId: string, includeCancelled: boolean, includeFinishedDateRange?: { from: Moment; to: Moment }): Observable<Event[]> {
		const eventsRef = this.afs.collection<Event>(`entities/${entityId}/events`, ref => ref.where('active', '==', true).orderBy('eventDate', 'asc'));
		return eventsRef.valueChanges({ idField: 'uid' }).pipe(
			map(events =>
				events.filter(({ status, eventDate }) => {
					if (!eventDate) {
						return false;
					}

					const eventDateMoment = moment(new Date((eventDate as any).seconds * 1000));
					const currentMoment = moment(new Date()).startOf('day');

					if (!includeCancelled && !includeFinishedDateRange) {
						return currentMoment <= eventDateMoment && status !== 'Cancelled' && status !== 'Finished';
					}

					if (includeCancelled && includeFinishedDateRange) {
						const { from, to } = includeFinishedDateRange;
						const fitsDateParameters = eventDateMoment >= from && eventDateMoment <= to;
						return currentMoment <= eventDateMoment || status === 'Cancelled' || (status === 'Finished' && fitsDateParameters);
					}

					if (includeCancelled) {
						return status === 'Cancelled' || (currentMoment <= eventDateMoment && status !== 'Finished');
					}

					if (includeFinishedDateRange) {
						const { from, to } = includeFinishedDateRange;
						const fitsDateParameters = eventDateMoment >= from && eventDateMoment <= to;

						return (status === 'Finished' && fitsDateParameters) || (currentMoment <= eventDateMoment && status !== 'Cancelled');
					}
				})
			)
		);
	}

	fetchEntityEvent(entityId, eventId) {
		return this.afs.doc(`entities/${entityId}/events/${eventId}`).valueChanges();
	}

	fetchEntityVenues(entityId) {
		const venuesRef = this.afs.collection(`entities/${entityId}/venues`, ref => ref.where('active', '==', true).orderBy('name', 'asc'));
		return venuesRef.valueChanges({ idField: 'uid' });
	}

	fetchEventUsers(entityId) {
		const usersRef = this.afs.collection(`entities/${entityId}/management/users/list`, ref => ref.where('isAdmin', '==', true));
		return usersRef.valueChanges({ idField: 'id' }) as Observable<User[]>;
	}

	public saveEvent(afterEvent: Event, beforeEvent?: Event, users?: User[], entity?: Entity): Promise<void> {
		const { firstname, surname, uid } = this.auth.userDetails;
		const { entityId, creatorId, refNo } = afterEvent;

		const sentByUser = `${firstname} ${surname}`;
		afterEvent.createdBy = creatorId;
		afterEvent.createdByName = `${firstname} ${surname}`;
		afterEvent.product = this.entityType;
		const eventRefNo = refNo;

		const { eventType, coordinatorId, id, venueName } = afterEvent;
		const eventSubject = `New ${eventType} Created at ${venueName}`;

		return this.afs
			.doc(`entities/${entityId}/events/${id}`)
			.set(afterEvent, { merge: true })
			.then(() => {
				if (users) {
					this.notifyOfChanges(afterEvent, beforeEvent, users, entity);
				}
				if (coordinatorId !== uid) {
					this.eventCoordinatorInformCreated(entityId, id, coordinatorId, 'notifyEventCoordinatorEventCreated', eventType, sentByUser, eventSubject, eventRefNo);
				}
			});
	}

	private addEventEmailPendingRequest(
		entityId: string,
		event: Event,
		request: string,
		recipients: string[],
		entity: Entity,
		changed?: string[],
		pendingPath = 'pending'
	): Promise<DocumentReference> {
		const { admin, product } = environment;
		const { refNo, eventType, id, updated } = event;
		const pendingCollectionRef = this.afs.collection(pendingPath);
		const sentByUser = this.auth.userDetails.uid;
		const eventUrl = this.getEventUrl(entityId, id);

		return pendingCollectionRef.add({
			entityColour: `${entity.template.colour1}`,
			entityLogo: `${entity.template.downloadLogo}`,
			entityName: `${entity.name}`,
			entityUrl: `${entity.websiteUrl}`,
			admin,
			product,
			sentByUser,
			entityId,
			eventId: id,
			request,
			recipients,
			refNo,
			eventType,
			eventUrl,
			updated,
			changed,
		});
	}

	async updateEvent(entityId, eventId, type, value, entity: Entity, event?: Event, users?: User[]) {
		const recipients = [];
		if (type == 'eventTeam') {
			const eventTeam = value.filter(item => item !== '' && item);
			eventTeam.forEach(teamMember => {
				if (!event.eventTeam.includes(teamMember)) {
					recipients.push(teamMember);
				}
			});
			await this.afs.doc(`entities/${entityId}/events/${eventId}`).set({ eventTeam }, { merge: true });
		} else if (type == 'coordinator') {
			if (event.coordinatorId !== value) {
				recipients.push(value);
			}
			await this.afs.doc(`entities/${entityId}/events/${eventId}`).set({ coordinatorId: value }, { merge: true });
		} else if (type == 'watchers') {
			const watchers = value.filter(item => item !== '' && item);
			watchers.forEach(watcher => {
				if (!event.watchers.includes(watcher)) {
					recipients.push(watcher);
				}
			});
			await this.afs.doc(`entities/${entityId}/events/${eventId}`).set({ watchers }, { merge: true });
		}

		if (event && event.submitted && users) {
			return this.notifyNewAssignee(entityId, event, type, [...recipients.map((id: string) => users.find(user => user.id === id).email)], entity);
		} else {
			return Promise.resolve();
		}
	}

	public getEventUrl(entityId: string, eventId: string): string {
		const path = `/${entityId}/faith/events/edit/${eventId}`;
		const encodedBase64Data = btoa(path);
		return `${environment.admin}/redirect/${encodedBase64Data}`;
	}

	eventCoordinatorInformCreated(entityId, eventId, coordinatorId, request, eventType, sentByUser, eventSubject, eventRefNo) {
		const taskURL = this.getEventUrl(entityId, eventId);

		const { admin, product } = environment;

		let data = {
			request,
			entityId,
			eventId,
			eventRefNo,
			coordinatorId,
			sentByUser,
			eventSubject,
			admin,
			taskUrl: taskURL,
			product,
		};
		return this.addPendingDoc(data);
	}

	public notifyNewAssignee(entityId: string, event: Event, role: string, recipients: string[], entity: Entity): Promise<DocumentReference> {
		return this.addEventEmailPendingRequest(
			entityId,
			event,
			`faithNotifyEventAddedAs${_.upperFirst(role)}`,
			[...new Set(recipients)],
			entity,
			recipients.map(recipient => `${recipient} added as ${role}`)
		);
	}

	private notifyOfChanges(afterEvent: Event, beforeEvent: Event, users: User[], entity: Entity): Promise<DocumentReference> {
		if (afterEvent.submitted) {
			if (beforeEvent) {
				const updated = [];
				const recipients = [];
				const { entityId, watchers, eventTeam, coordinatorId } = beforeEvent;
				const { added, removed, changed } = this.panelActivitiesService.detectObjectChanges(beforeEvent, afterEvent, [
					'eventDate',
					'updated',
					'eventTeam',
					'watchers',
					'venueId',
				]);

				if (added) {
					updated.push(...added.map(({ after, name }) => `${_.startCase(name)} added as ${after}.`));
				}

				if (removed) {
					updated.push(...removed.map(({ before, name }) => `${_.startCase(name)} removed. Previously it was ${before}`));
				}

				if (changed) {
					updated.push(...changed.map(({ before, after, name }) => `${_.startCase(name)} changed from ${before} to ${after}`));
				}

				if (watchers) {
					recipients.push(...watchers.map(watcherId => users.find(user => user.id === watcherId).email));
				}

				if (eventTeam) {
					recipients.push(...eventTeam.map(memberId => users.find(user => user.id === memberId).email));
				}

				if (coordinatorId) {
					recipients.push(users.find(user => coordinatorId === user.id).email);
				}

				return this.addEventEmailPendingRequest(entityId, afterEvent, 'faithNotifyOfEventChanges', [...new Set(recipients)], entity, updated);
			}
		}
	}

	public fetchEventFiles(entityId: string, eventId: string, collectionName = 'files') {
		return this.afs
			.collection(`entities/${entityId}/events/${eventId}/${collectionName}`, ref => {
				return ref.where('active', '==', true);
			})
			.valueChanges({ idField: 'id' }) as Observable<EventFile[]>;
	}

	public async uploadFile(
		file: EventFile,
		creatorId: string,
		entityId: string,
		eventId: string,
		progress__?: (percentage: number) => void,
		result__?: (res: Error | String) => void,
		collectionName = 'files'
	): Promise<void> {
		return new Promise(async (res, rej) => {
			const { name, size, type, active, created, raw, id } = file;

			if (active) {
				const storageRef = this.afStorage.storage.ref(`entities/${entityId}/files/${name}`);
				const storageTask = storageRef.put(raw);

				storageTask.on(
					'state_changed',
					({ bytesTransferred, totalBytes }) => {
						let pct = Math.ceil((bytesTransferred / totalBytes) * 100);
						progress__(pct >= 5 ? pct - 5 : 0);
					},
					err => {
						result__(err);
						rej();
					},
					async () => {
						const { firstname, surname, uid } = this.auth.userDetails;
						const afsF: EventFile = {
							created,
							name,
							size,
							type,
							id,
							saved: true,
							active: true,
							createdByName: `${firstname} ${surname}`,
							createdByUID: creatorId,
							download: await storageRef.getDownloadURL(),
							path: `entities/${entityId}/${collectionName}/${name}`,
						};

						await this.afs.doc(`entities/${entityId}/events/${eventId}/${collectionName}/${id}`).set(afsF);
						progress__(100);
						result__('completed');
						res();
					}
				);
			} else {
				progress__(90);
				try {
					await this.afs.doc(`entities/${entityId}/events/${eventId}/${collectionName}/${file.id}`).update({
						active: false,
					});

					result__('completed');
				} catch (err) {
					result__(err);
				}
				res();
			}
		});
	}

	addPendingDoc(data) {
		return this.afs.collection('pending').add(data);
	}
}
