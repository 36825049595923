import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Injectable } from '@angular/core';
import { map, take, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

import { Member, HistoryEntry } from './member.model';
import { Group } from '../groups/group.model';
import { GroupsService } from '../groups/groups.service';
import { FamiliesService } from '../families/families.service';
import { User } from '../../plex/users/user.model';
import { UsersService } from '../../plex/users/users.service';
import { AuditLogService } from '../../plex/audit-log/audit-log.service';
import { GenerateThumbnailService } from '../../plex/_services/generate-thumbnail.service';
import { Family } from '../families/famalies.model';
import { Entity } from '../entities/entities.model';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
declare var toastr: any;

@Injectable()
export class MembersService {
	entityMembersCollection: AngularFirestoreCollection<Member[]>;
	memberDoc: AngularFirestoreDocument<Member>;
	usersCollection: AngularFirestoreCollection<User[]>;

	task: AngularFireUploadTask;
	progressBarValue;
	entityId: string;

	constructor(
		private auditLogService: AuditLogService,
		public afs: AngularFirestore,
		public router: Router,
		public usersService: UsersService,
		public groupsService: GroupsService,
		public familiesService: FamiliesService,
		private storage: AngularFireStorage,
		public generateThumbnailService: GenerateThumbnailService,
		private store: Store
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	fetchEntityMembers() {
		if (this.entityId) {
			this.entityMembersCollection = this.afs.collection(`entities/${this.entityId}/members`, ref => ref.where('active', '==', true));
			const membersRef = this.afs.doc(`entities/${this.entityId}`).ref;
			return this.entityMembersCollection.snapshotChanges().pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as any;
						data.fullname = `${data.firstname} ${data.lastname}`;
						data.uid = a.payload.doc.id;
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
		}
	}

	addMember(member) {
		// console.log('TCL: member', member)

		// CHECK IF MEMBER ALREADY ADDED TO ENTITY
		this.entityMembersCollection = this.afs.collection(`entities/${this.entityId}/members`, ref => ref.where('firstname', '==', member.firstname));

		return this.entityMembersCollection
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as Member;
						data.uid = a.payload.doc.id;
						return data;
					});
				}),
				take(1)
			)
			.toPromise()
			.then(entityMembersList => {
				// MEMBER NOT ADDED TO ENTITY SO CAN ADD MEMBER
				const membersRef = this.afs.collection(`entities/${this.entityId}/members`);

				const memberData = {
					active: true,
					firstname: member.firstname,
					lastname: member.lastname,
					img: member.img,
					tel: member.tel,
					email: member.email,
				};

				return membersRef
					.add(memberData)
					.then(ref => {
						const tmpMemberId = ref.id;
						const entityRef = this.afs.doc(`entities/${this.entityId}`);
						let membersCount = 0;
						const memberName = `${member.firstname} ${member.lastname}`;
						if (member.img !== '') {
							this.uploadMemberImage(this.entityId, member.img, memberName, tmpMemberId);
						}

						// INCREMENT PROPERTIES COUNT
						entityRef
							.snapshotChanges()
							.pipe(take(1))
							.toPromise()
							.then(snap => {
								const entityData = snap.payload.data();
								membersCount = entityData['membersCount'];
								membersCount++;

								entityRef.set(
									{
										membersCount: membersCount,
									},
									{ merge: true }
								);
							});

						// AUDIT LOG
						let logData = {
							name: `${member.firstname} ${member.lastname}`,
							description: 'Member was added',
							type: 'added',
							category: 'members',
							created: Date.now(),
						};
						this.auditLogService.addAudit(logData);

						return tmpMemberId;
					})
					.catch(err => {
						Promise.reject(err);
					});
			});
	}

	uploadMemberImage(entityID, img, memberName, refId) {
		const imgName = memberName.replace(/\s/g, '_').toLowerCase();
		const fileName = `${new Date().getTime()}_${imgName}`;
		const path = `entities/${entityID}/members/${fileName}.png`;
		const ref = this.storage.ref(path);
		const task = ref.putString(img, 'data_url');

		return new Promise((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();

						downloadURL.subscribe(url => {
							this.generateThumbnailService.generateFromImage(img, 333, 443, 1, thumbnailData => {
								const file = {
									img: url,
									img_thumbnail: thumbnailData,
								};
								this.saveDataToFirestore(entityID, file, refId)
									.then(() => {
										return resolve('');
									})
									.catch(err => {
										return reject('Failed to save data to firestore: ' + err);
									});
							});
						});
					})
				)
				.subscribe();
		});
	}

	private saveDataToFirestore(entityID, downloadData, refId) {
		const entityRef = this.afs.doc(`entities/${entityID}/members/${refId}`);

		return entityRef.update(downloadData);
	}

	addGroupToMember(memberData: Group, memberId: string) {
		return this.groupsService.addGroup(memberData, memberId);
	}

	addFamilyToMember(memberData: Family, memberId: string) {
		return this.familiesService.addFamily(memberData, memberId);
	}

	setMemberInactive(member) {
		const entityRef = this.afs.collection('entities').doc(this.entityId).ref;
		const entityMemberRef = this.afs.collection('entities').doc(this.entityId).collection('members').doc(member.uid);
		const memberUsersRef = this.afs.collection('entities').doc(this.entityId).collection('members').doc(member.uid).collection('users').ref;

		//REMOVE MEMBER FROM ENTITY
		const updateEntityMember = entityMemberRef.update({
			active: false,
		});

		// Remove member from group
		let updateGroupMember;
		const memberGroupsSubscription = this.fetchMemberGroups(member.uid).subscribe(groups => {
			groups.forEach(group => {
				const groupRef = this.afs.collection('entities').doc(this.entityId).collection('groups').doc(group['uid']).collection('members').doc(member.uid);
				updateGroupMember = groupRef.update({
					active: false,
				});
			});
		});

		// UPDATE ENTITY COUNT
		const updateMembersCount = entityRef.get().then(entityDetails => {
			const entityData = entityDetails.data() as Entity;
			let membersCount = 0;

			if (entityData.membersCount) {
				membersCount = entityData.membersCount;
				membersCount--;
			}

			return entityRef.update({
				membersCount: membersCount,
			});
		});

		// GET MEMBER USERS
		const updateMemberUsers = memberUsersRef.get().then(users => {
			return users.forEach(user => {
				const userData = user.data();

				return this.removeUserFromMember(userData.uid, member.uid, userData.type);
			});
		});

		return Promise.all([updateGroupMember, updateEntityMember, updateMembersCount, updateMemberUsers]).then(() => {
			toastr.success('Member removed successfully!');
			let logData = {
				name: member.name,
				description: 'Member was removed',
				type: 'remove',
				category: 'members',
				created: Date.now(),
			};
			memberGroupsSubscription.unsubscribe();
			this.auditLogService.addAudit(logData);
		});
	}

	fetchUserMembers(userUID: string) {
		return this.afs
			.collection('users')
			.doc(userUID)
			.collection('entities')
			.doc(this.entityId)
			.collection('members', ref => ref.where('active', '==', true).orderBy('firstname', 'asc'))
			.valueChanges({ idField: 'uid' });
	}

	fetchUserPrimaryMember(userUID: string) {
		return this.afs
			.collection('users')
			.doc(userUID)
			.collection('entities')
			.doc(this.entityId)
			.collection('members', ref => ref.where('active', '==', true).orderBy('firstname', 'asc'))
			.valueChanges({ idField: 'uid' });
	}

	fetchMemberUsers(memberUID: string) {
		return this.afs.collection(`entities/${this.entityId}/members/${memberUID}/users`, ref => ref.where('active', '==', true)).valueChanges();
	}

	fetchMemberDetails(uid: string) {
		this.memberDoc = this.afs.doc(`entities/${this.entityId}/members/${uid}`);
		return this.memberDoc.valueChanges();
	}

	fetchFamily(familyId, memberId) {
		const groupMemberDoc = this.afs.collection(`entities/${this.entityId}/families`).doc(`${familyId}`);
		return groupMemberDoc.valueChanges();
	}

	fetchAllFamilies() {
		const familiesCollection = this.afs.collection(`entities/${this.entityId}/families`, ref => ref.where('active', '==', true));

		return familiesCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Family;
					data.uid = a.payload.doc.id;
					return data;
				});
			}),
			take(1)
		);
	}

	fetchMemberFamilies(memberId) {
		return this.afs.collection(`entities/${this.entityId}/members/${memberId}/families`, ref => ref.where('active', '==', true)).valueChanges();
	}

	addExistingFamilyToMember(family, memberId, member) {
		// add family to member
		return this.afs
			.collection(`entities/${this.entityId}/members`)
			.doc(`${memberId}`)
			.collection('families')
			.doc(family.uid)
			.set({ uid: family.uid, active: true }, { merge: true })
			.then(() => {
				return this.afs.collection(`entities/${this.entityId}/families/${family.uid}/members`).doc(`${memberId}`).set(
					{
						active: true,
						memberId: memberId,
						familyId: family.uid,
						firstname: member.firstname,
						lastname: member.lastname,
					},
					{ merge: true }
				);
			});

		// add member to family
	}

	removeFamilyFromMember(familyId, memberId) {
		const memberDoc = this.afs.collection(`entities/${this.entityId}/members`).doc(`${memberId}/families/${familyId}`);
		const familyMemberDoc = this.afs.collection(`entities/${this.entityId}/families/${familyId}/members`).doc(`${memberId}`);
		return memberDoc.set({ active: false }, { merge: true }).then(() => {
			return familyMemberDoc.set({ active: false }, { merge: true });
		});
	}

	fetchGroup(groupId, memberId) {
		const groupMemberDoc = this.afs.collection(`entities/${this.entityId}/groups`).doc(`${groupId}`);
		return groupMemberDoc.valueChanges();
	}

	fetchAllGroups() {
		const groupsCollection = this.afs.collection(`entities/${this.entityId}/groups`, ref => ref.where('active', '==', true));

		return groupsCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Group;
					data.uid = a.payload.doc.id;
					return data;
				});
			}),
			take(1)
		);
	}

	fetchMemberGroups(memberId) {
		return this.afs.collection(`entities/${this.entityId}/members/${memberId}/groups`, ref => ref.where('active', '==', true)).valueChanges();
	}

	addExistingGroupToMember(group, memberId, member) {
		// add group to member
		return this.afs
			.collection(`entities/${this.entityId}/members`)
			.doc(`${memberId}`)
			.collection('groups')
			.doc(group.uid)
			.set({ uid: group.uid, active: true }, { merge: true })
			.then(() => {
				return this.afs.collection(`entities/${this.entityId}/groups/${group.uid}/members`).doc(`${memberId}`).set(
					{
						active: true,
						memberId: memberId,
						groupId: group.uid,
						firstname: member.firstname,
						lastname: member.lastname,
					},
					{ merge: true }
				);
			});

		// add member to group
	}

	removeGroupFromMember(groupId, memberId) {
		const memberDoc = this.afs.collection(`entities/${this.entityId}/members`).doc(`${memberId}/groups/${groupId}`);
		const groupMemberDoc = this.afs.collection(`entities/${this.entityId}/groups/${groupId}/members`).doc(`${memberId}`);
		return memberDoc.set({ active: false }, { merge: true }).then(() => {
			return groupMemberDoc.set({ active: false }, { merge: true });
		});
	}

	updateMember(member: Member) {
		this.memberDoc = this.afs.doc(`entities/${this.entityId}/members/${member.uid}`);

		if (member.dob) {
			const dob = new Date(`${member.dob['month']}-${member.dob['day']}-${member.dob['year']}`);
			member['dob'] = dob;
		}

		if (member.joined) {
			const joined = new Date(`${member.joined['month']}-${member.joined['day']}-${member.joined['year']}`);
			member['joined'] = joined;
		}

		return this.memberDoc
			.update(member)
			.then(() => {
				let logData = {
					name: `${member.firstname} ${member.lastname}`,
					description: 'Member was updated',
					type: 'update',
					category: 'members',
					created: Date.now(),
				};
				this.auditLogService.addAudit(logData);
			})
			.catch(error => {
				toastr.error('Member could not be updated! Please try again.');
			});
	}

	addUserToMember(userID: string, memberID: string, type: string, parent: boolean) {
		const userRef = this.afs.collection('users').doc(userID);
		const memberRef = this.afs.collection('entities').doc(this.entityId).collection('members').doc(memberID);
		let userData: User = {};

		// FETCH USER DETAILS
		return userRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(userDetails => {
				if (userDetails.payload.data()) {
					userData = userDetails.payload.data();

					// CHECK IF USER ALREADY ADDED TO MEMBER
					return memberRef
						.collection('users', ref => ref.where('uid', '==', userDetails.payload.id).where('active', '==', true))
						.snapshotChanges()
						.pipe(
							map(changes => {
								return changes.map(a => {
									const data = a.payload.doc.data() as User;
									data.uid = a.payload.doc.id;
									return data.uid;
								});
							}),
							take(1)
						)
						.toPromise()
						.then(usersUidCheckCollection => {
							if (usersUidCheckCollection.length === 0) {
								// CHECK IF TYPE ALREADY EXISTS
								return memberRef
									.collection('users', ref => ref.where('type', '==', type).where('active', '==', true))
									.snapshotChanges()
									.pipe(
										map(changes => {
											return changes.map(a => {
												const data = a.payload.doc.data() as User;
												data.uid = a.payload.doc.id;
												return data.uid;
											});
										}),
										take(1)
									)
									.toPromise()
									.then(usersCollection => {
										if (usersCollection.length === 0) {
											// ADD MEMBER REFERENCE TO USER MEMBERS COLLECTION
											return this.associateUserToMember(memberID, userID, type, parent, userData);
										} else {
											return Promise.reject(`A ${type} contact already exists on this member`);
										}
									})
									.catch(error => {
										toastr.error(error);
									});
							} else {
								return Promise.reject(`User already added to this member`);
							}
						})
						.catch(error => {
							toastr.error(error);
						});
				} else {
					// console.log("No user found!");
				}
			})
			.catch(function (error) {
				console.log('Error getting user:', error);
			});
	}

	associateUserToMember(memberID, userID, type, parent, userData) {
		const userRef = this.afs.collection('users').doc(userID);
		const memberRef = this.afs.collection('entities').doc(this.entityId).collection('members').doc(memberID);
		const addMemberToUser = memberRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(memberDetails => {
				if (memberDetails.payload.data()) {
					const memberData = memberDetails.payload.data();
					// ASSOCIATE MEMBER TO USER
					userRef.collection('entities').doc(this.entityId).collection('members').doc(memberID).set(
						{
							firstname: memberData['firstname'],
							lastname: memberData['lastname'],
							ref: memberRef.ref,
							uid: memberID,
							type: type,
							parent: parent,
							active: true,
						},
						{ merge: true }
					);
				} else {
					// console.log("No member found!");
				}
			})
			.catch(function (error) {
				console.log('Error getting member:', error);
			});

		// SET BLANK CELL NUMBER IF UNDEFINED
		if (!userData.cell) {
			userData.cell = '';
		}

		// ADD USER REFERENCE TO MEMBER USERS COLLECTION
		const addUserToMember = memberRef.collection('users').doc(userID).set(
			{
				firstname: userData.firstname,
				surname: userData.surname,
				ref: userRef.ref,
				uid: userID,
				type: type,
				parent: parent,
				active: true,
				created: Date.now(),
				email: userData.email,
				cell: userData.cell,
			},
			{ merge: true }
		);

		return Promise.all([addMemberToUser, addUserToMember]).then(() => {
			// SET MEMBER PRIMARY OR ALTERNATE OR TENANT USER FOR TABLE
			const fullname = userData.firstname + ' ' + userData.surname;
			let typeObj = {};

			typeObj[type] = fullname;
			memberRef.set(typeObj, { merge: true });

			let logData = {
				name: 'member',
				description: 'User associated to member',
				type: 'added',
				category: 'members',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}

	addNewUserToMember(user, member: Member) {
		const userData = {
			firstname: user.newUserFirstname,
			surname: user.newUserSurname,
			email: user.newUserEmail,
		};

		return this.usersService.addUser(userData).then(userUID => {
			// console.log('userUID after add', userUID);
			this.addUserToMember(userUID, member.uid, user.newUserType, user.newUserOccupant);
		});
	}

	removeUserFromMember(userID: string, memberID: string, type: string) {
		const userRef = this.afs.collection('users').doc(userID);
		const memberRef = this.afs.collection('entities').doc(this.entityId).collection('members').doc(memberID);

		const deactivateUserMember = userRef.collection('entities').doc(this.entityId).collection('members').doc(memberID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		const deactivateMemberUser = memberRef.collection('users').doc(userID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		return Promise.all([deactivateUserMember, deactivateMemberUser]).then(() => {
			let typeObj = {};

			typeObj[type] = '';
			memberRef.set(typeObj, { merge: true });

			let logData = {
				name: 'member',
				description: 'User removed from member',
				type: 'remove',
				category: 'members',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}

	editUserMemberAssociation(userUID: string, memberUID: string, type: string, parent: boolean): Promise<any | void> {
		const memberUsersRef = this.afs.collection(`/entities/${this.entityId}/members/${memberUID}/users`, ref => ref.where('type', '==', type).where('active', '==', true));
		const memberUserRef = this.afs.doc(`/entities/${this.entityId}/members/${memberUID}/users/${userUID}`).ref;

		// CHECK IF TYPE CHANGED
		return memberUserRef.get().then(user => {
			const userData = user.data() as User;
			// console.log('userData', userData);

			if (userData.type === type) {
				// IF TYPE NOT CHANGED UPDATE PARENT
				return this.updateMemberForUser(userUID, memberUID, type, parent);
			} else {
				// CHECK IF TYPE ALREADY EXISTS
				return memberUsersRef
					.snapshotChanges()
					.pipe(take(1))
					.toPromise()
					.then(usersCollection => {
						if (usersCollection.length === 0) {
							// TYPE DOES NOT EXIST
							// console.log("type does not exist");
							return this.updateMemberForUser(userUID, memberUID, type, parent);
						} else {
							// TYPE EXISTS SO RETURN ERROR
							// console.log("Type already exists");
							return Promise.reject(`A ${type} contact already exists on this member`);
						}
					})
					.catch(error => {
						console.log('No users found with that type - ', error);
						toastr.error(error);
					});
			}
		});
	}

	updateMemberForUser(userUID: string, memberUID: string, type: string, parent: boolean) {
		const userMemberRef = this.afs.doc(`users/${userUID}/entities/${this.entityId}/members/${memberUID}`);
		const memberUserRef = this.afs.doc(`/entities/${this.entityId}/members/${memberUID}/users/${userUID}`);

		const updateUserMember = userMemberRef.update({
			type: type,
			parent: parent,
		});

		const updateMemberUser = memberUserRef.update({
			type: type,
			parent: parent,
		});

		return Promise.all([updateUserMember, updateMemberUser]);
	}

	addHistoryLogToMember(memberLog, memberId, userId) {
		const memberHistoryCollection = this.afs.collection(`entities/${this.entityId}/members/${memberId}/history`);
		let logData = {
			memberId: memberId,
			userId: userId,
			created: Date.now(),
			changed: memberLog,
		};
		return memberHistoryCollection.add(logData).catch(err => {
			console.log(err);
		});
	}

	fetchMemberHistory(memberId) {
		const historyCollection = this.afs.collection(`entities/${this.entityId}/members/${memberId}/history`, ref => ref.orderBy('created', 'desc'));

		return historyCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as HistoryEntry;
					data.id = a.payload.doc.id;
					return data;
				});
			}),
			take(1)
		);
	}

	fetchMemberHistoryUser(userId) {
		const userDoc = this.afs.doc(`users/${userId}`);
		return userDoc.valueChanges();
	}
}
