import { Injectable, ɵConsole } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, QuerySnapshot, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Email } from './models/email.model';
import { Batch } from './models/batch.model';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../../../environments/environment';
import { SMS } from './models/sms.model';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { Property } from '@plex/property_models';
import { Member } from '../../faith/members/member.model';
import { Entity } from '../entities/entities.model';
import { User } from '../users/user.model';
import { ManagementUser } from '../management-team/management.model';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { TeamsService } from '../../sports/teams/teams.service';

declare const mUtil: any;
declare let toastr: any;
declare let $: any;

@Injectable()
export class CommunicationsService {
	loggedInUser: string;
	userDetails;
	emailsCollection: AngularFirestoreCollection<Email[]>;
	batchesCollection: AngularFirestoreCollection<Batch[]>;
	emailDoc: AngularFirestoreDocument<Email>;
	batch: AngularFirestoreDocument<Batch>;
	draftsCollection: AngularFirestoreCollection<Email[]>;
	draftDoc: AngularFirestoreDocument<Email>;
	draftsSMSCollection: AngularFirestoreCollection<SMS[]>;
	draftSMSDoc: AngularFirestoreDocument<SMS>;
	fileAttachmentPath: string;
	entityId: string;

	public draftData: any;

	constructor(
		public afs: AngularFirestore,
		private auth: AuthenticationService,
		public router: Router,
		private datePipe: DatePipe,
		private store: Store,
		private teamsService: TeamsService
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		// Get Logged in User
		this.auth.user.subscribe(userDetails => {
			if (userDetails) {
				this.loggedInUser = userDetails.uid;
				this.userDetails = userDetails;
			}
		});
	}

	// BATCHES
	createBatch(type: string, batchData, sendStatus) {
		if (batchData.fileAttachments) {
			batchData.fileAttachments.map(item => {
				delete item.downloadFileThumbnail;
			});
		}

		let finalBatchData = batchData;
		finalBatchData.type = type;
		finalBatchData.created = new Date();
		finalBatchData.active = true;
		finalBatchData.status = 'new';
		finalBatchData.sendWhenDone = sendStatus;
		finalBatchData.createdBy = this.loggedInUser;
		finalBatchData.createdByName = `${this.userDetails.firstname} ${this.userDetails.surname}`;
		finalBatchData.messagesSent = 0;
		return this.afs
			.collection(`entities/${this.entityId}/communications/batches/list`)
			.add(finalBatchData)
			.then(ref => {
				return ref;
			});
	}

	fetchBatches(batchType: string) {
		//TODO We need to avoid subscribing to the whole batch collection https://noldor.atlassian.net/browse/AM-2446
		this.batchesCollection = this.afs.collection(`entities/${this.entityId}/communications/batches/list`, ref =>
			ref.where('type', '==', batchType).orderBy('dateSent', 'desc')
		);

		return this.batchesCollection.valueChanges({ idField: 'uid' });
	}

	fetchBatchDetails(batchId: string, batchType: string) {
		this.batch = this.afs.collection(`entities/${this.entityId}/communications/batches/list`).doc(batchId);

		return this.batch.valueChanges();
	}

	fetchBatchRecipients(batchId: string) {
		const recipientRef = this.afs.collection(`entities/${this.entityId}/communications/batches/list/${batchId}/recipients`);

		return recipientRef.valueChanges();
	}

	addRecipientToBatch(batchId: string, recipientData: any) {
		return this.afs.collection(`entities/${this.entityId}/communications/batches/list/${batchId}/recipients`).add(recipientData);
	}

	addMessageToBatch(batchId: string, messageData: any) {
		if (messageData.fileAttachments) {
			messageData.fileAttachments.map(item => {
				delete item.downloadFileThumbnail;
			});
		}
		return this.afs.collection(`entities/${this.entityId}/communications/batches/list/`).doc(batchId).set({ message: messageData }, { merge: true });
	}

	setBatchToSend(batchId: string) {
		return this.afs.collection(`entities/${this.entityId}/communications/batches/list/`).doc(batchId).set({ status: 'pending' }, { merge: true });
	}

	// EMAILS
	fetchEmail(emailUID: string) {
		this.emailDoc = this.afs.collection(`entities/${this.entityId}/communications/batches/list`).doc(emailUID);

		return this.emailDoc.valueChanges();
	}

	fetchEmailRecipients(emailUID: string) {
		const recipientRef = this.afs.collection(`entities/${this.entityId}/communications/batches/list/${emailUID}/recipients`);

		return recipientRef.valueChanges();
	}

	fetchEmailBuckets(ref) {
		const bucketRef = this.afs.doc(ref.path).collection('docs');
		return bucketRef.valueChanges({ idField: 'uid' });
	}

	// GENERAL
	fetchCounts() {
		return this.afs.doc(`entities/${this.entityId}/communications/counts`).valueChanges();
	}

	// DRAFTS
	fetchDrafts() {
		this.draftsCollection = this.afs.collection(`entities/${this.entityId}/communications/drafts/list`, ref => ref.where('active', '==', true));

		return this.draftsCollection.snapshotChanges().pipe(
			map(changes => {
				this.communicationsCounter('draftsCount', changes.length);
				return changes.map(a => {
					const data = a.payload.doc.data() as Email;
					data.uid = a.payload.doc.id;
					return data;
				});
			})
		);
	}

	fetchDraft(draftUID: string) {
		this.draftDoc = this.afs.collection(`entities/${this.entityId}/communications/drafts/list/`).doc(draftUID);

		return this.draftDoc.valueChanges();
	}

	getFileAttachmentRef(draftId: string) {
		return this.afs.collection(this.getFileAttachmentPath(draftId), ref => ref.where('active', '==', true));
	}

	getFileAttachmentPath(draftId: string) {
		return `entities/${this.entityId}/communications/drafts/list/${draftId}/attachments/`;
	}

	getFileAttachments(draftId: string) {
		const attachmentRef = this.afs.collection(this.getFileAttachmentPath(draftId));
		return attachmentRef.valueChanges({ idField: 'id' });
	}

	createEmailDraft(data, recipients: boolean, management: boolean, sendToAll: string) {
		let recipientEmail = [];
		let emailRecipientSet = {};
		data.forEach(userInfo => {
			recipientEmail.push(userInfo.email);
		});
		if (sendToAll === 'properties') {
			emailRecipientSet = {
				allProperties: true,
			};
		} else if (sendToAll === 'members') {
			emailRecipientSet = {
				allMembers: true,
			};
		} else if (sendToAll === 'users') {
			emailRecipientSet = {
				allUsers: true,
			};
		} else if (sendToAll === 'subgroups') {
			emailRecipientSet = {
				canSubgroups: true,
			};
		} else {
			emailRecipientSet = [];
		}

		this.afs
			.collection(`entities/${this.entityId}/communications/drafts/list`)
			.add({
				recipients: recipientEmail,
				subject: '',
				message: '',
				emailRecipients: emailRecipientSet,
				sendDraft: false,
				created: new Date(),
				createdBy: this.loggedInUser,
				createdByName: `${this.userDetails.firstname} ${this.userDetails.surname}`,
				active: true,
				recipientSet: recipients,
				managementSet: management,
			})
			.then(ref => {
				this.router.navigate([`/${this.entityId}/communications/email/draft/${ref.id}`]);
			});
	}

	convertDraftToBatch(draftUID: string) {
		return this.afs.collection(`entities/${this.entityId}/communications/drafts/list`).doc(draftUID).set({ sendDraft: true }, { merge: true });
	}

	saveDraft(draftUID: string, email: Email) {
		let recipients = [];

		if (email.recipients) {
			recipients = email.recipients;
		}

		return this.afs
			.collection(`entities/${this.entityId}/communications/drafts/list`)
			.doc(draftUID)
			.update({
				recipients: recipients,
				subject: email.subject,
				message: email.message,
				customFieldsData: email.customFieldsData,
				emailRecipients: email.emailRecipients,
				sportsData: email.sportsData || null,
				created: new Date(),
				createdBy: this.loggedInUser,
				active: true,
			});
	}

	/* sendBatch(draftUID: string, email: Email) {
        

        //CREATE AN EMAIL BATCH FIRST
        return this.createBatch(email.subject).then((newBatch) => {
            // CREATE EMAIL TO SEND
            return this.afs.doc(entityId).collection(`communication`).doc('email').collection(`batches`).doc(newBatch.id)
                .set({
                    recipient: email.recipient,
                    subject: email.subject,
                    message: email.message,
                    body: email.body,
                    created: new Date(),
                    createdBy: this.loggedInUser
                })
                .then(ref => {
                    // REMOVE DRAFT
                    const removeDraft = this.afs.doc(entityId).collection(`communication`).doc('email').collection('drafts').doc(draftUID)
                        .delete();
                    //ADD LOG ENTRY
                    let logData = {
                        name: email.subject,
                        description: 'Email sent to user',
                        type: 'info',
                        category: 'email',
                        created: Date.now()
                    }
                    this.auditLogService.addAudit(logData);
                });
        });
    } */

	deleteDraft(draftUID: string) {
		// CHECK IF BUCKET LINKED TO DRAFT
		const draftRef = this.afs.doc(`entities/${this.entityId}/communications/drafts/list/${draftUID}`);

		return draftRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(snap => {
				const draftData = snap.payload.data() as any;

				// REMOVE DRAFT FROM BUCKET
				if (draftData.bucketRef) {
					draftData.bucketRef
						.collection('drafts')
						.doc(draftUID)
						.delete()
						.then(() => {})
						.catch(err => {
							console.log(err);
						});
				}

				// REMOVE DRAFT
				return draftRef
					.delete()
					.then(() => {
						toastr.success('Draft was successfully removed');
					})
					.catch(() => {
						toastr.error('There was a problem removing the draft. Please try again');
					});
			});
	}

	removeDraft(draftUID: string) {
		const draftRef = this.afs.firestore.doc(`entities/${this.entityId}/communications/drafts/list/${draftUID}`);

		return draftRef.update({
			active: false,
		});
	}

	//ADD SMS

	// DRAFTS
	fetchSMSDrafts() {
		this.draftsSMSCollection = this.afs.collection(`entities/${this.entityId}/communications/drafts/sms`, ref => ref.where('active', '==', true));

		return this.draftsSMSCollection.valueChanges({ idField: 'uid' });
	}

	fetchSMSDraft(draftUID: string) {
		this.draftSMSDoc = this.afs.collection(`entities/${this.entityId}/communications/drafts/sms/`).doc(draftUID);

		return this.draftSMSDoc.valueChanges();
	}

	createSMSDraft(data, recipients: boolean, management: boolean, sendToAll: string) {
		let recipientSMS = [];
		let smsRecipientSet = {};
		data.forEach(userInfo => {
			if (userInfo.cell) {
				if (recipientSMS.indexOf(userInfo.cell) === -1) {
					recipientSMS.push(userInfo.cell);
				}
			}
		});
		if (sendToAll === 'properties') {
			smsRecipientSet = {
				allProperties: true,
			};
		} else if (sendToAll === 'users') {
			smsRecipientSet = {
				allUsers: true,
			};
		} else if (sendToAll === 'subgroups') {
			smsRecipientSet = {
				canSubgroups: true,
			};
		} else {
			smsRecipientSet = [];
		}

		this.afs
			.collection(`entities/${this.entityId}/communications/drafts/sms`)
			.add({
				recipients: recipientSMS,
				message: '',
				smsRecipients: smsRecipientSet,
				sendDraft: false,
				created: new Date(),
				createdBy: this.loggedInUser,
				active: true,
				recipientSet: recipients,
				managementSet: management,
				subject: 'SMS Draft : ' + this.datePipe.transform(Date.now(), 'dd-MM-yyyy HH:mm'),
			})
			.then(ref => {
				this.router.navigate([`/${this.entityId}/communications/sms/draft/${ref.id}`]);
			});
	}

	editSMSDraft(data) {
		const { recipients, message, smsRecipients, active, recipientSet, managementSet } = data;
		this.afs
			.collection(`entities/${this.entityId}/communications/drafts/sms`)
			.add({
				recipients,
				message,
				smsRecipients,
				sendDraft: false,
				created: new Date(),
				createdBy: this.loggedInUser,
				active,
				recipientSet,
				managementSet,
				subject: 'SMS Draft : ' + this.datePipe.transform(Date.now(), 'dd-MM-yyyy HH:mm'),
			})
			.then(ref => {
				this.router.navigate([`/${this.entityId}/communications/sms/draft/${ref.id}`]);
			});
	}

	saveSMSDraft(draftUID: string, sms: SMS) {
		let recipients = [];
		let customFieldData = [];
		let credits: number = 0;
		let customFieldsCredits: number = 0;

		if (sms.recipients) {
			recipients = sms.recipients;
		}

		if (sms.customFieldsData) {
			customFieldData = sms.customFieldsData;

			if (sms.recipients) {
				credits = sms.creditsRequired;
				customFieldsCredits = sms.customFieldsData.customFieldsCreditsRequired;
			}
		}

		if (customFieldsCredits !== undefined) {
			this.afs.collection(`entities/${this.entityId}/communications/drafts/sms`).doc(draftUID).update({
				customCreditsRequired: customFieldsCredits,
			});
		}

		return this.afs
			.collection(`entities/${this.entityId}/communications/drafts/sms`)
			.doc(draftUID)
			.update({
				recipients: recipients,
				message: sms.message,
				smsRecipients: sms.smsRecipients,
				created: new Date(),
				createdBy: this.loggedInUser,
				subject: 'SMS Draft : ' + this.datePipe.transform(Date.now(), 'dd-MM-yyyy HH:mm'),
				active: true,
				customFieldsData: customFieldData,
				creditsRequired: credits,
			});
	}

	deleteSMSDraft(draftUID: string) {
		// CHECK IF BUCKET LINKED TO DRAFT
		const draftRef = this.afs.doc(`entities/${this.entityId}/communications/drafts/sms/${draftUID}`);

		return draftRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(snap => {
				// REMOVE DRAFT
				return draftRef
					.delete()
					.then(() => {
						toastr.success('Draft was successfully removed');
					})
					.catch(() => {
						toastr.error('There was a problem removing the draft. Please try again');
					});
			});
	}

	removeSMSDraft(draftUID: string) {
		const draftRef = this.afs.firestore.doc(`entities/${this.entityId}/communications/drafts/sms/${draftUID}`);

		return draftRef.update({
			active: false,
		});
	}

	createSMSBatch(smsData, sendStatus) {
		smsData.subject = 'SMS Send : ' + this.datePipe.transform(Date.now(), 'dd-MM-yyyy HH:mm');
		const entityRef = this.afs.doc(`entities/${this.entityId}`);

		return entityRef.ref.get().then(entity => {
			const entityData = entity.data() as Entity;
			smsData.product = entityData.product;
			smsData.entityId = this.entityId;
			return this.createBatch('sms', smsData, sendStatus);
		});
	}

	createEmailBatch(emailData, sendStatus) {
		// GET ENTITY DETAILS FOR EMAIL TEMPLATE

		const entityRef = this.afs.doc(`entities/${this.entityId}`);

		return entityRef.ref.get().then(entity => {
			const entityData = entity.data() as Entity;
			emailData.entityId = this.entityId;
			emailData.product = entityData.product;
			emailData.entityName = entityData.name;
			emailData.entityLogo = entityData.template.downloadLogo;
			emailData.entityColour = entityData.template.colour1;
			emailData.entityDomain = `https://${entityData.domain}.${environment.client}`;
			emailData.disclaimerContent = entityData.template.disclaimerContent ? entityData.template.disclaimerContent : '';

			//GENERATE BUCKET LINK IF BUCKET ATTACHED TO EMAIL
			if (emailData.bucketRef) {
				let bucketComponent = `view`;
				if (entityData.product === 'whitfields') {
					bucketComponent = `edit`;
				}

				let encodedBase64Data = btoa(`/documents/buckets/${bucketComponent}/${emailData.bucketRef.id}`);
				emailData.bucketURL = `https://${entityData.domain}.${environment.client}/redirect/${encodedBase64Data}`;
			}

			return this.createBatch('email', emailData, sendStatus);
		});
	}

	resendEmailBatch(batchId: string) {
		const resendRef = this.afs.collection('pending').doc(batchId);
		const batchRef = this.afs.collection('entities').doc(this.entityId).collection('communications').doc('batches').collection('list').doc(batchId);

		batchRef.set(
			{
				status: 'Sending',
			},
			{ merge: true }
		);

		return resendRef.set({
			request: 'communicationBatchResend',
			entityId: this.entityId,
			batchId: batchId,
			type: 'email',
			product: environment.product,
		});
	}

	resendSMSBatch(batchId: string) {
		const resendRef = this.afs.collection('pending').doc(batchId);
		const batchRef = this.afs.collection('entities').doc(this.entityId).collection('communications').doc('batches').collection('list').doc(batchId);

		batchRef.set(
			{
				status: 'Sending',
			},
			{ merge: true }
		);

		return resendRef.set({
			request: 'communicationBatchResend',
			entityId: this.entityId,
			batchId: batchId,
			type: 'sms',
			product: environment.product,
		});
	}

	communicationsCounter(type, getCount) {
		const counterRef = this.afs.doc(`entities/${this.entityId}/communications/counts`).ref;
	}

	fetchNoticeCounts() {
		return this.afs.doc(`entities/${this.entityId}/communications/notices`).valueChanges();
	}

	fetchSmsCredits() {
		const creditsRef = this.afs.collection(`entities/${this.entityId}/communications/sms/credits`);

		return creditsRef.valueChanges({ idField: 'id' });
	}

	fetchCreditRequests() {
		return this.afs.collection(`entities/${this.entityId}/communications/sms/creditRequests`, ref => ref.orderBy('requestedDate', 'desc')).valueChanges({ idField: 'id' });
	}

	fetchEntityCredit() {
		const smsRef = this.afs.doc(`entities/${this.entityId}/communications/sms`);

		return smsRef.valueChanges();
	}

	fetchEntityCreditRequired(draftId: string) {
		const smsRef = this.afs.doc(`entities/${this.entityId}/communications/drafts/sms/${draftId}`);

		return smsRef.valueChanges();
	}

	fetchAllCreditRequests() {
		return this.afs.collection(`/entities/${this.entityId}/communications/sms/creditRequests`).valueChanges();
	}

	requestCredit(amount: number) {
		const userId = sessionStorage.getItem('userId');
		const entityRef = this.afs.doc(`entities/${this.entityId}`).ref;
		const entitySmsCreditRequestsRef = this.afs.collection(`/entities/${this.entityId}/communications/sms/creditRequests`);
		let entityData;
		return entityRef.get().then(doc => {
			if (doc.exists) {
				entityData = doc.data();
				// ADD REQUEST TO ENTITY
				return entitySmsCreditRequestsRef
					.add({
						entityId: this.entityId,
						requestedBy: userId,
						requestedDate: Date.now(),
						amount: amount,
						approved: false,
						status: 'pending',
						active: true,
						reference: mUtil.getUniqueID(entityData.prefix),
					})
					.then(ref => {
						// SEND EMAIL TO REQUEST CREDIT
						this.afs.collection('pending').doc(ref.id).set(
							{
								request: 'emailSmsCreditRequest',
								entityId: this.entityId,
								requestedBy: userId,
								amount: amount,
								admin: environment.admin,
								olympus: environment.olympus,
							},
							{ merge: true }
						);
					});
			} else {
			}
		});
	}

	updateCreditsRemaning(creditsRemaining: number, creditsRequired: number) {
		const smsRef = this.afs.doc(`/entities/${this.entityId}/communications/sms`);

		creditsRemaining = +creditsRemaining - +creditsRequired;

		smsRef.set(
			{
				credits: creditsRemaining,
			},
			{ merge: true }
		);
	}

	fetchUsersCounts(smsId: string) {
		const smsRef = this.afs.doc(`/entities/${this.entityId}/communications/drafts/sms/${smsId}`);

		return smsRef.valueChanges();
	}

	getUserCounts(smsId: string, selectedList: any, recipients, customData?, sportsData?) {
		console.log('getUserCounts', recipients);

		const countsRef = this.afs.doc(`/entities/${this.entityId}/communications/drafts/sms/${smsId}`);
		let smsListArray = [];

		console.log('selectedList', selectedList);

		// Fetch all #Faith members.
		const fetchAllMembers: Promise<void> = new Promise(async (res, rej) => {
			if (selectedList.allMembers) {
				try {
					const membersRef: CollectionReference = this.afs
						.collection('entities')
						.doc(this.entityId)
						.collection('members', ref => {
							return ref.where('active', '==', true);
						}).ref;

					const memberSnapshots: QuerySnapshot<Member> = await membersRef.get();
					const memberDocumentSnapshots: Array<QueryDocumentSnapshot<Member>> = memberSnapshots.docs;
					const memberTels: Array<string> = memberDocumentSnapshots.map(member => member.data().tel);
					smsListArray.push(...memberTels.filter((tel: string) => tel && tel));
					res();
				} catch (err) {
					rej(err);
				}
			} else {
				res();
			}
		});

		const fetchLeaders: Promise<void> = new Promise(async (res, rej) => {
			const { leaders, smallGroupLeaders } = selectedList;

			if (leaders || smallGroupLeaders) {
				try {
					const groupsRef = this.afs.collection(`entities/${this.entityId}/groups`).ref.where('active', '==', true).orderBy('name', 'asc');

					// Get all current entity's group ids
					const groupIds = (await groupsRef.get()).docs.map(({ id }) => id);

					// Get all members, simplified, for all group ids
					const groupMembers = await Promise.all(
						groupIds.map(async id => {
							const membersRef = this.afs.collection(`entities/${this.entityId}/groups/${id}/members`).ref.where('active', '==', true);

							return await Promise.all(
								(await membersRef.get()).docs.map(async membersDocs => {
									const { memberId, memberTypes } = membersDocs.data() as Member;

									/*
                                Get the tel from the actual member
                                since we don't store it on the group member
                                because there would be no easy way to update it.
                            */
									const memberRef = this.afs.doc(`/entities/${this.entityId}/members/${memberId}`).ref;
									const { tel } = (await memberRef.get()).data() as Member;

									return {
										uid: memberId,
										memberTypes,
										tel,
									};
								})
							);
						})
					);

					// Remove duplicate members and add memberTypes for members that are in multiple groups
					const members = [];
					groupMembers.forEach(group => {
						group.forEach(member => {
							if (members.some(({ uid }) => uid === member.uid)) {
								// If the member exists
								// If the member has existing types.
								// Push current group member types to existing types.
								if (member.memberTypes) {
									const memberIndex = members.findIndex(({ uid }) => uid === member.uid);
									members[memberIndex].memberTypes.push(...member.memberTypes);
								}
							} else {
								// If the member hasn't been pushed
								members.push(member);
							}
						});
					});

					/*
                        Push only the tel number if:
                        -member types exists
                        -if leaders, where leader is in member types
                        -if small group leaders, where leader is in member types
                        -the member has tel and member types properties
                        -the smsList doesn't include the tel already
                    */
					smsListArray.push(
						...members
							.filter(({ memberTypes, tel }) => {
								if (!memberTypes || !tel) return false;

								return (memberTypes.includes(leaders && 'leader') || memberTypes.includes(smallGroupLeaders && 'small-leader')) && !smsListArray.includes(tel);
							})
							.map(({ tel }) => tel)
					);

					return res();
				} catch (err) {
					return rej(err);
				}
			} else {
				return res();
			}
		});

		const fetchManagement: Promise<void> = new Promise(async (res, rej) => {
			if (selectedList.management) {
				try {
					const usersRef = this.afs.collection(`/entities/${this.entityId}/management/users/list`).ref.where('active', '==', true);
					const management: Array<ManagementUser> = (await usersRef.get()).docs.map(userDoc => userDoc.data());
					management.forEach(({ cell }) => {
						if (cell && !smsListArray.includes(cell)) smsListArray.push(cell);
					});
					return res();
				} catch (err) {
					return rej(err);
				}
			} else {
				return res();
			}
		});

		// FETCH ALL USERS
		const fetchAllUsersCheck: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.allUsers === true) {
				const usersRef = this.afs.collection(`/entities/${this.entityId}/users`).ref.where('active', '==', true);

				usersRef.get().then(users => {
					let allUsersPromises = [];

					if (users) {
						users.forEach(userData => {
							const user = userData.data() as User;
							if (user.cell) {
								if (smsListArray.indexOf(user.cell) === -1) {
									smsListArray.push(user.cell);
								}
							}
							allUsersPromises.push(user);
						});
					}

					Promise.all([allUsersPromises]).then(() => {
						resolve();
					});
				});
			} else {
				resolve();
			}
		});

		// FETCH CUSTOM FIELD USERS
		const fetchCustomUsersCheck: Promise<void | number> = new Promise((res, reject) => {
			if (selectedList.customFields === true && customData) {
				const fieldType = customData.type;
				const fieldUID = customData.uid;
				const fieldValue = customData.value;

				let users = [];

				let entityField;
				let endtityFieldDocRef = this.afs.collection(`entities/${this.entityId}/customFields`).doc(fieldUID);

				return endtityFieldDocRef
					.get()
					.toPromise()
					.then(doc => {
						// get the full custom field located at entity level
						if (doc.exists) {
							entityField = doc.data();
						} else {
							console.log('No such document!');
						}
					})
					.then(() => {
						// get the documents that contain the custom field(searchField, which got from field located at entity level) at property level

						return new Promise<number | void>((resolve, reject) => {
							let field;
							if (entityField.type === 'select') {
								// if the input type is select
								field = {
									active: entityField.active,
									createdBy: entityField.createdBy,
									label: entityField.label,
									sections: entityField.sections,
									type: entityField.type,
									customFieldUID: fieldUID,
									uid: fieldUID,
									value: fieldValue,
									options: entityField.options, // add options
								};
							} else {
								field = {
									active: entityField.active,
									createdBy: entityField.createdBy,
									label: entityField.label,
									sections: entityField.sections,
									type: entityField.type,
									customFieldUID: fieldUID,
									uid: fieldUID,
									value: fieldValue,
								};
							}
							console.log('fields', field);
							if (fieldUID && fieldValue !== '') {
								let collectionURL: string;
								if (fieldType === 'propertyEdit') {
									// if the custom field type is for properties
									collectionURL = `entities/${this.entityId}/properties`;
								}
								if (fieldType === 'userEdit') {
									// if the custom field type is for users
									collectionURL = `entities/${this.entityId}/users`;
								}
								if (fieldType === '' || fieldType === 'null' || fieldType === 'undefined') {
									console.error(`err id: noFieldType | Missing fieldType in custom field values! | communications.service.ts`);
								}
								console.log(`CommunicationsService -> getUserCounts -> fieldType`, fieldType);
								if (fieldType === 'memberEdit') {
									// if the custom field type is for users
									collectionURL = `entities/${this.entityId}/members`;
								}
								if (fieldType === 'groupEdit') {
									// if the custom field type is for users
									collectionURL = `entities/${this.entityId}/groups`;
								}
								const checkRefPromise = () =>
									new Promise(res => {
										if (entityField.type === 'checkbox' && field.value === false) {
											const observable = new BehaviorSubject<any>([]);
											//search on two conditions false and null
											this.afs
												.collection(`${collectionURL}`, ref => ref.where('active', '==', true))
												.valueChanges()
												.subscribe(data => {
													let a = data.filter((item: any) => {
														if (item.customFields) {
															let b = item.customFields.filter((field: any) => {
																return field['value'] === false;
															});
															if (b > 0) return item;
														} else {
															return item;
														}
													});
													observable.next(a);
													res(observable);
												});
										} else {
											const collectionFalseRef = this.afs
												.collection(`${collectionURL}`, ref => ref.where('active', '==', true).where('customFields', 'array-contains', field))
												.valueChanges();
											res(collectionFalseRef);
										}
									});

								checkRefPromise().then((refData: any) => {
									return refData.pipe(take(1)).subscribe(docField => {
										let count = 0;

										if (docField.length === 0) resolve();

										return docField.forEach(doc => {
											if (fieldType === 'propertyEdit') {
												let prop: Property = doc;

												if (prop.primary && prop.primary !== '' && !users.includes(prop.primary)) {
													users.push(prop.primary);
												}
												if (prop.alternate && prop.alternate !== '' && !users.includes(prop.alternate)) {
													users.push(prop.alternate);
												}
												count++;
											}
											if (fieldType === 'userEdit') {
												let prop: Property = doc;
												users.push(prop);
												count++;
											}
											if (fieldType === 'memberEdit') {
												let prop: any = doc;
												doc.cell = doc.tel;
												users.push(prop);
												count++;
											}
											if (fieldType === 'groupEdit') {
												let prop: any = doc;
												doc.cell = doc.tel;
												users.push(prop);
												count++;
											}
											if (count === docField.length) {
												let tempUsers = users.map((user: any) => {
													return user.cell ? user.cell : '';
												});

												smsListArray = [...tempUsers];
												resolve(users.length);
											}
										});
									});
								});
							} else {
								console.error(`err id: invalidFieldDetails | Missing either fieldUID or fieldValue in custom field values! | communications.service.ts`);
							}
						}).then(length => {
							res(length);
						});
					});
			} else {
				res();
			}
		});

		// FETCH ALL PROPERTY USERS
		const fetchAllPropertyUsersCheck: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.allProperties === true) {
				const propertiesRef = this.afs
					.collection('entities')
					.doc(this.entityId)
					.collection('properties', ref => ref.where('active', '==', true)).ref;
				let propertiesList = [];
				let usersList = [];

				const fetchProperties: Promise<void> = new Promise((resolve, reject) => {
					propertiesRef.get().then(properties => {
						if (properties) {
							properties.forEach(property => {
								propertiesList.push(property.id);
							});
							return Promise.all([propertiesList]).then(() => {
								resolve();
							});
						} else {
							resolve();
						}
					});
				});

				return Promise.all([fetchProperties]).then(() => {
					const fetchPropertiesUsers: Promise<void> = new Promise((resolve, reject) => {
						if (propertiesList.length > 0) {
							propertiesList.forEach(property => {
								const propertyUsersRef = this.afs.collection(`entities/${this.entityId}/properties/${property}/users`, ref => ref.where('active', '==', true));
								usersList.push(propertyUsersRef.valueChanges());
							});
							return Promise.all([usersList]).then(() => {
								resolve();
							});
						} else {
							resolve();
						}
					});

					return Promise.all([fetchPropertiesUsers]).then(() => {
						const combineLists = combineLatest<any[]>(usersList).pipe(map(arr => arr.reduce((acc, cur) => acc.concat(cur))));

						combineLists.pipe(take(1)).subscribe(propertyUsersList => {
							let allPropertiesPromises = [];

							propertyUsersList.forEach(propertyUser => {
								if (propertyUser.cell) {
									if (smsListArray.indexOf(propertyUser.cell) === -1) {
										smsListArray.push(propertyUser.cell);
									}
								}
								allPropertiesPromises.push(propertyUser);
							});

							return Promise.all([allPropertiesPromises]).then(() => {
								resolve();
							});
						});
					});
				});
			} else {
				resolve();
			}
		});

		// FETCH ALL PROPERTY PRIMARY OWNERS
		const fetchAllPropertyPrimaryOwnersCheck: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.owners === true || selectedList.primaryOwners === true) {
				const propertiesRef = this.afs
					.collection('entities')
					.doc(this.entityId)
					.collection('properties', ref => ref.where('active', '==', true)).ref;
				let propertiesList = [];
				let usersList = [];

				const fetchPrimaryProperties: Promise<void> = new Promise((resolve, reject) => {
					propertiesRef.get().then(properties => {
						if (properties) {
							properties.forEach(property => {
								propertiesList.push(property.id);
							});
							return Promise.all([propertiesList]).then(() => {
								resolve();
							});
						} else {
							resolve();
						}
					});
				});

				return Promise.all([fetchPrimaryProperties]).then(() => {
					const fetchPrimaryPropertiesUsers: Promise<void> = new Promise((resolve, reject) => {
						if (propertiesList.length > 0) {
							propertiesList.forEach(property => {
								const propertyUsersRef = this.afs.collection(`entities/${this.entityId}/properties/${property}/users`, ref =>
									ref.where('active', '==', true).where('type', '==', 'primary')
								);
								usersList.push(propertyUsersRef.valueChanges());
							});
							return Promise.all([usersList]).then(() => {
								resolve();
							});
						} else {
							resolve();
						}
					});

					return Promise.all([fetchPrimaryPropertiesUsers]).then(() => {
						const combineLists = combineLatest<any[]>([usersList]).pipe(map(arr => arr.reduce((acc, cur) => acc.concat(cur))));

						combineLists.pipe(take(1)).subscribe(propertyUsersList => {
							let allPropertiesPromises = [];

							propertyUsersList.forEach(propertyUser => {
								if (propertyUser.cell) {
									if (smsListArray.indexOf(propertyUser.cell) === -1) {
										smsListArray.push(propertyUser.cell);
									}
								}
								allPropertiesPromises.push(propertyUser);
							});

							return Promise.all([allPropertiesPromises]).then(() => {
								resolve();
							});
						});
					});
				});
			} else {
				resolve();
			}
		});

		// FETCH ALL PROPERTY ALTERNATE OWNERS
		const fetchAllPropertyAlternateOwnersCheck: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.owners === true || selectedList.alternateOwners === true) {
				const propertiesRef = this.afs
					.collection('entities')
					.doc(this.entityId)
					.collection('properties', ref => ref.where('active', '==', true)).ref;
				let propertiesList = [];
				let usersList = [];

				const fetchAlternateProperties: Promise<void> = new Promise((resolve, reject) => {
					propertiesRef.get().then(properties => {
						if (properties) {
							properties.forEach(property => {
								propertiesList.push(property.id);
							});
							return Promise.all([propertiesList]).then(() => {
								resolve();
							});
						} else {
							resolve();
						}
					});
				});

				return Promise.all([fetchAlternateProperties]).then(() => {
					const fetchAlternatePropertiesUsers: Promise<void> = new Promise((resolve, reject) => {
						if (propertiesList.length > 0) {
							propertiesList.forEach(property => {
								const propertyUsersRef = this.afs.collection(`entities/${this.entityId}/properties/${property}/users`, ref =>
									ref.where('active', '==', true).where('type', '==', 'alternate')
								);
								usersList.push(propertyUsersRef.valueChanges());
							});
							return Promise.all([usersList]).then(() => {
								resolve();
							});
						} else {
							resolve();
						}
					});

					return Promise.all([fetchAlternatePropertiesUsers]).then(() => {
						const combineLists = combineLatest<any[]>([usersList]).pipe(map(arr => arr.reduce((acc, cur) => acc.concat(cur))));

						combineLists.pipe(take(1)).subscribe(propertyUsersList => {
							let allPropertiesPromises = [];

							propertyUsersList.forEach(propertyUser => {
								if (propertyUser.cell) {
									if (smsListArray.indexOf(propertyUser.cell) === -1) {
										smsListArray.push(propertyUser.cell);
									}
								}
								allPropertiesPromises.push(propertyUser);
							});

							return Promise.all([allPropertiesPromises]).then(() => {
								resolve();
							});
						});
					});
				});
			} else {
				resolve();
			}
		});

		// // FETCH ALL PROPERTY OCCUPANTS
		const fetchAllPropertyOccupantsCheck: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.occupants === true) {
				const propertiesRef = this.afs
					.collection('entities')
					.doc(this.entityId)
					.collection('properties', ref => ref.where('active', '==', true)).ref;
				let propertiesList = [];
				let usersList = [];

				const fetchOccupantsProperties: Promise<void> = new Promise((resolve, reject) => {
					propertiesRef.get().then(properties => {
						if (properties) {
							properties.forEach(property => {
								propertiesList.push(property.id);
							});
							return Promise.all([propertiesList]).then(() => {
								resolve();
							});
						} else {
							resolve();
						}
					});
				});

				return Promise.all([fetchOccupantsProperties]).then(() => {
					const fetchOccupantsPropertiesUsers: Promise<void> = new Promise((resolve, reject) => {
						if (propertiesList.length > 0) {
							propertiesList.forEach(property => {
								const propertyUsersRef = this.afs.collection(`entities/${this.entityId}/properties/${property}/users`, ref =>
									ref.where('active', '==', true).where('occupant', '==', true)
								);
								usersList.push(propertyUsersRef.valueChanges());
							});
							return Promise.all([usersList]).then(() => {
								resolve();
							});
						} else {
							resolve();
						}
					});

					return Promise.all([fetchOccupantsPropertiesUsers]).then(() => {
						const combineLists = combineLatest<any[]>([usersList]).pipe(map(arr => arr.reduce((acc, cur) => acc.concat(cur))));

						combineLists.pipe(take(1)).subscribe(propertyUsersList => {
							let allPropertiesPromises = [];

							propertyUsersList.forEach(propertyUser => {
								if (propertyUser.cell) {
									if (smsListArray.indexOf(propertyUser.cell) === -1) {
										smsListArray.push(propertyUser.cell);
									}
								}
								allPropertiesPromises.push(propertyUser);
							});

							return Promise.all([allPropertiesPromises]).then(() => {
								resolve();
							});
						});
					});
				});
			} else {
				resolve();
			}
		});

		// FETCH SCHEME EXECUTIVES
		const fetchSchemeExecutivesCheck: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.schemeExecs === true) {
				const usersRef = this.afs.collection(`/entities/${this.entityId}/management/users/list`).ref.where('isSchemeExecutive', '==', true);

				usersRef.get().then(users => {
					let allUsersPromises = [];

					if (users) {
						users.forEach(userData => {
							const user = userData.data() as User;
							if (user.cell) {
								if (smsListArray.indexOf(user.cell) === -1) {
									smsListArray.push(user.cell);
								}
							}
							allUsersPromises.push(user);
						});
					}

					Promise.all([allUsersPromises]).then(() => {
						resolve();
					});
				});
			} else {
				resolve();
			}
		});

		// FETCH NON-SCHEME EXECUTIVES
		const fetchNonSchemeExecutivesCheck: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.schemeNonExecs === true) {
				const usersRef = this.afs.collection(`/entities/${this.entityId}/management/users/list`).ref.where('isSchemeExecutive', '==', false);

				usersRef.get().then(users => {
					let allUsersPromises = [];

					if (users) {
						users.forEach(userData => {
							const user = userData.data() as User;
							if (user.cell) {
								if (smsListArray.indexOf(user.cell) === -1) {
									smsListArray.push(user.cell);
								}
							}
							allUsersPromises.push(user);
						});
					}

					Promise.all([allUsersPromises]).then(() => {
						resolve();
					});
				});
			} else {
				resolve();
			}
		});

		// FETCH SPORTS PLAYERS USERS
		const fetchSportsPlayers: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.allPlayers === true) {
				this.teamsService.fetchCellNumbersForPlayers(this.entityId).then(cellNumbers => {
					if (cellNumbers.length > 0) {
						cellNumbers.forEach(cell => {
							if (smsListArray.indexOf(cell) === -1) {
								smsListArray.push(cell);
							}
						});
					}
					resolve();
				});
			} else {
				resolve();
			}
		});
		// FETCH SPORTS TEAM PLAYER USERS
		const fetchTeamPlayerUsers: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.sportsTeam === true && sportsData) {
				this.teamsService.fetchCellNumbersForTeam(this.entityId, sportsData.teamId).then(cellNumbers => {
					cellNumbers.forEach(cell => {
						if (smsListArray.indexOf(cell) === -1) {
							smsListArray.push(cell);
						}
					});
					resolve();
				});
			} else {
				resolve();
			}
		});

		// INDIVIDUAL
		const individualCheck: Promise<void> = new Promise((resolve, reject) => {
			if (selectedList.individual === true) {
				smsListArray.push('individual');
				resolve();
			} else {
				resolve();
			}
		});

		return Promise.all([
			fetchAllMembers,
			fetchAllUsersCheck,
			fetchAllPropertyUsersCheck,
			fetchAllPropertyPrimaryOwnersCheck,
			fetchAllPropertyAlternateOwnersCheck,
			fetchAllPropertyOccupantsCheck,
			fetchSchemeExecutivesCheck,
			fetchNonSchemeExecutivesCheck,
			individualCheck,
			fetchCustomUsersCheck,
			fetchManagement,
			fetchLeaders,
			fetchTeamPlayerUsers,
			fetchSportsPlayers,
		]).then(() => {
			let creditRequiredCount = smsListArray.length;
			if (recipients > 0) {
				creditRequiredCount += recipients;
			}
			// countsRef.set({
			//     creditsRequired: creditRequiredCount
			// }, { merge: true });
			return Promise.resolve(creditRequiredCount);
		});
	}

	filterCustomFieldsByContains(type) {
		return this.afs
			.collection(`entities/${this.entityId}/customFields`, ref => ref.where('sections', 'array-contains', type).where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	public initMDatePickers(pickers: Array<string>, callback: (picker: string, date: Date) => void, helperBtns?: boolean, format = 'dd/mm/yyyy') {
		console.log(format);
		pickers.forEach(picker => {
			const isRTL = mUtil.isRTL();

			const t = {
				leftArrow: `<i class="la la-angle-${isRTL ? 'right' : 'left'}"></i>`,
				rightArrow: `<i class="la la-angle-${isRTL ? 'left' : 'right'}"></i>`,
			};

			setTimeout(() => {
				$(`#${picker}`).datepicker(
					helperBtns
						? {
								format,
								rtl: mUtil.isRTL(),
								todayBtn: 'linked',
								clearBtn: !0,
								todayHighlight: !0,
								templates: t,
						  }
						: null
				);

				$(`#${picker}`).on('changeDate', ({ date }) => {
					return callback(picker, date);
				});
			}, 0);
		});
	}

	public initAlternateDatePicker(pickers: Array<string>, callback: (picker: string, date: Date) => void, helperBtns?: boolean, format = 'mm/yyyy') {
		pickers.forEach(picker => {
			const isRTL = mUtil.isRTL();

			const t = {
				leftArrow: `<i class="la la-angle-${isRTL ? 'right' : 'left'}"></i>`,
				rightArrow: `<i class="la la-angle-${isRTL ? 'left' : 'right'}"></i>`,
			};

			setTimeout(() => {
				$(`#${picker}`).datepicker(
					helperBtns
						? {
								format,
								rtl: mUtil.isRTL(),
								todayBtn: 'linked',
								todayHighlight: !0,
								templates: t,
								startView: 2,
								clearBtn: true,
								minViewMode: 1,
								orientation: 'bottom',
								endDate: '+0d',
						  }
						: null
				);

				$(`#${picker}`).on('changeDate', ({ date }) => {
					return callback(picker, date);
				});
			}, 0);
		});
	}
}
