import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Task } from '../models/task.model';
import { environment } from '../../../../../../../../src/environments/environment';
import { UsersService } from '../../users/users.service';
import { TasksService } from '../tasks.service';
import { DataService } from '../../../../../../_services/data.service';
import { DatePipe } from '@angular/common';
import { map, take } from 'rxjs/operators';

declare let toastr: any;
declare let mApp: any;
declare const $: any;

@Component({
	selector: 'app-tasks-create',
	templateUrl: './tasks-create.component.html',
	styleUrls: ['./tasks-create.component.css'],
})
export class TasksCreateComponent implements OnInit, AfterViewInit {
	@Input() entityId;
	createTaskForm;
	entityPrefix: string;
	managementTeamList;
	assignedToId;
	showSuccess: boolean = false;
	taskInfo: any;
	isSubmitting: boolean = false;
	taskId: string;

	constructor(
		private fb: FormBuilder,
		public usersService: UsersService,
		private tasksService: TasksService,
		public dataService: DataService,
		private datePipe: DatePipe,
		private router: Router
	) {}

	ngOnInit() {
		$('#createTaskModal').modal({ backdrop: 'static', keyboard: false });
		this.entityPrefix = sessionStorage.getItem('entity_prefix');
		//GET THE MANAGEMENT TEAM
		this.usersService.fetchManagementTeam().subscribe(entityUsersData => {
			this.managementTeamList = entityUsersData;
			this.assignedToId = sessionStorage.getItem('userId');
			let user = this.managementTeamList.find(user => user.uid == this.assignedToId);
			this.createTaskForm.get('assignedToName').patchValue(`${user.firstname} ${user.surname}`);
		});
		this.buildTaskForm();
		this.datePickerInit();
	}

	closeTaskCreate() {
		$('#createTaskModal').modal('hide');
		this.dataService.openTaskCreate(false);
	}

	buildTaskForm() {
		this.createTaskForm = this.fb.group({
			description: ['', [Validators.required]],
			subject: ['', [Validators.required]],
			dueDate: ['', [Validators.required]],
			category: ['', [Validators.required]],
			assignedToId: ['', [Validators.required]],
			assignedToName: ['', [Validators.required]],
		});
	}

	setAssignedToCurrentUser() {
		this.assignedToId = sessionStorage.getItem('userId');
	}

	datePickerInit() {
		setTimeout(() => {
			$('#m_task_datepicker_1').datepicker({
				format: 'yyyy-mm-dd',
				todayHighlight: true,
				forceParse: false,
				orientation: 'bottom left',
				immediateUpdates: true,
				autoclose: true,
				templates: {
					leftArrow: '<i class="la la-angle-left"></i>',
					rightArrow: '<i class="la la-angle-right"></i>',
				},
			});
			$('#m_task_datepicker_1').on('changeDate', e => {
				this.createTaskForm.controls['dueDate'].markAsDirty();
				this.createTaskForm.patchValue({ dueDate: e.currentTarget.value });
			});
		}, 0);
	}

	getNewAssignedUser() {
		const assignedId = this.createTaskForm.get('assignedToId').value;
		let user = this.managementTeamList.find(user => user.uid == assignedId);
		this.createTaskForm.get('assignedToName').patchValue(`${user.firstname} ${user.surname}`);
	}

	createTask(taskValues) {
		taskValues['dueDate'] = $('#m_task_datepicker_1').datepicker('getUTCDate');
		this.isSubmitting = true;
		this.tasksService.createNewTask(this.createTaskForm.value).then(task => {
			this.taskId = task.id;
			this.tasksService.getTask(task.id).subscribe(taskData => {
				this.taskInfo = taskData;
				this.taskInfo.dueDate = this.datePipe.transform(this.taskInfo.dueDate.toDate(), 'yyyy-MM-dd');
				if (this.taskInfo.refNo) {
					this.isSubmitting = false;
					this.showSuccess = true;
				}
			});
		});
	}

	viewTask(id) {
		this.router.navigate([`/${this.entityId}/tasks/edit/` + id]);
		$('#createTaskModal').modal('hide');
		this.dataService.openTaskCreate(false);
	}

	ngAfterViewInit() {}
}
