import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare const $: any;
declare const mUtil: any;
@Injectable()
export class CustomFieldsService {
	entityId: any;
	constructor(public afs: AngularFirestore, private store: Store) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	fetchCustomFields() {
		const customFields = this.afs.collection(`entities/${this.entityId}/customFields`, ref => ref.where('active', '==', true).orderBy('label', 'asc'));

		return customFields.valueChanges({ idField: 'uid' });
	}

	fetchCustomFieldsForLocation(fetchLocation: string) {
		const customFieldsRef = this.afs.doc(fetchLocation);
		return customFieldsRef.valueChanges();
	}

	fetchCustomField(customFieldUID: string) {
		return this.afs
			.doc(`entities/${this.entityId}/customFields/${customFieldUID}`)
			.ref.get()
			.then(customFieldDetails => {
				const customFieldData = customFieldDetails.data();

				return customFieldData;
			});
	}

	createCustomInputField(label: string, section: string) {
		const userID = sessionStorage.getItem('userId');
		const customFields = this.afs.collection(`entities/${this.entityId}/customFields`);

		const data = {
			active: true,
			label: label,
			type: 'input',
			createdBy: userID,
			sections: [section],
		};

		return customFields.add(data);
	}

	createCustomSelectField(selectForm, section: string) {
		const userID = sessionStorage.getItem('userId');
		const customFields = this.afs.collection(`entities/${this.entityId}/customFields`);

		const data = {
			active: true,
			label: selectForm.label,
			options: selectForm.options,
			type: 'select',
			createdBy: userID,
			sections: [section],
		};

		return customFields.add(data);
	}

	createCustomCheckboxField(label: string, section: string) {
		const userID = sessionStorage.getItem('userId');
		const customFields = this.afs.collection(`entities/${this.entityId}/customFields`);

		const data = {
			active: true,
			label: label,
			type: 'checkbox',
			createdBy: userID,
			sections: [section],
		};

		return customFields.add(data);
	}

	createCustomDateField(dateForm, section: string) {
		const userID = sessionStorage.getItem('userId');
		const customFields = this.afs.collection(`entities/${this.entityId}/customFields`);

		const data = {
			active: true,
			label: dateForm.label,
			type: 'date',
			createdBy: userID,
			sections: [section],
		};

		return customFields.add(data);
	}

	createCustomAlternateDateField(alternateDateForm, section: string) {
		const userID = sessionStorage.getItem('userId');
		const customFields = this.afs.collection(`entities/${this.entityId}/customFields`);

		const data = {
			active: true,
			label: alternateDateForm.label,
			type: 'alternateDate',
			createdBy: userID,
			sections: [section],
		};

		return customFields.add(data);
	}

	public initMDatePickers(pickers: Array<string>, callback: (picker: string, date: Date) => void, helperBtns?: boolean, format = 'dd/mm/yyyy') {
		pickers.forEach(picker => {
			const isRTL = mUtil.isRTL();

			const t = {
				leftArrow: `<i class="la la-angle-${isRTL ? 'right' : 'left'}"></i>`,
				rightArrow: `<i class="la la-angle-${isRTL ? 'left' : 'right'}"></i>`,
			};

			setTimeout(() => {
				$(`#${picker}`).datepicker(
					helperBtns
						? {
								format,
								rtl: mUtil.isRTL(),
								todayBtn: 'linked',
								clearBtn: !0,
								todayHighlight: !0,
								templates: t,
						  }
						: null
				);

				$(`#${picker}`).on('changeDate', ({ date }) => {
					return callback(picker, date);
				});
			}, 0);
		});
	}

	public initAlternateDatePicker(pickers: Array<string>, callback: (picker: string, date: Date) => void, helperBtns?: boolean, format = 'mm/yyyy') {
		pickers.forEach(picker => {
			const isRTL = mUtil.isRTL();

			const t = {
				leftArrow: `<i class="la la-angle-${isRTL ? 'right' : 'left'}"></i>`,
				rightArrow: `<i class="la la-angle-${isRTL ? 'left' : 'right'}"></i>`,
			};

			setTimeout(() => {
				$(`#${picker}`).datepicker(
					helperBtns
						? {
								format,
								rtl: mUtil.isRTL(),
								todayBtn: 'linked',
								todayHighlight: !0,
								templates: t,
								startView: 2,
								clearBtn: true,
								minViewMode: 1,
								orientation: 'bottom',
								endDate: '+0d',
						  }
						: null
				);

				$(`#${picker}`).on('changeDate', ({ date }) => {
					return callback(picker, date);
				});
			}, 0);
		});
	}

	addCustomFieldToLocation(customFieldUID: string, saveLocation: string, section: string) {
		const customFieldLocation = this.afs.doc(saveLocation);

		const data = {
			customFieldUID: customFieldUID,
			value: '',
		};

		// FETCH ALREADY ADDED CUSTOM FIELDS
		return customFieldLocation.ref.get().then(locationDetails => {
			let customFields = [];

			const locationData = locationDetails.data() as any;

			if (locationData.customFields) {
				customFields = locationData.customFields;
			}

			// ADD NEW CUSTOM FIELD
			var checkCustomField = customFields.filter(function (item) {
				return item.customFieldUID === customFieldUID;
			});

			if (checkCustomField.length === 0) {
				customFields.push(data);

				customFieldLocation
					.set(
						{
							customFields: customFields,
						},
						{ merge: true }
					)
					.then(() => {
						// ADD SECTION TO ENTITY CUSTOM FIELDS
						const entityCustomFieldsRef = this.afs.doc(`entities/${this.entityId}/customFields/${customFieldUID}`);

						entityCustomFieldsRef.ref.get().then(entityCustomFieldDetails => {
							let sections = [];
							const entityCustomFieldData = entityCustomFieldDetails.data() as any;

							if (entityCustomFieldData.sections) {
								sections = entityCustomFieldData.sections;
							}

							var checkCustomFieldSections = sections.filter(item => {
								return item === section;
							});

							if (checkCustomFieldSections.length === 0) {
								sections.push(section);
							}

							entityCustomFieldsRef.set(
								{
									sections: sections,
								},
								{ merge: true }
							);
						});
					});
			}
		});
	}

	saveCustomFieldToLocation(saveLocation: string, customFields) {
		const customFieldLocationRef = this.afs.doc(saveLocation);

		return customFieldLocationRef.set(
			{
				customFields: customFields,
			},
			{ merge: true }
		);
	}

	removeCustomField(uid: string) {
		const customfieldRef = this.afs.doc(`/entities/${this.entityId}/customFields/${uid}`);

		return customfieldRef.update({
			active: false,
		});
	}
}
