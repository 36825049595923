export class TaskActivity {
	public message: string;
	public created: Date;
	public createdBy: string;
	public createdByName: string;
	public type: string;
	public id?: string;
	public fileName?: string;

	constructor() {
		this.created = new Date();
		this.type = 'system'; // Default system message and class
	}

	toObject() {
		return Object.assign({}, this);
	}
}
