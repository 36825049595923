import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { LeftMenuModule } from '../components/left-menu/left-menu.module';
import { NotesService } from './notes.service';
import { NgxPermissionsService, NgxPermissionsModule } from 'ngx-permissions';
import { EntitiesService } from '../entities/entities.service';
import { LayoutModule } from '../../../../layouts/layout.module';
import { NgPipesModule } from 'ngx-pipes';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		//LeftMenuModule,
		FormsModule,
		ReactiveFormsModule,
		// LayoutModule,
		NgxPermissionsModule.forChild(),
		NgPipesModule,
		PerfectScrollbarModule,
	],
	declarations: [NotesComponent],
	exports: [RouterModule, NotesComponent],
	providers: [NotesComponent],
})
export class NotesModule {
	constructor() {}
}
