import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@environments/environment';

interface ProductConfig {
	admin: string;
	client: string;
	enableNewPastelGeneralLedger: boolean;
	internalTaskNotifications: boolean;
	notifications_primary: string;
	notifications_secondary: string;
	notifications_tertiary: string;
	primary_task_manager: string;
	primary_task_team_member: string;
	amitiCloudWorkersUrl?: string;
}

@Injectable({
	providedIn: 'root',
})
export class ConfigsService {
	private product: string = environment.product;

	constructor(private afs: AngularFirestore) {}

	public async getProductConfig(product: string = this.product) {
		const configRef = this.afs.doc<ProductConfig>(`/amiti/config/products/${product}`).ref;
		const configSnap = await configRef.get();
		return configSnap.data();
	}
}
