<div class="m-stack m-stack--ver m-stack--general" style="min-height: 180px">
	<div class="m-stack__item m-stack__item--center m-stack__item--middle">
		<!-- new activities -->
		<!-- <div *ngIf="!loading else loading">  -->
		<div *ngIf="unreadActivities.length > 0 || readActivities.length > 0; else noActivities">
			<div class="m-scrollable m-scroller ps ps--active-y" data-scrollable="true" data-height="250" data-mobile-height="20" style="height: 250px; overflow: hidden">
				<div class="m-list-timeline m-list-timeline--skin-light">
					<div class="m-list-timeline__items">
						<!-- unread timeline item -->
						<div class="m-list-timeline__item" *ngFor="let activity of unreadActivities">
							<span class="m-list-timeline__badge -m-list-timeline__badge--state-success"></span>
							<span class="m-list-timeline__text"
								>{{ activity.subject }} - <span [class]="activity.status">{{ activity.status }}</span></span
							>
							<span class="m-list-timeline__time">{{ activity.created }}</span>
						</div>

						<!-- read timeline item -->
						<div class="m-list-timeline__item m-list-timeline__item--read" *ngFor="let activity of readActivities">
							<span class="m-list-timeline__badge"></span>
							<span class="m-list-timeline__text"
								>{{ activity.subject }} - <span [class]="activity.status">{{ activity.status }}</span></span
							>
							<span class="m-list-timeline__time">{{ activity.created }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- </div> -->

		<!-- no new activities -->
		<ng-template #noActivities>
			<span class="">All caught up!<br />No activities.</span>
		</ng-template>

		<!-- loader -->
		<!-- <ng-template #loading>
      <div class="row">
        <div class="col-md-12">
          <div class="text-center">
            <div class="m-loader m-loader--brand" style="width: 30px; display: inline-block;"></div>
          </div>
        </div>
      </div>
    </ng-template> -->
	</div>
</div>
