import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotificationsComponent } from './notifications.component';
import { NotificationService } from './notifications.service';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
	declarations: [NotificationsComponent],
	imports: [CommonModule, RouterModule, ClickOutsideModule],
	exports: [CommonModule, NotificationsComponent],
	providers: [NotificationService],
})
export class NotificationsModule {}
