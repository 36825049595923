import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
	private createSource = new BehaviorSubject<boolean>(false);
	currentCreation = this.createSource.asObservable();

	private customFieldsState = new BehaviorSubject<boolean>(false);
	currentCustomFieldsState = this.customFieldsState.asObservable();

	constructor() {}

	openTaskCreate(create: boolean) {
		this.createSource.next(create);
	}

	openCustomFields(open: boolean) {
		this.customFieldsState.next(open);
	}
}
