import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class IntroStepsService {
	public constructor(private afs: AngularFirestore) {}

	addClass(id, className) {
		const element = document.getElementById(id);
		if (element) {
			element.classList.add(className);
		}
	}

	removeClass(id, className) {
		const element = document.getElementById(id);
		if (element) {
			element.classList.remove(className);
		}
	}

	introMethod(loading, user) {
		if (loading === false) {
			setTimeout(() => {
				const { dashboardsteps, dashboardstep } = user;
				if (dashboardsteps !== 'complete') {
					document.getElementById('help-tips').classList.add('help-tips-show');
					const stepNo = dashboardstep || 1;
					this.goToNextStep(stepNo);
				}
			}, 500);
		}
	}

	goToNextStep(step, direction = 'next') {
		const stepNo = 'step' + step;
		const focusClass = 'focused-element';
		const headerSteps = [1, 6, 7, 8];
		this.addClass(`help-message-${step}`, 'focused-help-message');

		const elIdToRemove = direction === 'next' ? step - 1 : step + 1;
		this.removeClass(`step${elIdToRemove}`, focusClass);

		if (step === 9) {
			this.addClass('m_aside_left', focusClass);
		} else {
			this.removeClass('m_aside_left', focusClass);
		}

		if (headerSteps.includes(step)) {
			this.addClass('m_header', focusClass);
		} else {
			this.removeClass('m_header', focusClass);
		}

		this.addClass(stepNo, focusClass);
	}

	completeHelpSteps(endStep, userId, entityId) {
		// for (let i = 1; i <= 10; i++) {
		// 	this.removeClass(`help-message-${i}`, 'focused-help-message');
		// 	this.removeClass(`step-${i}`, 'focused-element');
		// }

		this.removeClass(`step${endStep}`, 'focused-element');
		this.removeClass(`help-message-${endStep}`, 'focused-help-message');

		this.removeClass('m_aside_left', 'focused-help-message');

		this.removeClass('help-tips', 'help-tips-show');

		document.getElementById('m_header').setAttribute('style', 'pointer-events: all !important');

		const userSteps = {
			dashboardsteps: 'complete',
			dashboardstep: endStep,
		};
		this.updateUserHelpSteps(userSteps, userId, entityId);
	}

	updateUserHelpSteps(user, userId, entityId) {
		const userSteps = this.afs.doc(`users/${userId}/entities/${entityId}`);
		return userSteps.update(user);
	}

	fetchEntityUser(uid, entityId) {
		const entityUserDetails = this.afs.doc(`users/${uid}/entities/${entityId}`);
		return entityUserDetails.valueChanges();
	}
}
