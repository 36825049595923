import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiesTabComponent } from './activities-tab.component';

@NgModule({
	imports: [CommonModule],
	declarations: [ActivitiesTabComponent],
	exports: [ActivitiesTabComponent],
})
export class ActivitiesTabModule {}
