import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { Title } from '@angular/platform-browser';

declare let document: any;

interface Script {
	src: string;
	loaded: boolean;
}

@Injectable()
export class CssLoaderService {
	public _scripts: Script[] = [];
	public type: any;

	constructor(private titleService: Title) {}

	/**
	 * @deprecated
	 * @param {string} type
	 * @param tag
	 * @param {string} scripts
	 * @returns {Promise<any[]>}
	 */
	load(tag, ...scripts: string[]) {
		scripts.forEach((src: string) => {
			if (!this._scripts[src]) {
				this._scripts[src] = { src: src, loaded: false };
			}
		});

		let promises: any[] = [];
		scripts.forEach(src => promises.push(this.loadScript(tag, src)));

		return Promise.all(promises);
	}

	/**
	 * Lazy load list of scripts
	 * @param tag
	 * @param scripts
	 * @param loadOnce
	 * @param {string} type
	 * @returns {Promise<any[]>}
	 */
	loadScripts(type, tag, scripts, loadOnce?: boolean) {
		this.type = type;
		loadOnce = loadOnce || false;

		scripts.forEach((script: string) => {
			if (!this._scripts[script]) {
				this._scripts[script] = { src: script, loaded: false };
			}
		});

		let promises: any[] = [];
		scripts.forEach(script => promises.push(this.loadScript(tag, script, loadOnce)));

		return Promise.all(promises);
	}

	/**
	 * Lazy load a single script
	 * @param tag
	 * @param {string} src
	 * @param loadOnce
	 * @returns {Promise<any>}
	 */
	loadScript(tag, src: string, loadOnce?: boolean) {
		loadOnce = loadOnce || false;

		if (!this._scripts[src]) {
			this._scripts[src] = { src: src, loaded: false };
		}

		return new Promise((resolve, reject) => {
			// resolve if already loaded
			if (this._scripts[src].loaded && loadOnce) {
				resolve({ src: src, loaded: true });
			} else {
				let scriptTag;
				if (this.type === 'fav') {
					scriptTag = $('<link/>').attr('href', this._scripts[src].src).attr('rel', 'shortcut icon');
				} else {
					if (this.type === 'title') {
						// scriptTag = $('<title/>');
						// scriptTag.append(src)
						// scriptTag.append('</title>')
						this.titleService.setTitle(src);
					} else {
						scriptTag = $('<link/>').attr('type', 'text/css').attr('href', this._scripts[src].src).attr('rel', 'stylesheet');
					}
				}

				$(tag).append(scriptTag);

				this._scripts[src] = { src: src, loaded: true };
				resolve({ src: src, loaded: true });
			}
		});
	}
}
