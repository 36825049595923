import { Injectable } from '@angular/core';
import { AuthenticationService } from '../_services';
import { take, map, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class IgloGuard implements CanActivate {
	userEntity;
	constructor(private _router: Router, private auth: AuthenticationService) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		return this.auth.userPerms.pipe(
			take(1),
			map(user => !!user),
			tap(loggedIn => {
				if (loggedIn && this._router.url == 'wpm') {
					return true;
				} else {
					return this._router.navigate(['/404']);
				}
			})
		);
	}
}
