import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Helpers } from './helpers';
import { NgxPermissionsService } from '../../node_modules/ngx-permissions';
import { PopOverService } from './_services/popovers.service';
import { CssLoaderService } from './_services/css-loader.service';
import { environment } from '../environments/environment';
import { SchemesService } from './theme/pages/default/management/entities/entities.service';
import { filter, take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
	title = 'app';
	globalBodyClass =
		'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

	constructor(
		private _router: Router,
		private permissionsService: NgxPermissionsService,
		public popOverService: PopOverService,
		private route: ActivatedRoute,
		public cssLoaderService: CssLoaderService,
		private schemesService: SchemesService,
		private titleService: Title
	) {}

	ngOnInit() {
		this.cssLoaderService.loadScripts('style', 'body', [`assets/${environment.product}/style.bundle.css`]);
		this.cssLoaderService.loadScripts('fav', 'head', [`assets/${environment.product}/favicon.png`]);
		let navigated = false;
		this._router.events.subscribe(route => {
			if (route instanceof NavigationStart) {
				Helpers.setLoading(true);
				Helpers.bodyClass(this.globalBodyClass);
				this.popOverService.hideHelp();
			}
			if (route instanceof NavigationEnd) {
				Helpers.setLoading(false);

				// ---------- TITLE TAG OPTIONS ---------- //
				const segmentsToIgnore = [
					'List', // first letter must be capital
					'Sports',
				]; // add title segments to this array to ignore them

				const pipeIgnores = [
					'Add', // first letter must be capital
					'Edit',
					'View',
					'Draft',
				]; // add items here to remove the | from their segment (this also adds plural correction)

				const pluralCorrectionIgnore = [
					{
						word: 'Games', // this is the word you don't want to be affected
						syllable: 'e', // this is the letter you don't want to remove
					},
					{
						word: 'News', // first letter must be capital
						syllable: 's',
					},
					{
						word: 'Sms',
						syllable: 's',
					},
					{
						word: 'Notices',
						syllable: 'e',
					},
				]; // add items here to ignore plural correction

				// README:
				// The title tags automatically discard ids by checking for a number.
				// If you add an item to ignore pipes (eg. edit for properies edit) it
				// automatically corrects the plural, by removing the s and replacing the
				// ie with the letter y. So the final product is in the proper singular form.
				// If the automatic plural correction results in an undesired effect
				// (eg. removing the s from Sms leaving you with Sm), you can add an item
				// to the pluralCorrectionIgnore Array. You should include the word and
				// syllable you want to restore.
				// --------------------------------------- //

				let Arr = [];
				const url = route.urlAfterRedirects.toLowerCase();
				let splitUrl = url.split('/');
				splitUrl.splice(0, 1);

				const entity = sessionStorage.getItem('entity_name');
				const product = environment.product.charAt(0).toUpperCase() + environment.product.substring(1);

				let title: string;

				if (entity === product || !entity) {
					title = `${product}`;
				} else {
					title = `${product} | ${entity}`;
				}

				for (let i = splitUrl.length; i > 0; i--) {
					if (splitUrl[i - 1] === 'entities') {
						title = `${product}`;
					}
					Arr.unshift(splitUrl[i - 1].charAt(0).toUpperCase() + splitUrl[i - 1].substring(1)); // turn the first letter of a segment to uppercase and push to array
				}

				Arr.forEach(Segment => {
					let segment = Segment;
					let splitSegment;

					if (segment.includes('?')) {
						splitSegment = segment.split('?');
						segment = splitSegment[0];
					}

					if (!/\d/.test(segment)) {
						let ignore: boolean = false;
						let ignorePipe: boolean = false;

						segmentsToIgnore.forEach(ignoredSegment => {
							if (segment === ignoredSegment) {
								ignore = true;
							}
						});

						pipeIgnores.forEach(ignoredPipe => {
							if (segment === ignoredPipe) {
								ignorePipe = true;
							}
						});

						if (!ignore) {
							if (ignorePipe) {
								let ignorePlural: boolean = false;
								let ignoreSyllable: string;

								pluralCorrectionIgnore.forEach(item => {
									let word = item.word;
									if (url.includes(`/${word.charAt(0).toLowerCase() + word.substring(1)}/`)) {
										ignorePlural = true;
										ignoreSyllable = item.syllable;
									}
								});
								if (ignoreSyllable !== 's') {
									if (title.charAt(title.length - 1) === 's') {
										// remove s
										title = title.substring(0, title.length - 1);
									}
								}
								if (ignoreSyllable !== 'e') {
									if (title.charAt(title.length - 1) === 'e' && title.charAt(title.length - 2) !== 'i') {
										// remove e
										title = title.substring(0, title.length - 1);
									}
								}
								if (!ignorePlural) {
									if (title.charAt(title.length - 1) === 'e' && title.charAt(title.length - 2) === 'i') {
										// if the last syllable is 'ie', change it to y
										title = title.substring(0, title.length - 2);
										title = title.concat(`y`);
									}
								}
								title = title.concat(` ${segment}`);
							} else if (title.substring(title.length - 9) === 'Documents' || title.includes('Documents/')) {
								// if you're in a folder or subfolder
								title = title.concat(`/${segment.charAt(0).toLowerCase() + segment.substring(1)}`);
							} else if (title.includes(`| Management | Db`)) {
								// if you're on the whitfields dashboard
								title = `${product} | Dashboard`;
							} else {
								if (segment != product) {
									title = title.concat(` | ${segment}`);
								}
							}
						}
					}
				});

				this.cssLoaderService.loadScripts('title', 'head', [title], false);
			}
		});
	}
}
