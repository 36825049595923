import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Task } from './models/task.model';
import { Watcher } from './models/watcher.model';
import { TaskActivity } from './models/activity.model';
import { AuditLogService } from '../audit-log/audit-log.service';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { TaskServiceProvider } from './models/serviceprovider.model';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase';
import { FirebaseApp } from '@angular/fire';
import { environment } from '../../../../../../environments/environment';
import { map, take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Group } from '../../faith/groups/group.model';
import { Property } from '@plex/property_models';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare var toastr: any;

@Injectable()
export class TasksService {
	tasksCollection: AngularFirestoreCollection<Task[]>;
	taskDoc: AngularFirestoreDocument<Task>;
	tasksWatchersCollection: AngularFirestoreCollection<Watcher[]>;
	tasksSPCollection: AngularFirestoreCollection<TaskServiceProvider[]>;
	tasksActivityCollection: AngularFirestoreCollection<TaskActivity[]>;
	taskFileDoc: AngularFirestoreDocument<any>;

	fieldConversions = {
		dueDate: 'Due Date',
		description: 'Description',
		subject: 'Subject',
		priority: 'Priority',
		status: 'Status',
		category: 'Category',
		assignedToId: 'Assigned To Id',
		assignedToName: 'Assigned To Name',
		tags: 'Tags',
		updatedByName: 'Updated By',
	};

	userId;
	entityId;
	constructor(
		public afs: AngularFirestore,
		public router: Router,
		private auditLogService: AuditLogService,
		private authService: AuthenticationService,
		private storage: AngularFireStorage,
		public fb: FirebaseApp,
		private datePipe: DatePipe,
		private store: Store
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	createDefaultTask() {
		//this.tasksCollection = this.afs.collection(`entities/${this.entityId}/tasks`);

		// console.log("User id : ", sessionStorage.getItem('userId'));

		let task = new Task();
		task.subject = '';
		task.description = '';
		task.assignedToId = '';
		environment.product === 'whitfields' ? (task.priority = 2) : (task.priority = 3);
		task.assignedToName = sessionStorage.getItem('userFirstname') + ' ' + sessionStorage.getItem('userSurname');
		task.created = new Date();
		task.createdBy = sessionStorage.getItem('userId');
		task.createdByName = sessionStorage.getItem('userFirstname') + ' ' + sessionStorage.getItem('userSurname');

		// console.log("Adding task to ", entityID, task);
		return this.afs
			.collection(`entities/${this.entityId}/tasks`)
			.add(task.toObject())
			.then(newTask => {
				let activity = new TaskActivity();
				activity.message = sessionStorage.getItem('userFirstname') + ' ' + sessionStorage.getItem('userSurname') + ' created this task.';
				this.addActivityToTask(this.entityId, newTask.id, activity.toObject());

				let logData = {
					name: 'Task was added',
					description: 'Task was added',
					type: 'added',
					category: 'tasks',
					created: Date.now(),
				};
				this.auditLogService.addAudit(logData);
				return newTask;
			});
	}

	createNewTask(task: Task) {
		const tasksCollection = this.afs.collection(`entities/${this.entityId}/tasks`);

		// console.log("User id : ", sessionStorage.getItem('userId'));
		task.created = new Date();
		task.createdBy = sessionStorage.getItem('userId');
		task.createdByName = sessionStorage.getItem('userFirstname') + ' ' + sessionStorage.getItem('userSurname');
		environment.product === 'whitfields' ? (task.priority = 2) : (task.priority = 3);
		task.status = '1';
		task.active = true;
		task.tags = [];
		task.type = 'general';

		// console.log("Adding task to ", entityID, task);
		return tasksCollection.add(task).then(newTask => {
			let activity = new TaskActivity();
			activity.message = sessionStorage.getItem('userFirstname') + ' ' + sessionStorage.getItem('userSurname') + ' created this task.';
			this.addActivityToTask(this.entityId, newTask.id, activity.toObject());

			let logData = {
				name: 'Task was added',
				description: 'Task was added',
				type: 'added',
				category: 'tasks',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
			return newTask;
		});
	}

	updateTask(taskId, task: Task, taskInfo: Task, prefix, refNo) {
		if (!taskInfo.refNo) {
			task.refNo =
				prefix +
				'-' +
				Math.floor(new Date().valueOf() * Math.random())
					.toString()
					.substring(2, 10);
		}
		task.updatedByName = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')}`;
		task.updatedBy = sessionStorage.getItem('userId');
		return this.afs
			.doc(`entities/${this.entityId}/tasks/${taskId}`)
			.set(task, { merge: true })
			.then(taskDetails => {});
	}

	_cleanNames(str: string) {
		// console.log(str)
		for (var key in this.fieldConversions) {
			str = str.replace(key, this.fieldConversions[key]);
		}
		return str;
	}

	updateTaskFiles(uploading, taskId, file, refNo) {
		let activity = new TaskActivity();
		activity.type = 'add';
		activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} added task File - ${file}`;
		let logData = {
			name: `${file} - ${refNo}`,
			description: `Task updated - Added File`,
			type: 'added',
			category: 'tasks',
			created: Date.now(),
		};

		if (uploading === true) {
			const logAllData = this.logTaskData(logData, taskId, activity);
			return Promise.all([logAllData]).then(() => {
				toastr.success('Added File');
			});
		}
	}

	updateTaskStatus(taskid, status) {
		const taskData = {
			status: status,
		};
		if (taskid != 'blank') {
			return this.afs
				.doc(`entities/${this.entityId}/tasks/${taskid}`)
				.update(taskData)
				.then(taskDetails => {
					// console.log('Service Success');
				});
		}
	}

	addActivityToTask(entityId, taskId, activity) {
		this.tasksActivityCollection = this.afs.collection<TaskActivity[]>(`entities/${entityId}/tasks/${taskId}/activity/`);

		activity.createdBy = sessionStorage.getItem('userId');
		activity.createdByName = sessionStorage.getItem('userFirstname') + ' ' + sessionStorage.getItem('userSurname');

		return this.tasksActivityCollection.add(activity);
	}

	addActivityToTaskFile(taskId, fileId, activity) {
		const taskFileComment = this.afs.collection<TaskActivity[]>(`entities/${this.entityId}/tasks/${taskId}/files/${fileId}/comments`);

		activity.createdBy = sessionStorage.getItem('userId');
		activity.createdByName = sessionStorage.getItem('userFirstname') + ' ' + sessionStorage.getItem('userSurname');

		return taskFileComment.add(activity);
	}

	fetchActivityToTaskFile(taskId, fileId) {
		const taskFileComments = this.afs.collection(`entities/${this.entityId}/tasks/${taskId}/files/${fileId}/comments`, ref => ref.orderBy('created', 'desc'));
		return taskFileComments.valueChanges({ idField: 'id' });
	}

	fetchUserTasks() {
		const userID = sessionStorage.getItem('userId');

		const tasksRef = this.afs.collection(`entities/${this.entityId}/tasks`, ref => ref.where('assignedToId', '==', userID).where('active', '==', true));
		return tasksRef.valueChanges({ idField: 'id' });
	}

	getSchemeTasks() {
		this.tasksCollection = this.afs.collection(`entities/${this.entityId}/tasks`, ref => ref.orderBy('created', 'desc').where('active', '==', true));

		return this.tasksCollection.valueChanges({ idField: 'id' });
	}

	getTaskActivities(taskdId) {
		return this.afs
			.collection<TaskActivity>(`entities/${this.entityId}/tasks/${taskdId}/activity`, ref => ref.orderBy('created', 'desc'))
			.valueChanges();
	}

	getServiceProviders() {
		const serviceProvidersRef = this.afs.collection(`/entities/${this.entityId}/serviceProviders`, ref => ref.where('active', '==', true).orderBy('name', 'asc'));

		return serviceProvidersRef.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as TaskServiceProvider;
					const spData = {
						uid: a.payload.doc.id,
						name: data.name,
					};
					return spData;
				});
			})
		);
	}

	addExistingServiceProvider(spUid: string, taskId: string, refNo: string) {
		const userID = sessionStorage.getItem('userId');
		const taskServiceProvidersRef = this.afs.collection(`entities/${this.entityId}/tasks/${taskId}/serviceProviders`);
		const spRef = this.afs.doc(`entities/${this.entityId}/serviceProviders/${spUid}`).ref;

		return spRef.get().then(spDetails => {
			const spData = spDetails.data() as any;

			return taskServiceProvidersRef
				.add({
					spUID: spUid,
					createdBy: userID,
					created: Date.now(),
				})
				.then(() => {
					let activity = new TaskActivity();
					activity.type = 'add';
					activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} added task Provider - ${spData.name}`;
					let logData = {
						name: `${spData.name} - ${refNo}`,
						description: `Task updated - Added Provider`,
						type: 'info',
						category: 'tasks',
						created: Date.now(),
					};
					this.logTaskData(logData, taskId, activity);
					toastr.success(`Added Provider`);
				});
		});
	}

	addNewServiceProvider(spDetails, taskId: string, refNo: string) {
		const serviceProvidersRef = this.afs.collection(`entities/${this.entityId}/serviceProviders`);

		const data = {
			name: spDetails.name,
			description: spDetails.description,
			type: spDetails.type,
			active: true,
			isPrivate: false,
			sponsor: false,
			created: Date.now(),
		};

		return serviceProvidersRef.add(data).then(results => {
			return this.addExistingServiceProvider(results.id, taskId, refNo).then(() => {
				toastr.success('Service provider has been successfully created!');
				let logData = {
					name: `${spDetails.name}`,
					description: `Service Provider created`,
					type: 'info',
					category: 'tasks',
					created: Date.now(),
				};
				this.logTaskData(logData);
			});
		});
	}

	getTaskServiceProviders(taskdId) {
		const spCollection = this.afs.collection<TaskServiceProvider>(`entities/${this.entityId}/tasks/${taskdId}/serviceProviders`);
		return spCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as TaskServiceProvider;
					data.uid = a.payload.doc.id;
					this.getServiceProviderDetails(data.spUID)
						.pipe(take(1))
						.subscribe(spDetails => {
							data.name = spDetails.name;
							data.description = spDetails.description;
							data.type = spDetails.type;
							data.websiteUrl = '';
							if (spDetails.websiteUrl) {
								data.websiteUrl = spDetails.websiteUrl;
							}
							data.downloadFile = '';
							if (spDetails.downloadFile) {
								data.downloadFile = spDetails.downloadFile;
							}
							data.downloadFileThumbnail = '';
							if (spDetails.downloadFileThumbnail) {
								data.downloadFileThumbnail = spDetails.downloadFileThumbnail;
							}
							data.primary_tel = '';
							if (spDetails.primary_tel) {
								data.primary_tel = spDetails.primary_tel;
							}
							data.primary_cell = '';
							if (spDetails.primary_cell) {
								data.primary_cell = spDetails.primary_cell;
							}
							data.primary_email = '';
							if (spDetails.primary_email) {
								data.primary_email = spDetails.primary_email;
							}
						});
					return data;
				});
			})
		);
	}

	getServiceProviderDetails(uid: string) {
		return this.afs.doc<TaskServiceProvider>(`entities/${this.entityId}/serviceProviders/${uid}`).valueChanges();
	}

	removeServiceProviderFromTask(taskId, spId, spName, refNo) {
		// console.log("Removing ServiceProvider From Task", taskId, spId, spName);

		return this.afs
			.doc(`entities/${this.entityId}/tasks/${taskId}/serviceProviders/${spId}`)
			.delete()
			.then(() => {
				let activity = new TaskActivity();
				activity.type = 'remove';
				activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} removed task Provider - ${spName}`;

				let logData = {
					name: `${spName} - ${refNo}`,
					description: `Task updated - Removed Provider`,
					type: 'info',
					category: 'tasks',
					created: Date.now(),
				};
				this.logTaskData(logData, taskId, activity);
				toastr.success(`Removed Provider`);
			});
	}

	getTaskWatchers(taskdId) {
		return this.afs
			.collection<Watcher>(`entities/${this.entityId}/tasks/${taskdId}/watchers`, ref => ref.orderBy('watcherUserName', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	getTaskTeamMembers(taskId) {
		return this.afs
			.collection<any>(`entities/${this.entityId}/tasks/${taskId}/teamMembers`, ref => ref.orderBy('memberUserName', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	getTask(taskId) {
		return this.afs.doc<Task>(`entities/${this.entityId}/tasks/${taskId}`).valueChanges();
	}

	getTaskFiles(taskdId) {
		return this.afs
			.collection<any>(`entities/${this.entityId}/tasks/${taskdId}/files`, ref => ref.orderBy('created', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	downloadTaskFile(filePath, fileName, fileType): void {
		// console.log(fileName);
		let storageRef = this.fb.storage().ref();
		// console.log('storageRef', storageRef);
		storageRef
			.child(filePath)
			.getDownloadURL()
			.then(url => {
				const xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';
				xhr.onload = event => {
					/* Create a new Blob object using the response
					 *  data of the onload object.
					 */
					const blob = new Blob([xhr.response], { type: fileType });
					const a: any = document.createElement('a');
					a.style = 'display: none';
					document.body.appendChild(a);
					const url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = fileName;
					a.click();
					window.URL.revokeObjectURL(url);
				};
				xhr.open('GET', url);
				xhr.send();
			})
			.catch(function (error) {
				// Handle any errors
				console.log(error);
			});
	}

	deleteTaskFile(taskId, file, refNo) {
		this.taskFileDoc = this.afs.doc(`entities/${this.entityId}/tasks/${taskId}/files/${file.id}`);
		return this.taskFileDoc
			.update({
				active: false,
			})
			.then(() => {
				let activity = new TaskActivity();
				activity.type = 'remove';
				activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} removed task File - ${file.name}`;

				let logData = {
					name: `${file.name} - ${refNo}`,
					description: `Task updated - Removed File`,
					type: 'deleted',
					category: 'tasks',
					created: Date.now(),
				};
				const logAllData = this.logTaskData(logData, taskId, activity);
				return Promise.all([logAllData]).then(() => {
					toastr.success('Removed File');
				});
			});
	}

	addWatcherToTask(taskId, watcher, refNo) {
		// console.log("Adding Watch To Task", taskId, watcher);
		this.tasksWatchersCollection = this.afs.collection(`entities/${this.entityId}/tasks/${taskId}/watchers/`);
		let tasksWatcherCollection = this.afs.doc(`entities/${this.entityId}/tasks/${taskId}/watchers/${watcher.watcherUserId}`);
		let activity = new TaskActivity();
		activity.type = 'add';
		activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} added task Watcher - ${watcher.watcherUserName}`;

		return tasksWatcherCollection.set(watcher.toObject()).then(() => {
			let logData = {
				name: `${watcher.watcherUserName} - ${refNo}`,
				description: `Task updated - Added Watcher`,
				type: 'info',
				category: 'tasks',
				created: Date.now(),
			};
			this.logTaskData(logData, taskId, activity);
			this.informWatcher(taskId, watcher, 'inviteTaskWatcher');
			toastr.success(`Added Watcher`);
		});
	}

	addTeamMemberToTask(taskId, member, refNo) {
		const taskTeamMemberDoc = this.afs.doc(`entities/${this.entityId}/tasks/${taskId}/teamMembers/${member.memberUserId}`);
		let activity = new TaskActivity();
		activity.type = 'add';
		activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} added task Team Member - ${member.memberUserName}`;

		return taskTeamMemberDoc.set(member.toObject()).then(() => {
			let logData = {
				name: `${member.watcherUserName} - ${refNo}`,
				description: `Task updated - Added Team Member`,
				type: 'info',
				category: 'tasks',
				created: Date.now(),
			};
			this.logTaskData(logData, taskId, activity);
			this.informTeamMember(taskId, member, 'notifyTaskTeamChange');
			toastr.success(`Added Team Member`);
		});
	}

	informWatcher(taskId, watcher, request) {
		const taskCollectionRef = this.afs.collection('pending');

		const encodedBase64Data = btoa(`/${this.entityId}/tasks/redirect/${taskId}/${this.entityId}`);
		const taskURL = `${environment.admin}/redirect/${encodedBase64Data}`;

		let data = {
			request: request,
			entityId: this.entityId,
			taskId: taskId,
			watcherId: watcher.watcherUserId,
			watcherName: watcher.watcherUserName,
			admin: environment.admin,
			taskUrl: taskURL,
			product: environment.product,
		};
		return taskCollectionRef.add(data);
	}

	informAssignedUser(taskId, assignedId, assignedUsername, request) {
		const taskCollectionRef = this.afs.collection('pending');

		const encodedBase64Data = btoa(`/${this.entityId}/tasks/redirect/${taskId}/${this.entityId}`);
		const taskURL = `${environment.admin}/redirect/${encodedBase64Data}`;

		let data = {
			request: request,
			entityId: this.entityId,
			taskId: taskId,
			watcherId: assignedId,
			watcherName: assignedUsername,
			admin: environment.admin,
			taskUrl: taskURL,
			product: environment.product,
		};
		return taskCollectionRef.add(data);
	}

	informTeamMember(taskId, member, request) {
		const taskCollectionRef = this.afs.collection('pending');

		const encodedBase64Data = btoa(`/${this.entityId}/tasks/redirect/${taskId}/${this.entityId}`);
		const taskURL = `${environment.admin}/redirect/${encodedBase64Data}`;

		let data = {
			request: request,
			entityId: this.entityId,
			taskId: taskId,
			memberId: member.memberUserId,
			memberName: member.memberUserName,
			admin: environment.admin,
			taskUrl: taskURL,
			notificationType: 'notifyNewTeamMember',
			product: environment.product,
		};
		return taskCollectionRef.add(data);
	}

	sendTaskUpdateToTaskTeamMember(taskId, member) {
		const taskCollectionRef = this.afs.collection('pending');

		const encodedBase64Data = btoa(`/${this.entityId}/tasks/redirect/${taskId}/${this.entityId}`);
		const taskURL = `${environment.admin}/redirect/${encodedBase64Data}`;

		let data = {
			request: 'notifyTaskTeamChange',
			entityId: this.entityId,
			taskId: taskId,
			memberId: member.memberUserId,
			membeName: member.memberUserName,
			admin: environment.admin,
			taskUrl: taskURL,
			notificationType: 'notifyAllTeamMembers',
			product: environment.product,
		};
		return taskCollectionRef.add(data);
	}

	removeWatcherFromTask(taskId, watcherId, watcherName, refNo) {
		// console.log("Removing Watcher From Task", taskId, watcherId, watcherName);
		return this.afs
			.doc(`entities/${this.entityId}/tasks/${taskId}/watchers/${watcherId}`)
			.delete()
			.then(() => {
				let activity = new TaskActivity();
				activity.type = 'remove';
				activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} removed task Watcher - ${watcherName}`;
				let logData = {
					name: `${watcherName} - ${refNo}`,
					description: `Task updated - Removed Watcher`,
					type: 'info',
					category: 'tasks',
					created: Date.now(),
				};
				this.logTaskData(logData, taskId, activity);
				toastr.success(`Removed Watcher`);
			});
	}

	removeTeamMemberFromTask(taskId, memberId, memberName, refNo) {
		return this.afs
			.doc(`entities/${this.entityId}/tasks/${taskId}/teamMembers/${memberId}`)
			.delete()
			.then(() => {
				let activity = new TaskActivity();
				activity.type = 'remove';
				activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} removed task Team Member - ${memberName}`;

				let logData = {
					name: `${memberName} - ${refNo}`,
					description: `Task updated - Removed Team Member`,
					type: 'info',
					category: 'tasks',
					created: Date.now(),
				};
				this.logTaskData(logData, taskId, activity);
				toastr.success(`Removed Team Member`);
			});
	}

	notifyTaskReassign(taskId, assignedToId, admin) {
		const pendingNotifyTaskCreatedRef = this.afs.collection('pending');

		const encodedBase64Data = btoa(`/${this.entityId}/tasks/redirect/${taskId}/${this.entityId}`);
		const taskUrl = `${environment.admin}/redirect/${encodedBase64Data}`;

		pendingNotifyTaskCreatedRef.add({
			request: 'notifyTaskCreated',
			entityId: this.entityId,
			taskId,
			assignedToId,
			admin,
			taskUrl,
			product: environment.product,
		});
	}

	notifyTaskReassignedChange(taskId, assignedToId, admin, product) {
		const pendingNotifyTaskCreatedRef = this.afs.collection('pending');

		const encodedBase64Data = btoa(`/${this.entityId}/tasks/redirect/${taskId}/${this.entityId}`);
		const taskUrl = `${environment.admin}/redirect/${encodedBase64Data}`;

		pendingNotifyTaskCreatedRef.add({
			request: 'notifyTaskReassignChange',
			entityId: this.entityId,
			taskId,
			assignedToId,
			admin,
			taskUrl,
			product: environment.product,
		});
	}

	removeTask(taskId: string) {
		this.afs.doc(`entities/${this.entityId}/tasks/${taskId}`).set(
			{
				active: false,
			},
			{ merge: true }
		);
	}

	getTaskProperties(taskdId) {
		return this.afs
			.collection<Property>(`entities/${this.entityId}/tasks/${taskdId}/properties`, ref => ref.orderBy('property_number', 'asc'))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as Property;
						return data;
					});
				})
			);
	}

	getTaskGroups(taskdId) {
		return this.afs
			.collection<Group>(`entities/${this.entityId}/tasks/${taskdId}/groups`, ref => ref.orderBy('name', 'asc'))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as Group;
						return data;
					});
				})
			);
	}

	addPropertyToTask(taskId, task, property, refNumber) {
		// console.log("Adding Watch To Task", taskId, property);
		const propertiesDoc = this.afs.doc(`entities/${this.entityId}/tasks/${taskId}/properties/${property.id}`);
		let activity = new TaskActivity();
		activity.type = 'add';
		activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} added task Property - ${property.property_number}`;

		return propertiesDoc.set(property).then(() => {
			const tasksDoc = this.afs.doc(`entities/${this.entityId}/properties/${property.id}/tasks/${taskId}`);
			let refNo = '';
			if (task.refNo) {
				refNo = task.refNo;
			}
			let taskInfo = {
				subject: task.subject,
				status: task.status,
				priority: task.priority,
				id: taskId,
				refNo: refNo,
				created: task.created,
				dueDate: task.dueDate,
			};
			return tasksDoc.set(taskInfo).then(() => {
				let logData = {
					name: `${property.property_number} - ${refNumber}`,
					description: `Task updated - Added Property`,
					type: 'info',
					category: 'properties',
					created: Date.now(),
				};
				this.logTaskData(logData, taskId, activity);
				toastr.success(`Added Property`);
			});
		});
	}

	addGroupToTask(taskId, task, group, refNumber) {
		// console.log("Adding Watch To Task", taskId, group);
		const propertiesDoc = this.afs.doc(`entities/${this.entityId}/tasks/${taskId}/groups/${group.id}`);
		let activity = new TaskActivity();
		activity.type = 'add';
		activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} added task group - ${group.name}`;

		return propertiesDoc.set(group).then(() => {
			const tasksDoc = this.afs.doc(`entities/${this.entityId}/groups/${group.id}/tasks/${taskId}`);
			let refNo = '';
			if (task.refNo) {
				refNo = task.refNo;
			}
			let taskInfo = {
				subject: task.subject,
				status: task.status,
				priority: task.priority,
				id: taskId,
				refNo: refNo,
				created: task.created,
				dueDate: task.dueDate,
			};
			return tasksDoc.set(taskInfo).then(() => {
				let logData = {
					name: `${group.name} - ${refNumber}`,
					description: `Task updated - Added group`,
					type: 'info',
					category: 'groups',
					created: Date.now(),
				};
				this.logTaskData(logData, taskId, activity);
				toastr.success(`Added group`);
			});
		});
	}

	updatePropertyTask(taskId, task, property) {
		const tasksDoc = this.afs.doc(`entities/${this.entityId}/properties/${property.id}/tasks/${taskId}`);
		let taskInfo = {
			subject: task.subject,
			status: task.status,
			priority: task.priority,
			id: taskId,
			refNo: task.refNo,
			created: task.created,
			dueDate: task.dueDate,
		};
		return tasksDoc.update(taskInfo);
	}

	removePropertyFromTask(taskId, propertyId, propertyName, refNo) {
		// console.log("Removing Watcher From Task", taskId, propertyId, propertyName);
		return this.afs
			.doc(`entities/${this.entityId}/tasks/${taskId}/properties/${propertyId}`)
			.delete()
			.then(() => {
				let activity = new TaskActivity();
				activity.type = 'remove';
				activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} removed task Property - ${propertyName}`;
				this.afs
					.doc(`entities/${this.entityId}/properties/${propertyId}/tasks/${taskId}`)
					.delete()
					.then(() => {
						let logData = {
							name: `${propertyName} - ${refNo}`,
							description: `Task updated - Removed Property`,
							type: 'info',
							category: 'properties',
							created: Date.now(),
						};
						this.logTaskData(logData, taskId, activity);
						toastr.success(`Removed Property`);
					});
			});
	}

	removeGroupFromTask(taskId, groupId, groupName, refNo) {
		// console.log("Removing Watcher From Task", taskId, groupId, groupName);
		return this.afs
			.doc(`entities/${this.entityId}/tasks/${taskId}/groups/${groupId}`)
			.delete()
			.then(() => {
				let activity = new TaskActivity();
				activity.type = 'remove';
				activity.message = `${sessionStorage.getItem('userFirstname')} ${sessionStorage.getItem('userSurname')} removed task Group - ${groupName}`;
				this.afs
					.doc(`entities/${this.entityId}/groups/${groupId}/tasks/${taskId}`)
					.delete()
					.then(() => {
						let logData = {
							name: `${groupName} - ${refNo}`,
							description: `Task updated - Removed Group`,
							type: 'info',
							category: 'groups',
							created: Date.now(),
						};
						this.logTaskData(logData, taskId, activity);
						toastr.success(`Removed Group`);
					});
			});
	}

	// LOG DATA
	logTaskData(logData, taskId?, activity?) {
		if (taskId) {
			this.addActivityToTask(this.entityId, taskId, activity.toObject());
		}
		this.auditLogService.addAudit(logData);
	}

	getUserWhitfieldsTasks() {
		const userId = sessionStorage.getItem('userId');
		// const collRef = this.afs.collection(`entities/whitfields/tasks`, ref => ref.where('active', '==', true));
		const collRef = this.afs.collection(`entities/whitfields/tasks`, ref => ref.where('active', '==', true).where('assignedToId', '==', userId));
		return collRef.valueChanges();
	}

	getUserWhitfieldsLastReadDate() {
		const userId = sessionStorage.getItem('userId');
		const collRef = this.afs.doc(`/users/${userId}/entities/whitfields/notifications/lastReadDate`);
		return collRef.valueChanges();
	}

	updateUserWhitfieldsLastReadDate() {
		const userId = sessionStorage.getItem('userId');
		const collRef = this.afs.doc(`/users/${userId}/entities/whitfields/notifications/lastReadDate`);
		const now = Date.now(); // Unix timestamp in milliseconds

		return collRef.set({
			date: now,
		});
	}
}
