import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

import { EntityDashboardComponent } from './theme/pages/default/management/dashboards/entity-dashboard/entity-dashboard.component';

/**
 * If a snapshot is detached from the routing and re-attached later, there should be no memory leak.
 * But if a snapshot is detached from the routing and never re-attached to the router, we need to take care of the memory management.
 *
 * TLDR: Handle custom logic in shouldDetach. If it's never detached, you don't need to worry about re-attaching to destroy your components.
 */
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
	private storedRoutes = new Map<string, DetachedRouteHandle>();
	private routesToCache = [
		// Will re-render if the entityId route param changes
		{ route: 'entity-dashboard', component: EntityDashboardComponent },
	];

	private futureParamEntityId: string;
	private currParamEntityId: string;

	/**
	 * Asks if the snapshot should be detached from the router.
	 * That means that the router will no longer handle this snapshot after it has been stored by calling the store-method.
	 *
	 * Decides when the route should be stored
	 * If the route should be stored, I believe the boolean is indicating to a controller whether or not to fire this.store
	 * _When_ it is called though does not particularly matter, just know that this determines whether or not we store the route
	 * An idea of what to do here: check the route.routeConfig.path to see if it is a path you would like to store
	 *
	 * @param route This is, at least as I understand it, the route that the user is currently on, and we would like to know if we want to store it
	 * @returns boolean indicating that we want to (true) or do not want to (false) store that route
	 */
	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		if (this.futureParamEntityId !== this.currParamEntityId) {
			return false;
		}

		const shouldCache = this.routesToCache.some(toCache => {
			const isSamePath = toCache.route === route.routeConfig!.path;
			const isSameComponent = toCache.component === route.routeConfig!.component;
			return isSamePath && isSameComponent && !route.paramMap.has('entityId');
		});

		return shouldCache;
	}

	/**
	 * After the router has asked by using the shouldDetach-method and it returned true, the store-method is called (not immediately but some time later).
	 * If the router sends you a null-value, you can delete this entry from your storage. No need to take care about the memory. Angular should handle this.
	 *
	 * Constructs route to store, and then stores it for later attachment
	 *
	 * @param route This is stored for later comparison to requested routes, see `this.shouldAttach`
	 * @param handle Later to be retrieved by this.retrieve, and offered up to whatever controller is using this class
	 */
	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		this.storedRoutes.set(route.routeConfig!.path!, handle);
	}

	/**
	 * Asks if a snapshot for the current route already has been stored.
	 * Return true, if your storage contains the right snapshot and the router should re-attach this snapshot to the routing.
	 *
	 * Determines whether or not there is a stored route and, if there is, whether or not it should be rendered in place of requested route
	 *
	 * @param route The route the user requested
	 * @returns boolean indicating whether or not to render the stored route
	 */
	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		return !!this.storedRoutes.get(route.routeConfig!.path!);
	}

	/**
	 * Load the snapshot from your storage. It's only called, if the shouldAttach-method returned true
	 *
	 * Finds the locally stored instance of the requested route, if it exists, and returns it.
	 *
	 * @param route New route the user has requested
	 * @returns DetachedRouteHandle object which can be used to render the component
	 */
	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		return this.storedRoutes.get(route.routeConfig?.path!) as DetachedRouteHandle;
	}

	/**
	 * Asks if a snapshot from the current routing can be used for the future routing.
	 *
	 * Determines whether or not the current route should be reused.
	 *
	 * @param future The route the user is going to, as triggered by the router
	 * @param curr The route the user is currently on
	 * @returns boolean basically indicating true if the user intends to leave the current route
	 */
	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		// Store entityId param in state for usage in shouldAttach to know whether or not to rerender if the entityId changes.
		const futureParamEntityId = future.params.entityId;
		const currParamEntityId = curr.params.entityId;

		if (futureParamEntityId) {
			this.futureParamEntityId = futureParamEntityId;
		}

		if (currParamEntityId) {
			this.currParamEntityId = currParamEntityId;
		}

		return future.routeConfig === curr.routeConfig;
	}
}
