import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { ManagersService } from './managers.service';
import { EntitiesService } from '../../entities/entities.service';
import { CommunicationsService } from '../../communications/communications.service';
import { UsersService } from '../../users/users.service';
import { User } from '../../users/user.model';
import { ManagementUser } from '../../management-team/management.model';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { config } from '../../../../../../../configs/configs';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { PopOverService } from '../../../../../../_services/popovers.service';
import { take } from 'rxjs/operators';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';

declare const toastr: any;

@Component({
	selector: 'app-managers',
	templateUrl: './managers.component.html',
	styleUrls: ['./managers.component.scss'],
})
export class ManagersComponent implements OnInit {
	config = config[environment.product];
	envType = environment.product;
	users = [];
	usersCollection: AngularFirestoreCollection<ManagementUser[]>;
	userDoc: AngularFirestoreDocument<ManagementUser>;
	showAddSection = false;
	addingSpinner = false;
	showAddProfileSection = false;
	showRemoveProfileSection = false;
	addExistingLoader: boolean = false;
	inviteLoader: boolean = false;

	generatingSms: boolean = false;

	user: ManagementUser;
	existingUsersList: User[];
	existingUserType = '';
	userSubscription: Subscription;
	fetchManagerUsersSubscription: Subscription;
	managersListSubscription: Subscription;

	addExistingUserForm: FormGroup;
	addProfileForm: FormGroup;

	showPopover: boolean;
	hasUsersCells: boolean = false;
	addProfileOtherSelected = false;

	types = [
		{
			name: 'Scheme Manager',
			value: 'Scheme Manager',
		},
		{
			name: 'Other',
			value: 'Other',
		},
	];
	entityId: any;

	constructor(
		public afs: AngularFirestore,
		private entitiesService: EntitiesService,
		public usersService: UsersService,
		private communicationsService: CommunicationsService,
		private managersService: ManagersService,
		public _router: Router,
		private fb: FormBuilder,
		public popOverService: PopOverService,
		private store: Store
	) {}

	ngOnInit() {
		this.store
			.select(selectEntityId)
			.pipe(take(1))
			.subscribe(entityId => (this.entityId = entityId));
		this.buildform();
		this.fetchManagers();
		this._router.events.subscribe(evt => {
			if (evt instanceof NavigationEnd) {
				this.fetchManagers();
				// trick the Router into believing it's last link wasn't previously loaded
				this._router.navigated = false;
				// if you need to scroll back to top, here is the right place
				window.scrollTo(0, 0);
			}
		});
		this.managersService.getSchemeManagers().subscribe(managers => {
			this.users = managers;
		});
	}

	fetchManagers() {
		// FETCH MANAGEMENT USERS
		this.fetchManagerUsersSubscription = this.usersService.fetchManagers().subscribe(usersData => {
			this.users = usersData;
			this.users.forEach(user => {
				if (user.cell) {
					this.hasUsersCells = true;
				}
			});
		});

		// FETCH ENTITY USERS LIST FOR ADDING A USER TO A PROPERTY
		this.userSubscription = this.entitiesService.getUsersForEntity().subscribe(entityUsersData => {
			this.existingUsersList = entityUsersData;
			// this.existingUsersList.unshift({
			//     firstname: 'Select User',
			//     surname: '',
			//     uid: ''
			// });
		});

		this.popOverService.showPopoverChange.subscribe(value => {
			this.showPopover = value;
		});
	}

	addManagerSectionToggle() {
		this.showAddSection = !this.showAddSection;
		this.addExistingUserForm.reset();
	}

	addExistsingUser(form) {
		this.user = this.existingUsersList.filter((user: any) => {
			return user.uid === form.existingUserID;
		})[0];

		this.addExistingLoader = true;

		const formData = {
			type: form.existingUserType,
		};
		this.addUserToManagementTeam(formData);
	}

	addUserToManagementTeam(form) {
		this.userDoc = this.afs.doc(`entities/${this.entityId}/management/users/list/${this.user.uid}`);
		let profiles = [];
		this.addingSpinner = true;
		this.showAddSection = false;

		// SET ADMIN, SCHEME EXECUTIVE AND VIEW ONLY PROFILES
		this.user.isAdmin = false;
		this.user.isSchemeManager = false;
		this.user.isSchemeExecutive = false;
		this.user.isViewOnly = false;

		if (form.type === 'Scheme Manager' || form.type === null) {
			this.user.isSchemeManager = true;
		} else {
			profiles.push(form.type);
		}
		if (this.addExistingUserForm.value.permission === 'admin') {
			this.user.isAdmin = true;
		}
		// CHECK IF USER HAS PREVIOUS PROFILES
		this.userDoc
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(snap => {
				// IF USER HAS PREVIOUS PROFILES LOOP THROUGH THEM AND PREVENT DUPLICATE PROFILE BEING ADDED
				if (form.type !== 'Scheme Manager') {
					snap.payload.data().profiles.forEach(profile => {
						if (form.type !== profile) {
							profiles.push(profile);
						}
					});
					this.user.profiles = profiles;
				} else {
					this.user.profiles = snap.payload.data().profiles;
				}
				this.userDoc.update(this.user).then(() => {
					this.addingSpinner = false;
					this.inviteLoader = false;
					this.addExistingLoader = false;
					if (environment.product === 'whitfields') {
						this.entitiesService.sendManagerAddedNotification(this.entityId, this.user);
					}
					if (this.user.isAdmin === true) {
						this.entitiesService.sendAdminNotificationEmail(this.entityId, this.user.uid);
					}
					this.updateUserPermissions();
				});
			})
			.catch(error => {
				// ADD USER TO MANAGEMENT TEAM
				this.user.profiles = profiles;
				this.user.active = true;
				this.userDoc.set(this.user).then(() => {
					this.addingSpinner = false;
					this.inviteLoader = false;
					this.addExistingLoader = false;
					if (environment.product === 'whitfields') {
						this.entitiesService.sendManagerAddedNotification(this.entityId, this.user);
					}
					if (this.user.isAdmin === true) {
						this.entitiesService.sendAdminNotificationEmail(this.entityId, this.user.uid);
					}
					this.updateUserPermissions();
				});
			});

		this.addExistingUserForm.reset();
	}

	updateUserPermissions() {
		// BUILD PERMISSIONS
		const permissions = [];
		if (this.user.isSchemeManager === true) {
			permissions.push('scheme_manager');
		}
		if (this.addExistingUserForm.value.permission === 'admin') {
			permissions.push('admin');
		}

		// UPDATE USER PERMISSIONS FOR ENTITY
		const userEntityRef = this.afs.collection('users').doc(this.user.uid).collection('entities').doc(this.entityId);

		return userEntityRef
			.set(
				{
					permissions: permissions,
				},
				{ merge: true }
			)
			.then(() => {
				if (permissions.length === 0) {
					const managementUserRef = this.afs.doc(`entities/${this.entityId}/management/users/list/${this.user.uid}`);
					managementUserRef.delete();
				}
			});
	}

	buildform() {
		this.addExistingUserForm = this.fb.group({
			existingUserID: ['', [Validators.required]],
			existingUserType: [''],
			permission: [''],
		});

		if (environment.product !== 'whitfields') {
			this.addExistingUserForm.get('existingUserType').setValidators(Validators.required);
		}

		this.addProfileForm = this.fb.group({
			addProfileType: ['', [Validators.required]],
			addProfileTypeOther: [''],
		});

		this.addProfileForm.get('addProfileType').valueChanges.subscribe(val => {
			if (val === 'Other') {
				this.addProfileOtherSelected = true;
			} else {
				this.addProfileOtherSelected = false;
			}
		});
	}

	showRemoveProfilesSection(user?: ManagementUser) {
		this.showRemoveProfileSection = true;
		this.user = user;
	}

	removeProfile(user: ManagementUser, i) {
		this.userDoc = this.afs.doc(`entities/${this.entityId}/management/users/list/${user.uid}`);

		user.profiles.splice(i, 1);

		const profiles = {
			profiles: user.profiles,
		};

		this.userDoc.update(profiles);

		if (user.profiles.length == 0) {
			this.showRemoveProfileSection = false;
		}

		this.updateUserPermissions();
	}

	setProfile(user: ManagementUser, profile: string, value: boolean) {
		const data = {};
		this.user = user;

		data[profile] = value;
		this.user[profile] = value;
		const userDetails = data;

		this.userDoc = this.afs.doc(`entities/${this.entityId}/management/users/list/${user.uid}`);
		this.userDoc.update(userDetails).then(() => {
			if (environment.product === 'whitfields') {
				if (profile === 'isSchemeManager' && value === false) {
					this.entitiesService.sendManagerRemovedNotification(this.entityId, this.user);
				}
			}
			this.updateUserPermissions();
		});
	}

	addProfile(form) {
		this.userDoc = this.afs.doc(`entities/${this.entityId}/management/users/list/${this.user.uid}`);
		let profiles = [];
		this.addingSpinner = true;
		this.showAddProfileSection = false;

		// CHECK IF USER HAS PREVIOUS PROFILES
		this.userDoc
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(snap => {
				const managementUserData = snap.payload.data();
				// IF USER HAS PREVIOUS PROFILES LOOP THROUGH THEM AND PREVENT DUPLICATE PROFILE BEING ADDED
				if (form.addProfileType !== 'Scheme Manager') {
					if (form.addProfileType !== 'Other') {
						profiles.push(form.addProfileType);
						if (managementUserData.profiles) {
							managementUserData.profiles.forEach(profile => {
								if (form.addProfileType !== profile) {
									profiles.push(profile);
								}
							});
						}
					} else {
						profiles.push(form.addProfileTypeOther);
						if (managementUserData.profiles) {
							managementUserData.profiles.forEach(profile => {
								if (form.addProfileType !== profile) {
									profiles.push(profile);
								}
							});
						}
					}
					this.user.profiles = profiles;
				} else {
					this.user.isSchemeExecutive = true;
				}
				this.userDoc.update(this.user).then(results => {
					this.addingSpinner = false;
					this.addProfileForm.reset();
					this.updateUserPermissions();
				});
			})
			.catch(error => {
				console.log('Could not update no user found', error);
			});
	}

	emailManager(email) {
		let sendData = [{ email: email }];
		this.communicationsService.createEmailDraft(sendData, true, false, '');
	}

	emailManagment() {
		let sendData = [];
		this.communicationsService.createEmailDraft(sendData, false, true, '');
	}

	smsManager(cell) {
		this.generatingSms = true;
		let sendData = [{ cell: cell }];
		this.communicationsService.createSMSDraft(sendData, true, false, '');
	}

	smsManagment() {
		this.generatingSms = true;
		let sendData = [];
		this.communicationsService.createSMSDraft(sendData, false, true, '');
	}

	showAddProfilesSection(user?: ManagementUser) {
		this.showAddProfileSection = true;
		this.user = user;
	}

	hideRemoveProfileSection() {
		this.showRemoveProfileSection = false;
	}

	hideAddProfileSection() {
		this.showAddProfileSection = false;
	}

	ngOnDestroy() {
		this.fetchManagerUsersSubscription.unsubscribe();
		this.userSubscription.unsubscribe();
		// this.managersListSubscription.unsubscribe();
	}
}
