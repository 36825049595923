import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from '@auth/_services';
import { Moment } from 'moment';
import lodash from 'lodash';

export interface FieldChange {
	name: string;
	before: string;
	after: string;
}

export class Activity {
	constructor() {
		this.created = Date.now();
		this.type = 'system';
		if (this.changed_fields) {
			this.changed_fields_length = Object.keys(this.changed_fields).length;
		}
	}

	public message: string;
	/* FIXME: */
	public created: number | string | Moment;
	public createdBy: string;
	public createdByName: string;
	public type: 'system' | 'update' | 'add' | 'remove' | 'user' | 'file';
	public id: string;
	public fileName?: string;
	/* FIXME: */
	public changed_fields?: FieldChange[]; // Object of changes (depends on generic)
	public changed_fields_length?: number;
	public toObject? = () => Object.assign({}, this);
}

@Injectable({
	providedIn: 'root',
})
export class PanelActivitiesService {
	constructor(private afs: AngularFirestore, private auth: AuthenticationService) {}

	public activities$(path: string) {
		return this.afs
			.collection<Activity>(path, ref => ref.orderBy('created', 'desc'))
			.valueChanges({ idField: 'id' });
	}

	public newActivity(message: string, type: Activity['type'], changed_fields?: FieldChange[]): Activity {
		const id = this.afs.createId();
		const { uid, firstname, surname } = this.auth.userDetails;
		const activity: Activity = {
			message,
			type,
			created: Date.now(),
			id,
			createdBy: uid,
			createdByName: `${firstname} ${surname}`,
		};

		if (changed_fields) {
			activity.changed_fields = changed_fields;
			activity.changed_fields_length = changed_fields.length;
		}

		return activity;
	}

	public addComment(path: string, message: string): Promise<void> {
		const activity = this.newActivity(message, 'user');
		return this.afs.doc<Activity>(path + activity.id).set(activity);
	}

	public detectObjectChanges<Object>(before: Object, after: Object, ignore?: string[]): { added?: FieldChange[]; removed?: FieldChange[]; changed?: FieldChange[] } {
		if (!ignore) ignore = ['created', 'createdBy', 'createdByName', 'watchers', 'product', 'eventTeam', 'updated', 'venueId'];

		let added = [];
		let removed = [];
		let changed = [];

		const beforeEntries = Object.entries(before);
		const afterEntries = Object.entries(after);

		beforeEntries.forEach(([key, value]) => {
			if (before[key] && !after[key]) {
				removed.push({ name: key, before: value, after: after[key] });
			}
		});

		afterEntries.forEach(([key, value]) => {
			const existsBefore = !!before[key];
			if (existsBefore) {
				if (before[key] !== value && value) {
					if (before[key] instanceof Array && after[key] instanceof Array) {
						const isNewArray = after[key].map((value: any) => before[key].includes(value)).some(keymatches => !keymatches);
						if (isNewArray) {
							changed.push({ name: key, after: value, before: before[key] });
						}
					} else {
						changed.push({ name: key, after: value, before: before[key] });
					}
				}
			}

			if (!existsBefore && after[key]) {
				added.push({ name: key, after: value, before: before[key] });
			}
		});

		added = added.filter(({ name }) => !ignore.includes(name));
		removed = removed.filter(({ name }) => !ignore.includes(name));
		changed = changed.filter(({ name }) => !ignore.includes(name));

		const changes = { added, removed, changed };

		if (!added.length) {
			delete changes.added;
		}
		if (!removed.length) {
			delete changes.removed;
		}
		if (!changed.length) {
			delete changes.changed;
		}

		return changes;
	}

	public addEvent(path: string, changed_fields: FieldChange[], type: Activity['type'], msg: string): Promise<void> {
		const activity = this.newActivity(msg, type, changed_fields);
		return this.afs.doc<Activity>(path + activity.id).set(activity);
	}

	public addUpdateEvent(path: string, changed_fields: FieldChange[]): Promise<void> {
		const message = `Updated: ${changed_fields.map(({ name }) => lodash.startCase(name).toLowerCase()).join(', ')}`;
		return this.addEvent(path, changed_fields, 'update', message);
	}

	public addRemoveEvent(path: string, changed_fields: FieldChange[]): Promise<void> {
		const message = `Removed: ${changed_fields.map(({ name }) => lodash.startCase(name).toLowerCase()).join(', ')}`;
		return this.addEvent(path, changed_fields, 'remove', message);
	}

	public addAddEvent(path: string, changed_fields: FieldChange[]): Promise<void> {
		const message = `Added: ${changed_fields.map(({ name }) => lodash.startCase(name).toLowerCase()).join(', ')}`;
		return this.addEvent(path, changed_fields, 'add', message);
	}

	public addSystemEvent(path: string, message: string): Promise<void> {
		return this.addEvent(path, [], 'system', message);
	}
}
