import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import * as firebase from 'firebase';
import { FirebaseApp } from '@angular/fire';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../../../../auth/_services/authentication.service';
import { Article } from '../models/article.model';
import { finalize, map } from 'rxjs/operators';
import { GenerateThumbnailService } from '../../_services/generate-thumbnail.service';
import { User } from '../../users/user.model';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';

declare let moment: any;

@Injectable()
export class NewsArticlesService {
	entityName: any;

	loggedInUser: string;

	task: AngularFireUploadTask;
	progressBarValue;
	entityId: any;

	constructor(
		private afStore: AngularFirestore,
		private auth: AuthenticationService,
		public router: Router,
		public fb: FirebaseApp,
		private storage: AngularFireStorage,
		public generateThumbnailService: GenerateThumbnailService,
		private store: Store
	) {
		// GET LOGGED IN USERS
		this.loggedInUser = sessionStorage.getItem('userId');
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	fetchArticles() {
		const newsArticlesRef = this.afStore.collection(`entities/${this.entityId}/news_articles/articles/list`, ref =>
			ref.where('active', '==', true).orderBy('modified', 'desc')
		);

		return newsArticlesRef.snapshotChanges().pipe(
			map(changes => {
				return changes.map((a: any) => {
					const data = a.payload.doc.data();
					data.id = a.payload.doc.id;
					data.created = moment(data.created.toDate()).format('YYYY-MM-DD hh:mm');
					data.modified = moment(data.modified.toDate()).format('YYYY-MM-DD hh:mm');
					this.fetchUserDetails(data.modifiedBy).then(user => {
						data.modifiedByUser = user;
					});
					return data;
				});
			})
		);
	}

	fetchArticle(articleId: string) {
		const newsArticlesRef = this.afStore.doc(`entities/${this.entityId}/news_articles/articles/list/${articleId}`);

		return newsArticlesRef.valueChanges();
	}

	addArticle(article: Article) {
		const newsArticlesRef = this.afStore.collection(`entities/${this.entityId}/news_articles/articles/list`);

		const { title, post, imgName, categories, tags, published, homePage, blogPage } = article;

		const data = {
			active: true,
			title,
			post,
			imgName,
			categories,
			tags,
			published,
			createdBy: this.loggedInUser,
			created: new Date(),
			modifiedBy: this.loggedInUser,
			modified: new Date(),
			slug: title.replace(/[^A-Z0-9]+/gi, '-'),
			homePage,
			blogPage,
		};

		return newsArticlesRef.add(data).then(ref => {
			if (article.imgBase64) {
				this.uploadArticleImage(article.imgBase64, article.imgName, ref.id);
			}
			return ref;
		});
	}

	saveArticle(article: Article, articleId: string, updatedImg: boolean) {
		const newsArticlesRef = this.afStore.doc(`entities/${this.entityId}/news_articles/articles/list/${articleId}`);

		const { title, post, imgName, categories, tags, published, created, homePage, blogPage } = article;

		const data = {
			active: true,
			title,
			post,
			imgName,
			categories,
			tags,
			published,
			createdBy: this.loggedInUser,
			created,
			modifiedBy: this.loggedInUser,
			modified: new Date(),
			slug: title.replace(/[^A-Z0-9]+/gi, '-'),
			homePage,
			blogPage,
		};

		return newsArticlesRef.update(data).then(ref => {
			if (updatedImg) {
				return this.uploadArticleImage(article.imgBase64, article.imgName, articleId);
			}
		});
	}

	// Entity template uploads

	uploadArticleImage(imgBase64, imgName, refId) {
		const fileName = `${new Date().getTime()}_${imgName}`;
		const path = `entities/${this.entityId}/newsarticles/${fileName}.png`;
		const ref = this.storage.ref(path);
		const task = ref.putString(imgBase64, 'data_url');

		return new Promise((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							this.generateThumbnailService.generateFromImage(imgBase64, 350, 350, 1, thumbnailData => {
								const file = {
									img: url,
									imgThumbnail: thumbnailData,
								};
								this.saveDataToFirestore(file, refId)
									.then(() => {
										return resolve('');
									})
									.catch(err => {
										return reject('Failed to save data to firestore: ' + err);
									});
							});
						});
					})
				)
				.subscribe();
		});
	}

	private saveDataToFirestore(downloadData, refId) {
		const entityRef = this.afStore.doc(`entities/${this.entityId}/news_articles/articles/list/${refId}`);
		return entityRef.update(downloadData);
	}

	updateArticle(data: any, articleId: string) {
		const newsArticlesRef = this.afStore.doc(`entities/${this.entityId}/news_articles/articles/list/${articleId}`);

		return newsArticlesRef.update(data);
	}

	removeArticle(articleId: string) {
		const newsArticlesRef = this.afStore.doc(`entities/${this.entityId}/news_articles/articles/list/${articleId}`);

		return newsArticlesRef.set(
			{
				active: false,
			},
			{ merge: true }
		);
	}

	filterArticles(filter: string, type: string) {
		const newsArticlesRef = this.afStore.collection(`entities/${this.entityId}/news_articles/articles/list`, ref => ref.where(type, '==', filter));

		return newsArticlesRef.valueChanges({ idField: 'id' });
	}

	fetchUserDetails(userId: string) {
		const userRef = this.afStore.doc(`users/${userId}`).ref;

		return userRef.get().then(user => {
			const userData = user.data() as User;
			return `${userData.firstname} ${userData.surname}`;
		});
	}

	/// CATEGORIES

	addCategory(category: string) {
		const newsArticlesRef = this.afStore.collection(`entities/${this.entityId}/news_articles/categories/list`);

		const data = {
			active: true,
			name: category,
			createdBy: this.loggedInUser,
			created: new Date(),
			modifiedBy: this.loggedInUser,
			modified: new Date(),
		};

		return newsArticlesRef.add(data);
	}

	fetchCategories() {
		const newsCategoriesRef = this.afStore.collection(`entities/${this.entityId}/news_articles/categories/list`, ref =>
			ref.where('active', '==', true).orderBy('modified', 'desc')
		);

		return newsCategoriesRef.valueChanges({ idField: 'id' });
	}
}
