<span *ngIf="!isSubmitting; else creatingTask">
	<span *ngIf="!showSuccess; else showOptions">
		<div class="modal-header m--bg-brand">
			<h5 class="modal-title text-white" id="exampleModalLongTitle">Create Task - (Quick Action)</h5>
		</div>
		<form [formGroup]="createTaskForm" (ngSubmit)="createTask(createTaskForm.value)">
			<div class="modal-body">
				<div class="row">
					<div class="col-xl-7">
						<div class="form-group m-form__group">
							<label for="description">
								Task Subject
								<span class="help-block m--font-danger">*</span>
							</label>
							<input class="form-control m-input" placeholder="Enter task subject" type="text" formControlName="subject" />
							<span *ngIf="!createTaskForm.get('subject').valid && createTaskForm.get('subject').touched" class="m-form__help help-block"
								>Please enter a task subject</span
							>
						</div>

						<div class="form-group m-form__group">
							<label for="description">
								Full Description
								<span class="help-block m--font-danger">*</span>
							</label>
							<textarea
								rows="6"
								cols="10"
								formControlName="description"
								placeholder="Full task description"
								title="Full Task Description"
								class="form-control"
							></textarea>
							<span *ngIf="!createTaskForm.get('description').valid && createTaskForm.get('description').touched" class="m-form__help help-block"
								>Please enter a task description</span
							>
						</div>
					</div>
					<div class="col-xl-5">
						<div class="form-group m-form__group date">
							<label for="due_date">
								Due Date
								<span class="help-block m--font-danger">*</span>
							</label>
							<input type="text" class="form-control m-input" id="m_task_datepicker_1" readonly placeholder="Select date" formControlName="dueDate" />
							<span *ngIf="!createTaskForm.get('dueDate').valid && createTaskForm.get('dueDate').touched" class="m-form__help help-block"
								>Please select a due date to continue</span
							>
						</div>

						<div class="form-group m-form__group">
							<label for="description">
								Category
								<span class="help-block m--font-danger">*</span>
							</label>
							<select class="form-control m-input" formControlName="category" placeholder="Select Category">
								<option value="" disabled>Select Category</option>
								<option value="general">General</option>
								<option value="communication">Communication</option>
								<option value="finance">Finance</option>
								<option value="gardens">Gardens</option>
								<option value="maintenance">Maintenance</option>
								<option value="meetings">Meetings</option>
								<option value="security">Security</option>
								<option value="other">Other</option>
							</select>
							<span *ngIf="!createTaskForm.get('category').valid && createTaskForm.get('category').touched" class="m-form__help help-block"
								>Please select a task category</span
							>
						</div>

						<div class="form-group m-form__group">
							<label for="description">
								Assigned to
								<span class="help-block m--font-danger">*</span>
							</label>
							<div class="input-group">
								<select
									(change)="getNewAssignedUser()"
									class="form-control m-input"
									id="assignedToId"
									name="assignedToId"
									ngModel
									required
									formControlName="assignedToId"
									[(ngModel)]="assignedToId"
								>
									<option *ngFor="let teamMember of managementTeamList" value="{{ teamMember.id }}">{{ teamMember.firstname }} {{ teamMember.surname }}</option>
								</select>
								<div class="input-group-append">
									<button class="btn btn-outline-brand" type="button" (click)="setAssignedToCurrentUser()">Me</button>
								</div>
								<span *ngIf="!createTaskForm.get('assignedToId').valid && createTaskForm.get('assignedToId').touched" class="m-form__help help-block"
									>Please assign the task to someone</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="row align-items-center">
					<div class="col-lg-6 m--valign-middle">
						<button type="submit" value="Submit" class="btn btn-brand m-btn m-btn--custom" [disabled]="!createTaskForm.valid">Create</button>
					</div>
					<div class="col-lg-6 m--align-right">
						<button type="button" class="btn btn-secondary m-btn m-btn--custom" (click)="closeTaskCreate()">Cancel</button>
					</div>
				</div>
			</div>
		</form>
	</span>
	<ng-template #showOptions>
		<div class="modal-header m--bg-brand">
			<h5 class="modal-title text-white" id="exampleModalLongTitle">Task Created - (Quick Action)</h5>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-xl-12">
					<h5>
						A task has been created, reference number: <strong>{{ taskInfo?.refNo }}</strong
						>.
					</h5>
					<br />
					<strong>Task details:</strong>
					<br />
					<em>{{ taskInfo?.subject }}</em
					>, assigned to <em>{{ taskInfo?.assignedToName }}</em
					>,
					<br />
					with the category of <em>{{ taskInfo?.category | titlecase }}</em
					>, the priorty is <em>3 (medium)</em>
					<br />
					and is due to be completed by <em>{{ taskInfo?.dueDate }}</em
					>.
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<div class="row align-items-center">
				<div class="col-lg-6 m--valign-middle">
					<button type="button" class="btn btn-brand m-btn m-btn--custom" (click)="closeTaskCreate()">Done</button>
				</div>
				<div class="col-lg-6 m--align-right">
					<button type="button" class="btn btn-secondary m-btn m-btn--custom" (click)="viewTask(taskId)">View Task</button>
				</div>
			</div>
		</div>
	</ng-template>
</span>
<ng-template #creatingTask>
	<h4 class="text-center" style="padding: 60px">
		<span class="m-loader m-loader--brand"></span>
	</h4>
</ng-template>
