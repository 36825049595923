<div class="m-portlet m-portlet--mobile m-portlet--head-sm" style="min-height: 290px">
	<div class="m-portlet__head">
		<div class="m-portlet__head-caption">
			<div class="m-portlet__head-title">
				<h3 class="m-portlet__head-text">Current Balances</h3>
			</div>
		</div>
		<div class="m-portlet__head-tools">
			<ul class="m-portlet__nav">
				<li
					class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
					m-dropdown-toggle="hover"
					aria-expanded="true"
				>
					<a href="#" class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill m-dropdown__toggle">
						<i class="la la-ellipsis-v"></i>
					</a>
					<div class="m-dropdown__wrapper" style="z-index: 101">
						<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" style="left: auto; right: 21.5px"></span>
						<div class="m-dropdown__inner">
							<div class="m-dropdown__body">
								<div class="m-dropdown__content">
									<ul class="m-nav">
										<ul class="m-nav">
											<li class="m-nav__item">
												<a href="#" class="m-nav__link" (click)="isEditOverdraft = !isEditOverdraft">
													<i class="m-nav__link-icon la la-edit"></i>
													<span class="m-nav__link-text">Edit Overdraft</span>
												</a>
											</li>
										</ul>
										<li class="m-nav__item">
											<a href="#" class="m-nav__link" (click)="isEditBuffer = !isEditBuffer">
												<i class="m-nav__link-icon la la-edit"></i>
												<span class="m-nav__link-text">Edit Buffer</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="m-portlet__body">
		<ng-template #loadingBalances>
			<div class="text-center pt-3">
				<label
					>Loading Balances
					<div class="m-loader m-loader--brand" style="width: 30px; display: inline-block"></div
				></label>
			</div>
		</ng-template>

		<div class="m--font-bolder" *ngIf="!isLoadingBalances; else loadingBalances">
			<div *ngIf="currentData">
				<div *ngFor="let account of currentData">
					{{ entity?.whitfieldsPrefix }}/{{ account?.account }} :
					<span
						[ngClass]="{
							positive: !account?.total.includes('-'),
							negative: account?.total.includes('-')
						}"
					>
						{{ account?.total }}
					</span>
					<br />
				</div>
				<hr />
			</div>
			Available Payment Balance :
			<span
				[ngClass]="{
					positive: !available?.total.includes('-'),
					negative: available?.total.includes('-')
				}"
				>{{ available?.total }}
			</span>
			<hr />

			<span *ngIf="!isEditOverdraft">Overdraft : {{ available?.overdraft }}</span>
			<div class="form-group m-form__group" *ngIf="isEditOverdraft">
				<label>Edit Overdraft</label> {{ entity?.overdraft }}
				<div class="input-group">
					<input
						[disabled]="loadingOverDraft"
						type="text"
						class="form-control form-control-sm"
						name="bufferAmount"
						#overdraft="ngModel"
						[(ngModel)]="entity.overdraft"
						ngModel
						placeholder="Enter buffer amount."
					/>
					<div class="input-group-append">
						<button [disabled]="loadingOverDraft" class="btn btn-secondary btn-sm" type="button" (click)="isEditOverdraft = !isEditOverdraft">X</button>
					</div>
					<div class="input-group-append">
						<button [disabled]="loadingOverDraft" class="btn btn-outline-brand btn-sm" type="button" (click)="updateOverdraft(overdraft.value)">Save</button>
					</div>
				</div>
			</div>

			<hr />
			<span *ngIf="!isEditBuffer">Buffer : {{ entity?.bufferOverride }}</span
			><br />
			<span *ngIf="!isEditBuffer">Buffer Amount : {{ entity?.buffer }}</span>
			<div class="form-group m-form__group" *ngIf="isEditBuffer">
				<label>Edit Buffer</label> {{ entity?.buffer }}
				<div class="input-group">
					<input
						[disabled]="loadingBuffer"
						type="text"
						class="form-control form-control-sm"
						name="buffer"
						#buffer="ngModel"
						[(ngModel)]="entity.buffer"
						ngModel
						placeholder="Enter buffer amount."
					/>
					<div class="input-group-append">
						<button [disabled]="loadingBuffer" class="btn btn-secondary btn-sm" type="button" (click)="isEditBuffer = !isEditBuffer">X</button>
					</div>
					<div class="input-group-append">
						<button [disabled]="loadingBuffer" class="btn btn-outline-brand btn-sm" type="button" (click)="updateBuffer(buffer.value)">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
