import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { environment } from './../../../environments/environment';
import { User } from '../../theme/pages/default/plex/users/user.model';
import { switchMap, take, map } from 'rxjs/operators';
import { SchemesService } from './../../theme/pages/default/management/entities/entities.service';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthenticationService {
	entityId;
	user: Observable<User>;
	userDetails: User;
	public userId: string;
	public userName: string;
	emailVerified: boolean = false;
	isUserLoaded = new BehaviorSubject(false);
	userPerms = new Subject<any>();

	constructor(
		private afAuth: AngularFireAuth,
		private afs: AngularFirestore,
		private router: Router,
		private permissionsService: NgxPermissionsService,
		private ngxRolesService: NgxRolesService,
		private store: Store
	) {
		this.store.select(selectEntityId).subscribe(entityId => {
			this.entityId = entityId;
		});

		this.user = this.afAuth.authState.pipe(
			switchMap(user => {
				if (user) {
					let updateUser = false;
					if (!this.userDetails) {
						updateUser = true;
					} else if (this.userDetails.email !== user.email) {
						updateUser = true;
					}
					if (updateUser) {
						const userDoc = this.afs.collection('userRefs').doc<User>(user.uid).ref;
						userDoc.get().then(doc => {
							if (doc.exists) {
								this.userDetails = doc.data();
								this.userId = this.userDetails.uid;
								//const this.userDetails.isSuperAdmin = this.fetchSuperAdmin();
								sessionStorage.setItem('userId', this.userId);
								sessionStorage.setItem('userFirstname', this.userDetails.firstname);
								sessionStorage.setItem('userSurname', this.userDetails.surname);
								this.isUserLoaded.subscribe(loaded => {
									if (!loaded) {
										// this.permissionsManager(),
										if (environment.product !== 'whitfields') {
											this.permissionsManager().then(loaded => {
												this.userPerms.next(loaded);
												this.isUserLoaded.next(true);
											});
										} else {
											this.loadUserRoles().then(loaded => {
												this.userPerms.next(loaded);
												this.isUserLoaded.next(true);
											});
										}
									}
								});
							}
						});
					}
					return this.afs.doc<User>(`userRefs/${user.uid}`).valueChanges();
				} else {
					this.isUserLoaded.next(true);
					return of(null);
				}
			})
		);
	}

	fetchSuperAdmin() {
		return this.afs.doc(`entities/whitfields/admins/${this.userId}`).ref.get();
	}

	managePermissionsAndUserState() {
		return new Observable(observer => {
			this.user.subscribe(user => {
				this.userPerms.subscribe(perms => {
					if (!!user && perms) {
						observer.next(true);
					} else {
						observer.next(false);
					}
					observer.complete();
				});
			});
		});
	}

	permissionsManager() {
		return new Promise((res, rej) => {
			let entityID = '';
			const permissionsList = localStorage.getItem('permissions');
			if (environment['product'] !== 'olympus') {
				if (environment['product'] === 'whitfields') {
					entityID = 'whitfields';
				} else {
					entityID = this.entityId;
				}
				if (permissionsList) {
					this.permissionsService.loadPermissions(JSON.parse(permissionsList));
				}
				this.store
					.select(selectEntityId)
					.pipe(take(1))
					.toPromise()
					.then(entityId => {
						if (entityId && environment['product'] !== 'whitfields') {
							this.afs
								.doc(`/users/${this.userId}/entities/${entityID}`)
								.valueChanges()
								.pipe(take(1))
								.subscribe(userEntityDetails => {
									if (userEntityDetails) {
										if (userEntityDetails['permissions']) {
											localStorage.setItem('permissions', JSON.stringify(userEntityDetails['permissions']));
											this.permissionsService.loadPermissions(userEntityDetails['permissions']);
											this.permissionsService.permissions$.pipe(take(1)).subscribe(perms => {
												res(true);
											});
										} else {
											this.router.navigate(['/profile']);
											res(true);
										}
									}
								});
						} else {
							this.afs
								.doc(`/users/${this.userId}/entities/whitfields`)
								.valueChanges()
								.pipe(take(1))
								.subscribe(userEntityDetails => {
									if (userEntityDetails) {
										if (userEntityDetails['permissions']) {
											localStorage.setItem('permissions', JSON.stringify(userEntityDetails['permissions']));
											this.permissionsService.loadPermissions(userEntityDetails['permissions']);
											this.permissionsService.permissions$.pipe(take(1)).subscribe(perms => {
												res(true);
											});
										} else {
											this.router.navigate(['/profile']);
											res(true);
										}
									}
								});
						}
					});
			} else {
				this.afs
					.doc(`/users/${this.userId}`)
					.valueChanges()
					.pipe(take(1))
					.subscribe(userEntityDetails => {
						if (userEntityDetails['permissions']) {
							this.permissionsService.loadPermissions(userEntityDetails['permissions']);
							res(true);
						} else {
							this.router.navigate(['/profile']);
							res(true);
						}
					});
			}
		});
	}

	loadUserRoles() {
		return new Promise(res => {
			let entityID = '';
			const rolesList = localStorage.getItem('roles');
			const permissionsList = localStorage.getItem('permissions');
			if (environment['product'] !== 'olympus') {
				if (environment['product'] === 'whitfields') {
					entityID = 'whitfields';
				} else {
					entityID = this.entityId;
				}
				if (rolesList) {
					this.ngxRolesService.addRolesWithPermissions(JSON.parse(rolesList));
					//this.permissionsService.loadPermissions(JSON.parse(permissionsList));
				}
				this.afs
					.doc(`/users/${this.userId}/entities/${entityID}`)
					.valueChanges()
					.pipe(take(1))
					.subscribe((userEntityDetails: any) => {
						if (userEntityDetails) {
							if (userEntityDetails.hasOwnProperty('groups')) {
								const userRoles = userEntityDetails['groups'][0].roles;
								const tmpRoles = {};
								const tmpRolePermissions = [];
								tmpRoles[userEntityDetails['groups'][0].value] = [userEntityDetails['groups'][0].value];
								userEntityDetails.permissions.forEach(permission => {
									const key = permission.toUpperCase();
									tmpRoles[key] = [permission];
								});
								userRoles.forEach(role => {
									if (role.permissions) {
										tmpRoles[role.value.toUpperCase()] = [];
										for (let key in role.permissions[0]) {
											if (role.permissions[0][key]) {
												tmpRoles[role.value.toUpperCase()].push(key);
												tmpRolePermissions.push(key);
											}
										}
									} else {
										role.subRoles.forEach(subRole => {
											tmpRoles[subRole.value.toUpperCase()] = [];
											for (let key in subRole.permissions[0]) {
												if (subRole.permissions[0][key]) {
													tmpRoles[subRole.value.toUpperCase()].push(key);
													tmpRolePermissions.push(key);
												}
											}
										});
									}
								});
								localStorage.setItem('roles', JSON.stringify(tmpRoles));
								localStorage.setItem('permissions', JSON.stringify(tmpRolePermissions));
								this.ngxRolesService.addRolesWithPermissions(tmpRoles);
								// this.permissionsService.loadPermissions(tmpRolePermissions)
								res(true);
							} else {
								const tmpRoles = {};
								userEntityDetails.permissions.forEach(permission => {
									const key = permission.toUpperCase();
									tmpRoles[key] = [permission];
									localStorage.setItem('roles', JSON.stringify(tmpRoles));
									this.ngxRolesService.addRolesWithPermissions(tmpRoles);
								});
								res(true);
							}
						} else {
							this.router.navigate(['/profile']);
							res(true);
						}
					});
			} else {
				res(true);
			}
		});
	}

	googleLogin() {
		const provider = new firebase.auth.GoogleAuthProvider();
		return this.oAuthLogin(provider);
	}

	private oAuthLogin(provider) {
		return this.afAuth.signInWithPopup(provider).then((credential: any) => {
			// SET USER FIRSTNAME AND SURNAME
			credential.user.firstname = credential.additionalUserInfo.profile.given_name;
			credential.user.surname = credential.additionalUserInfo.profile.family_name;

			const userData = {
				firstname: credential.user.firstname,
				surname: credential.user.surname,
				email: credential.user.email.toLowerCase(),
				uid: credential.user.uid,
				photoURL: credential.user.photoURL,
			};

			const userRef = this.afs.doc(`/public/registeredUsers/list/${credential.user.uid}`);

			return userRef
				.snapshotChanges()
				.pipe(take(1))
				.toPromise()
				.then(snap => {
					if (!snap.payload.data()) {
						// IF USER DOES NOT EXIST CREATE USER WITH GOOGLE DETAILS
						return this.createUserData(userData, true);
					}
				})
				.catch(error => {
					console.log('User does not exist', error);
					// IF USER DOES NOT EXIST CREATE USER WITH GOOGLE DETAILS
					return this.createUserData(userData, true);
				});
		});
	}

	emailSignUp(email, password, firstname, surname) {
		return this.afAuth.createUserWithEmailAndPassword(email, password).then((user: any) => {
			const userData = {
				firstname: firstname,
				surname: surname,
				email: email.toLowerCase(),
				uid: user.user.uid,
				photoURL: user.user.photoURL,
			};
			return this.createUserData(userData, false);
		});
	}

	emailLogin(email, password) {
		return this.afAuth.signInWithEmailAndPassword(email, password);
	}

	resetPassword(email) {
		return this.afAuth
			.sendPasswordResetEmail(email.toLowerCase())
			.then(() => {
				// Password reset email sent!
				console.log('Reset Sent to', email);
			})
			.catch(error => {
				const errorCode = error.code;
				const errorMessage = error.message;
				//Error handling for Live to test a specific issue happening with shoki@whitfields.co.za
				console.log('🚀 ~ AuthenticationService ~ errorCode', errorCode);
				console.log('🚀 ~ AuthenticationService ~ errorMessage', errorMessage);
			});
		// return this.afAuth.sendPasswordResetEmail(email.toLowerCase());
	}

	sendEmailVerificationRequest(user) {
		return this.afs.collection(`pending`).doc(user.firebaseId).set({
			request: 'emailVerificationRequests',
			email: user.email.toLowerCase(),
			firstname: user.firstname,
			uid: user.firebaseId,
			entityId: null,
			environmentAdmin: environment.admin,
			environmentClient: environment.client,
			source: 'admin',
			product: environment.product,
			created: new Date(),
		});
	}

	sendEmailWelcomeMail(firebaseId) {
		return this.afs.collection(`pending`).add({
			request: 'emailWelcomeMail',
			firebaseId: firebaseId,
			environmentAdmin: environment.admin,
			product: environment.product,
			created: new Date(),
		});
	}

	verifyUserEmail(firebaseId: string) {
		// CHECK USER UID EXISTS
		const userRef = this.afs.collection('public/registeredUsers/list').doc(firebaseId).ref;

		return userRef.get().then(snap => {
			if (snap.exists === true) {
				return this.afs
					.collection(`pending`)
					.doc(firebaseId)
					.set(
						{
							request: 'verifyUser',
							verified: true,
						},
						{ merge: true }
					)
					.then(() => {
						this.sendEmailWelcomeMail(firebaseId);
						return Promise.resolve('User Verified');
					});
			} else {
				return Promise.reject('User could not be verified');
			}
		});
	}

	veryifyNotFoundSoCheckFields(firebaseId) {
		// THE FIREBASE ID WAS NOT FOUND WHEN VERIFYING SO WE ARE NOW GOING TO DO ANOTHER CHECK TO SEE IF ANY
		// OF THE DOCUMENTS WITHIN THE USERREFS COLLECTION IF THE USER UID MATCHES AND IF IT MATHCES, RUN verifyUserEmail()
		// AGAIN WITH THE NEW ID
		const userRefRefs = this.afs.collection('public/registeredUsers/list').ref;

		return userRefRefs
			.where('uid', '==', firebaseId)
			.get()
			.then(snapshot => {
				if (!snapshot.empty) {
					snapshot.forEach(doc => {
						return this.verifyUserEmail(doc.id);
					});
				}
			})
			.catch(err => {
				console.log('Error getting documents', err);
			});
	}

	private createUserData(user: User, socialLogin: boolean) {
		const pendingCreateUserDataRef = this.afs.doc(`pending/${user.uid}`);

		let entityId = 'amiti';
		if (this.entityId) {
			entityId = this.entityId;
		}

		return pendingCreateUserDataRef.set({
			request: 'createUserData',
			user: user,
			socialLogin: socialLogin,
			entityId,
			environmentAdmin: environment.admin,
			product: environment.product,
			environmentClient: environment.client,
			source: 'admin',
			generatedId: this.afs.createId(),
		});
	}

	private updateUserData(user) {
		const id = user.uid;
		const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${id}`);
		const userIDsRef: AngularFirestoreDocument<User> = this.afs.doc(`userRefs/${user.firebaseId}`);

		const userDetailsResult = userRef.set(
			{
				uid: id,
				firebaseId: user.firebaseId,
				email: user.email.toLowerCase(),
				firstname: user.firstname,
				surname: user.surname,
				photoURL: user.photoURL,
				socialLogin: true,
			},
			{ merge: true }
		);

		const userIDsResult = userIDsRef.set(
			{
				uid: id,
				firebaseId: user.firebaseId,
				email: user.email.toLowerCase(),
				firstname: user.firstname,
				surname: user.surname,
				photoURL: user.photoURL,
				socialLogin: true,
			},
			{ merge: true }
		);

		return Promise.all([userDetailsResult, userIDsResult]);
	}

	changePassword(email, currentPassword) {
		return this.reAuthUser(email, currentPassword)
			.then(user => {
				return user;
			})
			.catch(error => {
				return error;
			});
	}

	updateUserPassword(user, password) {
		return user
			.updatePassword(password)
			.then(() => {
				const success = {
					message: 'password successful',
					type: 'success',
				};
				return success;
			})
			.catch(error => {
				const err = {
					error,
					type: 'error',
				};
				return err;
			});
	}

	updateUserEmail(userEmail) {
		return this.afAuth.currentUser.then(user => user.updateEmail(userEmail));
	}

	reAuthUser(email, password) {
		const credential = firebase.auth.EmailAuthProvider.credential(email, password);
		return this.afAuth.currentUser.then(user => user.reauthenticateWithCredential(credential));
	}

	signOut() {
		this.afAuth.signOut().then(() => {
			// this.updateOnlineStatus(this.userId, false);
			window.location.reload();
			this.router.navigate(['/login']);
		});
	}

	checkPendingUsersInvites(user: User) {
		let collref = this.afs.collection('pendingUserInvites').ref;
		let queryref = collref.where('email', '==', user.email);
		return queryref.get().then(snapShot => {
			if (snapShot.empty) {
				return Promise.reject('No pending invites');
			} else {
				return Promise.resolve(
					snapShot.docs.map(documentSnapshot => {
						return documentSnapshot.data();
					})
				);
			}
		});
	}

	updateOnlineStatus(userId: string, status: boolean) {
		const userRef = this.afs.doc(`users/${userId}`);

		userRef.set(
			{
				online: status,
			},
			{ merge: true }
		);

		this.userId = userId;
	}

	signedInWithEmailLink(user) {
		//console.log("​signedInWithEmailLink -> user", user)
		const pendingUsersCollection = this.afs.collection(`pendingUsersCollection`, ref => ref.where('email', '==', user.email));
		return pendingUsersCollection
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as any;
						data.uid = a.payload.doc.id;
						return data;
					});
				})
			)
			.pipe(take(1))
			.toPromise()
			.then(userRefsData => {
				// console.log("TCL: AuthenticationService -> signedInWithEmailLink -> userRefsData", userRefsData)
				if (userRefsData.length > 0) {
					let tmpUser = userRefsData[0];
					console.log('FF - signedInWithEmailLink -> tmpUser', tmpUser);
					const userData = {
						uid: tmpUser.uid,
						firebaseId: user.uid,
						email: tmpUser.email,
						firstname: tmpUser.firstname,
						surname: tmpUser.surname,
						permissions: tmpUser.permissions,
						verified: true,
						active: true,
						socialLogin: false,
						ref: `/users/${tmpUser.uid}`,
						type: 'staff',
						created: Date.now(),
						product: 'whitfields',
					};
					const userRefs = this.afs.doc(`userRefs/${userData.firebaseId}`);
					// console.log('set user to userRefs collection');
					return userRefs.set(userData, { merge: true }).then(() => {
						const usersCollection = this.afs.doc(`users/${userData.uid}`);

						delete userData.verified;
						// console.log('set user to users collection');
						return usersCollection.set(userData, { merge: true }).then(() => {
							// console.log('add user to project entity');
							const projectEntityDocRef = this.afs.doc(`entities/${environment.product}/users/${userData.uid}`);
							return projectEntityDocRef.set(userData).then(() => {
								// console.log('add entity to user')
								return this.afs
									.doc(`entities/whitfields`)
									.ref.get()
									.then(doc => {
										const entityData = doc.data() as any;
										entityData.ref = 'entities/whitfields';
										//console.log('entity to add to user: ', entityData);
										// console.log(`add to users/${userData.uid}/entities/whitfields`);
										const entityUserData = {
											name: entityData.name,
											ref: 'entities/whitfields',
											uid: 'whitfields',
											entity_type: entityData.entity_type,
											domain: entityData.domain,
											active: true,
											email: tmpUser.email,
											firstname: tmpUser.firstname,
											surname: tmpUser.surname,
											permissions: tmpUser.permissions,
											product: 'whitfields',
										};
										return this.afs
											.doc(`users/${userData.uid}/entities/whitfields`)
											.set(entityUserData, { merge: true })
											.then(() => {
												// console.log('added user to project entity');
												const pendingUserDoc = this.afs.collection('pendingUsersCollection').doc(`${userData.uid}`);
												// console.log('remove pending');
												return pendingUserDoc.delete().then(() => {
													// console.log('all done');
												});
											});
									});
							});
						});
					});
				}
			});
	}

	setFirstPassword(password) {
		return this.afAuth.currentUser.then(user => user.updatePassword(password));
	}

	public async getIdToken() {
		return (await this.afAuth.currentUser).getIdToken();
	}
}
