<!-- INTRO TEXT MESSAGE -->
<div *ngIf="currentStep === componentStep; else hideStep" class="popped-intro intro-{{ area }} {{ customClass }} right ml-3" id="help-message-{{ componentStep }}">
	<div class="row justify-content-center pb-2">
		<span class="btn btn-sm btn-pill btn-outline btn-danger">{{ componentStep }}</span>
	</div>

	<div *ngIf="componentStep === 10">
		Add an existing user in your {{ scheme }} to your Management Team or invite new people to join by clicking on the plus sign
		<button class="btn m-btn--square btn-outline-success m-btn m-btn--icon m-btn--icon-only"><i class="la la-plus"></i></button><br />
		<br />
		You can also email and sms all management {{ stepThreeType | lowercase }} by using envelope
		<button class="btn m-btn--square btn-outline-warning m-btn m-btn--icon m-btn--icon-only"><i class="fa fa-envelope"></i></button> or phone
		<button class="btn m-btn--square btn-outline-accent m-btn m-btn--icon m-btn--icon-only"><i class="fa fa-mobile"></i></button> icons<br />
		<br />
		Remember to make members Admin by using the down arrow
		<button class="btn m-btn--square btn-outline-info m-btn m-btn--icon btn-sm m-btn--icon-only"><i class="fa fa-angle-down"></i></button> to the right of their names if you
		want them to be able to access this admin page
	</div>

	<div *ngIf="componentStep !== 10">
		<div *ngIf="iconSteps.includes(componentStep)">
			You can view all the {{ iconStepData[componentStep].type }} of your {{ scheme }} by clicking here
			<br />
			<br />
			Click on the
			<span *ngFor="let icon of stepIcons" class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill">
				<i class="la la-{{ icon }}"></i>
			</span>
			buttons to add new {{ iconStepData[componentStep].description }}
		</div>

		<div *ngIf="!iconSteps.includes(componentStep)">
			<div *ngFor="let line of stepData[componentStep]">
				<span>{{ line }}</span>
				<br />
				<br />
			</div>
		</div>
	</div>

	<div class="row px-3 pt-3 button-container">
		<button class="btn btn-sm btn-outline-danger" type="button" (click)="dismissHelpMessages()">Dismiss</button>

		<div class="float-right">
			<button *ngIf="currentStep !== 1" class="btn btn-sm btn-secondary btn-outline ml-2" type="button" (click)="goToNextStep(-1)">Previous</button>

			<button *ngIf="currentStep !== 10" class="btn btn-sm btn-secondary btn-outline ml-2" type="button" (click)="goToNextStep(1)">Next</button>

			<button *ngIf="currentStep === 10" class="btn btn-sm btn-secondary btn-outline ml-2" type="button" (click)="dismissHelpMessages()">Done</button>
		</div>
	</div>
</div>

<ng-template #hideStep></ng-template>
