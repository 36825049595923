<div [id]="generalLedgerModalId" class="modal-dialog modal-dialog-centered modal-lg" role="document">
	<div class="modal-content">
		<div class="modal-header m--bg-brand">
			<h5 class="modal-title text-white" id="exampleModalLabel">General Ledger Accounts</h5>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
		</div>
		<div class="modal-body" [id]="modalBodyId">
			<div class="text-center" [hidden]="entityLoaded">
				LOADING
				<br />
				<div class="m-loader m-loader--brand" style="width: 30px; display: inline-block"></div>
			</div>
			<form [formGroup]="generalLedgerForm" [hidden]="!entityLoaded">
				<div class="form-group m-form__group">
					<label>Select account</label>
					<input type="text" class="form-control" placeholder="Search..." formControlName="search" />
					<select multiple size="10" class="form-control" formControlName="account" id="account">
						<option (click)="clearAccountTypeFilters()" *ngFor="let account of filteredList" value="{{ account.accountLink }}_{{ account.account }}">
							{{ entity?.whitfieldsPrefix }}/{{ account.account }} ( {{ account.type }} - {{ account.description }} )
						</option>
					</select>
				</div>

				<div class="m-form__group form-group">
					<div class="m-checkbox-inline" formGroupName="items">
						<label class="m-checkbox" *ngFor="let accountType of accountTypes; let i = index">
							<input [formControlName]="accountType" (change)="filterAccounts(accountType)" type="checkbox" /><small>{{ accountType }}</small>
							<span></span>
						</label>
					</div>
				</div>

				<div class="row">
					<div *ngFor="let formControlName of datePickerControls" class="col">
						<div class="form-group m-form__group">
							<label>{{ formControlName | titlecase }}</label>
							<input [id]="formControlName" readonly type="text" placeholder="DD-MM-YYYY" class="form-control m_datepicker" [formControlName]="formControlName" />
							<span
								*ngIf="
									generalLedgerForm.get(formControlName).invalid &&
									generalLedgerForm.get(formControlName).dirty &&
									generalLedgerForm.get(formControlName).errors.afterTo
								"
								class="m-form__help text-danger"
							>
								The to date cannot be after the from date.
							</span>
						</div>
					</div>
				</div>

				<div class="m-form__group form-group">
					<label>Select file format</label>
					<div class="m-radio-inline">
						<label class="m-radio">
							<input type="radio" name="ftype" value="pdf" formControlName="ftype" /> PDF
							<span></span>
						</label>
						<label class="m-radio">
							<input type="radio" name="ftype" value="csv" formControlName="ftype" /> CSV
							<span></span>
						</label>
					</div>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
			<span>
				<button [disabled]="generalLedgerForm.invalid || isLoadingGeneralLedger" type="button" class="btn btn-brand btn-sm" (click)="submitGeneralLedger()">Submit</button>
			</span>
		</div>
	</div>
</div>
