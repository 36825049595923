import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UpperCasePipe } from '@angular/common';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { MReportsService, WodAccount } from '../../../../m-reports.service';
import { Entity } from '../../../../../../management/entities/entities.model';
import { selectEntityData } from 'src/app/_state/entity/entity.selectors';
import { WhitfieldsCallLampyService } from '../../../../../whitfields-call-lampy.service';
import { lampyPayloadDateFormat } from '../../../../../whitfields-call-lampy-payload.model';
import { ConfigsService } from '../../../../../../../../../_shared/services/configs.service';

declare const toastr: any;
declare const mApp: any;
declare const swal: any;
declare const pdfMake: any;
declare const $: any;

interface GeneralLedger {
	accountscount: number;
	complex: string;
	ledgers: {
		account: string;
		complex: string;
		crTotal: number;
		deTotal: number;
		description: string;
		entries: {
			Credit: string;
			Debit: string;
			Description: string;
			Reference: string;
			RunningBalance: string;
			TxDate: string;
		}[];
		ledgerTotal: string;
		runningBalance: number;
		type: string;
	}[];
}

@Component({
	selector: 'app-accounts-report-general-ledger-form',
	templateUrl: './accounts-report-general-ledger-form.component.html',
	styleUrls: ['./accounts-report-general-ledger-form.component.scss'],
})
export class AccountsReportGeneralLedgerFormComponent implements OnInit, OnDestroy, AfterViewChecked {
	public generalLedgerForm: FormGroup;
	public accountTypes: string[] = [];
	public entity: Entity;
	public filteredList: WodAccount[] = [];
	public isLoadingGeneralLedger = false;
	public entityLoaded = false;
	private subscriptions = new Subscription();
	private accountsList: WodAccount[];
	private date = moment().format('DD/MM/YY');
	private datePickerMomentFormat = 'DD-MM-yyyy';
	private useNewPastel = false;
	public datePickerControls = ['from', 'to'];

	public modalBodyId = 'm_blockui_1_content';
	public generalLedgerModalId = 'generalLedgerModal';

	constructor(
		private mReportsService: MReportsService,
		private formBuilder: FormBuilder,
		private store: Store,
		private whitfieldsCallLampyService: WhitfieldsCallLampyService,
		private upperCasePipe: UpperCasePipe,
		private configsService: ConfigsService
	) {}

	ngOnInit() {
		this.generalLedgerForm = this.initGeneralLedgerForm();
		const { from, to, account, search } = this.generalLedgerForm.controls;
		let { items } = this.generalLedgerForm.controls;

		const entitySub = this.store
			.select(selectEntityData)
			.pipe(
				filter(entity => !!entity.whitfieldsPrefix),
				take(1)
			)
			.subscribe(async entity => {
				this.entity = entity;

				// Revert new pastel general ledgers. https://noldor.atlassian.net/browse/AM-1967
				const { enableNewPastelGeneralLedger } = await this.configsService.getProductConfig();

				if (!enableNewPastelGeneralLedger) {
					await this.getAccounts(entity, account, items, search);
				}

				const newPastelDateMoment = moment(entity.newPastelDate, 'DD-MM-YYYY');

				this.entityLoaded = true;

				// Logic from https://noldor.atlassian.net/browse/AM-1632
				this.subscriptions.add(
					from.valueChanges.pipe(distinctUntilChanged()).subscribe(fromValue => {
						if (enableNewPastelGeneralLedger) {
							this.accountTypes = [];
							this.accountsList = [];
							this.filteredList = [];

							account.reset();
							search.reset();
							items.reset();

							account.disable();
							search.disable();
							items.disable();
						}

						to.reset();

						to.disable();

						try {
							const fromValueMoment = moment(fromValue, this.datePickerMomentFormat);

							if (!newPastelDateMoment.isValid() && entity.newPastelDate) {
								throw new Error(`newPastelDate: ${entity.newPastelDate} is not a valid date. Please update the entity details.`);
							}

							// "Once the Start Date has been selected, the End Date block opens for capturing."
							if (fromValue) {
								if (!fromValueMoment.isValid()) {
									throw new Error(`fromValue: ${fromValue} is not a valid date.`);
								}

								return to.enable();
							}

							to.reset();
							return to.disable();
						} catch (err) {
							console.error(err);
							toastr.error(err);
						}
					})
				);

				this.subscriptions.add(
					to.valueChanges.pipe(distinctUntilChanged()).subscribe(async toValue => {
						try {
							if (toValue) {
								const toValueMoment = moment(toValue, this.datePickerMomentFormat);

								const fromValue = from.value;
								const fromValueMoment = moment(fromValue, this.datePickerMomentFormat);

								if (!toValueMoment.isValid()) {
									throw new Error(`toValue: ${toValueMoment} is not a valid date.`);
								}

								if (!fromValueMoment.isValid()) {
									throw new Error(`fromValue: ${fromValue} is not a valid date.`);
								}

								// From can't be after to.
								if (fromValueMoment.isAfter(toValueMoment)) {
									// FIXME: https://github.com/angular/angular/issues/19170
									setTimeout(() => {
										to.markAsDirty();
										to.setErrors({ afterTo: true });
									}, 1);
									return;
								}

								if (fromValue) {
									let mixedPastel = false;
									this.useNewPastel = this.entity.isFirefly;

									if (enableNewPastelGeneralLedger) {
										// If the newPastelDate field has a value.
										if (entity.newPastelDate) {
											if (fromValueMoment.isBefore(newPastelDateMoment)) {
												// "If the start date that is captured is before the date when the scheme went onto the new Pastel database..."
												//  "...then the end date must be after the start date..."
												if (toValueMoment.isAfter(fromValueMoment)) {
													//  "...but no later than the date captured in Our Scheme."
													if (newPastelDateMoment.isBefore(toValueMoment)) {
														mixedPastel = true;
													}
												}
											}

											// "If the start date captured is on or after the date captured..."
											if (fromValueMoment.isSame(newPastelDateMoment) || fromValueMoment.isAfter(newPastelDateMoment)) {
												// "...the end date can be any date, provided it is after the start date."
												// if (toValueMoment.isAfter(fromValueMoment)) {
												// 	this.useNewPastel = true;
												// }
											}
										}

										if (mixedPastel) {
											// We don't want to mix accounts, so display a warning and fetch from old pastel.
											swal(
												'Mixed accounts detected!',
												'The date range you selected is both before and after the new pastel date. Note that the resulting accounts are only from old Pastel.',
												'warning'
											);
										}

										this.blockUi(this.modalBodyId, `Getting ${entity.whitfieldsPrefix} accounts from ${this.useNewPastel ? 'new Pastel' : 'old Pastel'} ...`);

										// TODO: "...and displays either the accounts in the old Pastel or the new Pastel, based on the dates selected"
										await this.getAccounts(entity, account, items, search);
									}

									to.setErrors(null);
									this.unblockUi(this.modalBodyId);
									return this.generalLedgerForm.updateValueAndValidity();
								}

								account.disable();
								search.disable();
								items.disable();

								const err = `From: ${fromValue}, To: ${toValue}, New pastel date: ${newPastelDateMoment.format(this.datePickerMomentFormat)}`;
								toastr.error(err, 'Invalid date range.');
								console.error(`Invalid date range: ${err}`);

								to.setErrors(['does not meet requirements', true]);
								this.unblockUi(this.modalBodyId);

								return this.generalLedgerForm.updateValueAndValidity();
							}
						} catch (err) {
							console.error(err);
							toastr.error(err);
							this.unblockUi(this.modalBodyId);
						}
					})
				);
			});

		this.subscriptions.add(entitySub);
	}

	ngAfterViewChecked(): void {
		const pickers = $('.m_datepicker');

		pickers.datepicker({
			format: 'dd-mm-yyyy',
			todayHighlight: true,
			forceParse: false,
			orientation: 'bottom left',
			immediateUpdates: true,
			autoclose: true,
			todayBtn: 'linked',
			clearBtn: true,
			templates: {
				leftArrow: '<i class="la la-angle-left"></i>',
				rightArrow: '<i class="la la-angle-right"></i>',
			},
		});

		pickers.on('changeDate', ({ date, currentTarget }) => {
			let value = '';
			if (date) {
				value = moment(date).format(this.datePickerMomentFormat);
			}
			this.generalLedgerForm.get(currentTarget.id).setValue(value);
		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private async getAccounts(entity: Entity, account: AbstractControl, items: AbstractControl, search: AbstractControl) {
		try {
			let prefix = entity.isFirefly ? entity.prefix : entity.whitfieldsPrefix;
			const accounts = await this.mReportsService.getAccounts(prefix, entity.isFirefly);

			this.accountTypes = this.getUnique(accounts, 'type');
			this.accountsList = accounts;
			this.filteredList = accounts;

			account.setValue(
				this.accountsList.map(({ accountLink, account }) => {
					let accStr = `${accountLink}_${account}`;
					if (!accountLink) {
						accStr = `_${account}`;
					}

					return accStr;
				})
			);

			this.generalLedgerForm.setControl('items', this.toFormGroup(this.accountTypes));
			items = this.generalLedgerForm.controls.items;
			items.enable();

			this.searchAccounts();
		} catch (err) {
			const errMsg = 'Issue getting accounts';
			console.error(`${errMsg}: ${err}`);
			toastr.error(err, errMsg);
		}

		account.enable();
		search.enable();
	}

	private getUnique(arr: WodAccount[], comp: string): string[] {
		return arr
			.map(e => e[comp])
			.map((e, i, final) => final.indexOf(e) === i && i)
			.filter(e => arr[e])
			.map(e => arr[e].type);
	}

	initGeneralLedgerForm(): FormGroup {
		return this.formBuilder.group({
			account: [{ value: [], disabled: true }, [Validators.required]],
			from: ['', [Validators.required]],
			to: [{ value: '', disabled: true }, [Validators.required]],
			ftype: ['pdf', [Validators.required]],
			filter: [''],
			search: [{ value: '', disabled: true }],
			items: this.toFormGroup(this.accountTypes),
		});
	}

	toFormGroup(types: any[]) {
		let group: any = {};

		types.forEach(question => {
			group[question] = new FormControl({ value: true, disabled: true });
		});

		return new FormGroup(group);
	}

	searchAccounts() {
		this.subscriptions.add(
			this.generalLedgerForm.controls.search.valueChanges.subscribe(val => {
				if (val !== '' && val !== null) {
					this.filteredList = this.accountsList.filter(account => {
						return account.account.includes(val);
					});
				} else {
					this.filteredList = this.accountsList;
				}
			})
		);
	}

	public filterAccounts(type: string) {
		const itemsTypeValue = this.generalLedgerForm.controls.items.value[type];

		const accountCtrl = this.generalLedgerForm.get('account');
		const accountValue = accountCtrl.value;

		this.accountsList.forEach(account => {
			if (account.type === type) {
				let accStr = `${account.accountLink}_${account.account}`;
				if (!account.accountLink) {
					accStr = `_${account.account}`;
				}

				const includedInAccountValue = accountValue.includes(accStr);

				if (itemsTypeValue !== '' && itemsTypeValue !== false) {
					if (!includedInAccountValue) {
						accountValue.push(accStr);
					}
				} else {
					if (includedInAccountValue) {
						const index = accountValue.findIndex((item: string) => item === accStr);
						if (index >= 0) {
							accountValue.splice(index, 1);
						}
					}
				}
			}
		});

		accountCtrl.setValue(accountValue);
		accountCtrl.clearValidators();
		accountCtrl.updateValueAndValidity();
	}

	public clearAccountTypeFilters(): void {
		const itemsGrp = this.generalLedgerForm.get('items') as FormGroup;

		itemsGrp.reset();
	}

	public async submitGeneralLedger() {
		const { from, to, ftype, items, account } = this.generalLedgerForm.value;

		this.isLoadingGeneralLedger = true;
		this.blockUi(this.modalBodyId, `Generating general ledger ${this.upperCasePipe.transform(ftype)} using  ${this.useNewPastel ? 'new Pastel' : 'old Pastel'}...`);

		let tmpAccounts: any = [];

		if (!this.useNewPastel) {
			Object.keys(items)
				.filter(value => items[value])
				.forEach(key => {
					const tmpList = this.accountsList.filter((item: any) => item.type === key).map((item: any) => item.accountLink + '_' + item.account);
					tmpAccounts.push(tmpList);
				});
		}

		let finalAccounts: string[] = account.flat();

		if (account !== '') {
			if (Array.isArray(account) && account.length > 0) {
				finalAccounts = [...account, ...tmpAccounts.flat()];
			}
		}

		finalAccounts = [...new Set(finalAccounts)];

		if (this.generalLedgerForm.valid) {
			let startDate: string;
			let endDate: string;
			let generalLedger: GeneralLedger;
			let calculatedCredit = 0.0;
			let calculatedDebit = 0.0;

			try {
				const newPastelGeneralLedger: GeneralLedger = { accountscount: finalAccounts.length, complex: '', ledgers: [] };
				if (this.useNewPastel) {
					startDate = moment(from, this.datePickerMomentFormat).format(lampyPayloadDateFormat);
					endDate = moment(to, this.datePickerMomentFormat).format(lampyPayloadDateFormat);

					const complex = this.entity.whitfieldsPrefix;
					const result = await this.whitfieldsCallLampyService.getLampyGeneralLedger(finalAccounts, startDate, endDate);
					const lampyResult = result['result'];
					for (const finalAccount of finalAccounts) {
						const [, accountNumber] = finalAccount.split('_');

						const { transactions } = lampyResult[accountNumber];

						const { account, description, type } = this.accountsList.find(({ account }) => {
							return account === accountNumber;
						});

						const balance = lampyResult[accountNumber]['totals'];

						newPastelGeneralLedger.ledgers.push({
							account,
							description,
							type,
							complex,
							crTotal: balance.credit,
							deTotal: balance.debit,
							entries: transactions.map(transaction => {
								const { credit, debit, description, reference, txdate } = transaction;

								calculatedCredit += +credit;
								calculatedDebit += +debit;

								const RunningBalance = `${calculatedDebit - calculatedCredit}`;
								return {
									Credit: `${credit}`,
									Debit: `${debit}`,
									Description: `${description}`,
									Reference: `${reference}`,
									RunningBalance,
									TxDate: moment(txdate).format('YYYY-MM-DD'),
								};
							}),
							ledgerTotal: `${balance.balance}`,
							runningBalance: balance.balance,
						});
					}

					generalLedger = newPastelGeneralLedger;
					generalLedger.complex = this.entity.prefix;
				} else {
					startDate = moment(from, this.datePickerMomentFormat).format('DD/MM/YYYY');
					endDate = moment(to, this.datePickerMomentFormat).format('DD/MM/YYYY');
					const data = await this.mReportsService.getGeneralLedger(startDate, endDate, this.entity.whitfieldsPrefix, finalAccounts).toPromise();
					generalLedger = data as GeneralLedger;
					generalLedger = { ...newPastelGeneralLedger, ...data } as GeneralLedger;
				}

				switch (ftype) {
					case 'pdf':
						this.generateGeneralLedgerPDF(generalLedger);
						break;
					case 'csv':
						this.generateGeneralLedgerCSV(generalLedger);
						break;
				}
			} catch (err) {
				const errMsg = `There was a problem generating the general ledger.`;
				console.error(err);
				toastr.error(err, errMsg);
				this.unblockUi(this.modalBodyId);
			}
		} else {
			toastr.error('Form invalid');
		}

		this.isLoadingGeneralLedger = false;
		this.unblockUi(this.modalBodyId);
	}

	generateGeneralLedgerPDF(data) {
		try {
			let title = `${data.complex}_General_Ledger_${this.date}`;
			const buildTableBody = (tableData, columns) => {
				var body = [];
				body.push(columns);
				if (tableData.entries) {
					if (tableData.entries.length > 0) {
						if (tableData.hasBBF) {
							const bbfEntry = {
								Credit: tableData.creditBroughtForward ? tableData.creditBroughtForward : '0.00',
								Debit: tableData.debitBroughtForward ? tableData.debitBroughtForward : '0.00',
								Description: tableData.description,
								Reference: '',
								RunningBalance: tableData.openingRunningBalance,
								TxDate: '',
							};
							tableData.entries.unshift(bbfEntry);
						}
						tableData.entries.forEach(function (row) {
							var dataRow = [];

							columns.forEach(function (column) {
								dataRow.push(row[column.text].toString());
							});
							body.push(dataRow);
						});
						body.push(['', '', { text: 'Total', bold: true }, '', '', { text: tableData.runningBalance, bold: true }]);
					}
				}
				return body;
			};

			const table = (data, columns) => {
				const cols = [];
				columns.forEach(col => {
					cols.push({ text: col, bold: true });
				});
				return {
					style: 'table',
					table: {
						widths: ['*', '*', '30%', '*', '*', '*'],
						headerRows: 1,
						body: buildTableBody(data, cols),
					},
					layout: {
						hLineColor: function (i, node) {
							return i === 0 || i === node.table.body.length ? 'dark gray' : 'dark gray';
						},
						vLineColor: function (i, node) {
							return i === 0 || i === node.table.widths.length ? 'dark gray' : 'dark gray';
						},
					},
				};
			};

			const buildLedgerTables = data => {
				const builds = [];
				if (data.ledgers) {
					data.ledgers.forEach(ledger => {
						builds.push(
							{
								text: `${ledger.complex}/${ledger.account} (${ledger.description})`,
								style: 'subheader',
								alignment: 'center',
							},
							table(ledger, ['TxDate', 'Reference', 'Description', 'Debit', 'Credit', 'RunningBalance'])
						);
					});
				}
				return builds;
			};

			// Usage
			const docDefinition = {
				pageMargins: [8, 8, 8, 8],
				content: [
					{
						text: `General Ledger - ${data.complex}`,
						style: 'header',
						alignment: 'center',
					},
					buildLedgerTables(data),
				],
				styles: {
					header: {
						fontSize: 18,
						margin: [0, 10, 0, 5],
					},
					table: {
						fontSize: 8,
						margin: [0, 5, 0, 15],
					},
					subheader: {
						fontSize: 14,
						margin: [0, 0, 0, 10],
					},
					tableHeader: {
						fontSize: 13,
						color: 'black',
					},
					date: {
						margin: [0, 5, 0, 5],
					},
				},
			};
			pdfMake.createPdf(docDefinition).download(`${title}.pdf`);
		} catch (err) {
			const errMsg = 'Issue generating PDF';
			console.error(`${errMsg}: ${err}`);
			toastr.error(err, errMsg);
			this.unblockUi(this.modalBodyId);
		}
	}

	generateGeneralLedgerCSV(data) {
		try {
			const buildTables = () => {
				const tables = [];
				tables.push({ title: `General Ledger - ${data.complex}` });
				data.ledgers.forEach(ledger => {
					tables.push({ name: `${ledger.complex}/${ledger.account} (${ledger.description})` });
					tables.push({ TxDate: 'TxDate', Reference: 'Reference', Description: 'Description', Debit: 'Debit', Credit: 'Credit', RunningBalance: 'RunningBalance' });
					if (ledger.hasBBF) {
						tables.push({
							Credit: ledger.creditBroughtForward ? ledger.creditBroughtForward : '0.00',
							Debit: ledger.debitBroughtForward ? ledger.debitBroughtForward : '0.00',
							Description: ledger.description,
							Reference: '',
							RunningBalance: ledger.openingRunningBalance,
							TxDate: '',
						});
					}
					if (ledger.entries) {
						ledger.entries.forEach(entry => {
							tables.push({
								TxDate: entry.TxDate,
								Reference: entry.Reference,
								Description: entry.Description,
								Debit: entry.Debit,
								Credit: entry.Credit,
								RunningBalance: entry.RunningBalance,
							});
						});
					}
					tables.push({ TxDate: '', Reference: '', Description: 'Total', Debit: '', Credit: '', RunningBalance: ledger.runningBalance });
				});
				return tables;
			};
			const buildData = buildTables();
			new ngxCsv(buildData, `${data.complex}_General_Ledger_${this.date}`);
		} catch (err) {
			const errMsg = 'Issue generating CSV';
			console.error(`${errMsg}: ${err}`);
			toastr.error(err, errMsg);
			this.unblockUi(this.modalBodyId);
		}
	}

	private blockUi(id: string, message = 'Processing...'): void {
		mApp.block(`#${id}`, { overlayColor: '#000000', type: 'loader', state: 'brand', message });
	}

	private unblockUi(id: string): void {
		mApp.unblock(`#${id}`);
	}
}
