import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import typesense from 'typesense';
import { AngularFirestore } from '@angular/fire/firestore';
import { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents';
import { ConfigurationOptions } from 'typesense/lib/Typesense/Configuration';
import { MultiSearchRequestSchema, MultiSearchRequestsSchema, MultiSearchResponse } from 'typesense/lib/Typesense/MultiSearch';

@Injectable({
	providedIn: 'root',
})
export class TypesenseService {
	constructor(private afs: AngularFirestore) {}

	private async getAdminTypesenseConfig(): Promise<ConfigurationOptions> {
		const { typesense } = (await this.afs.doc(`/amiti/config`).ref.get()).data() as any;
		typesense.apiKey = typesense.adminKey;
		delete typesense.adminKey;
		delete typesense.test;
		return typesense as ConfigurationOptions;
	}

	private async getSeachOnlyTypesenseConfig(): Promise<ConfigurationOptions> {
		const { typesense } = (await this.afs.doc(`/amiti/config`).ref.get()).data() as any;
		delete typesense.adminKey;
		delete typesense.test;
		return typesense as ConfigurationOptions;
	}

	public async retrieveCollection(collectionName: string) {
		const config = await this.getAdminTypesenseConfig();
		const typesenseClient = new typesense.Client(config);

		return typesenseClient.collections(`${environment.firebase.projectId}-${collectionName}`).retrieve();
	}

	public async searchCollection(collection: string, search: SearchParams): Promise<SearchResponse<any>> {
		const config = await this.getSeachOnlyTypesenseConfig();
		const typesenseClient = new typesense.Client(config);

		return typesenseClient.collections(collection).documents().search(search);
	}

	public async multiSearch(searchRequests: MultiSearchRequestsSchema, commonSearchParams: Partial<MultiSearchRequestSchema>): Promise<MultiSearchResponse<any>> {
		const config = await this.getSeachOnlyTypesenseConfig();
		const typesenseClient = new typesense.Client(config);

		return typesenseClient.multiSearch.perform(searchRequests, commonSearchParams);
	}

	public searchEntitiesManagementList(q: string, per_page = 250, page = 1): Promise<SearchResponse<any>> {
		const search: SearchParams = {
			q,
			query_by: 'name, entity_type, product, prefix, createdby',
			per_page,
			page,
		};
		return this.searchCollection(`${environment.firebase.projectId}-entities`, search);
	}

	public searchTasks(q: string, filter_by: string, per_page = 250, page = 1, { field, order }): Promise<SearchResponse<any>> {
		const defaultSortBy = 'subject';

		let sort_by: string;
		const intFields = ['created', 'updated']; // these fields are already int types, sort is supported
		if (intFields.includes(field)) {
			sort_by = `${field}:${order}`;
		} else {
			const sortFieldsToIgnore = ['#', 'orderBy'];
			if (sortFieldsToIgnore.includes(field)) {
				field = defaultSortBy;
			}

			sort_by = `${field}_sort_by:${order}`;
		}

		const search: SearchParams = {
			q,
			query_by: 'entityId, refNo, refNo_keywords, taskType, subject, priority, status, managerName, createdByName, watchers, taskTeam, managerId', //   created, updated (has to be sting or array)
			filter_by,
			per_page,
			page,
			sort_by,
		};

		return this.searchCollection(`${environment.firebase.projectId}-tasksLookup`, search);
	}
}
