import { Directive } from '@angular/core';
import { RouterLinkWithHref, ActivatedRoute, Router } from '@angular/router';

// TODO: This directive seems unnecessary now that Angular has built in functionality preserving query params (https://angular.io/api/router/RouterLink & https://angular.io/api/router/QueryParamsHandling)

@Directive({
	selector: 'a[routerLink]',
})
export class PreserveQueryParamsDirective {
	constructor(private link: RouterLinkWithHref, private route: ActivatedRoute, private router: Router) {
		this.route.queryParamMap.subscribe(queryParams => {
			if (this.route.snapshot.queryParams['prefix']) {
				this.link.queryParams = Object.assign({}, { prefix: this.route.snapshot.queryParams['prefix'] });
			}
		});
	}
}
