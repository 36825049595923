import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SchemesService } from '../../entities/entities.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../../src/environments/environment';
import { config } from '../../../../../../../../src/configs/configs';
import { AdvancedSearchService } from '../advanced-search/advanced-search.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { take } from 'rxjs/operators';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';
declare const toastr: any;
declare const mUtil: any;
declare const mDropdown: any;
declare const $: any;
@Component({
	selector: 'app-quick-search',
	templateUrl: './quick-search.component.html',
	styleUrls: ['./quick-search.component.scss'],
})
export class QuickSearchComponent implements OnInit {
	@Input() source: string;
	config = config[environment.product];
	searchForm: FormGroup;
	results: any;
	searchResult = false;
	isSearching = false;
	userResults = [];
	accountsResults = [];
	providerResults = [];
	public scrollConfig: PerfectScrollbarConfigInterface = {};
	entityId: any;
	constructor(
		private formbuilder: FormBuilder,
		private schemesService: SchemesService,
		private permissionsService: NgxPermissionsService,
		private router: Router,
		private advancedSearchService: AdvancedSearchService,
		private store: Store
	) {}

	ngOnInit() {
		this.store
			.select(selectEntityId)
			.pipe(take(1))
			.subscribe(entityId => (this.entityId = entityId));
		this.buildSearchForm();
		const resetForm = () => {
			this.searchForm.reset();
			this.results = [];
			this.userResults = [];
			this.accountsResults = [];
			this.providerResults = [];
		};
		if (this.source !== 'management') {
			setTimeout(() => {
				$(document).on('click', function (e) {
					const element = mUtil.getByClass('quick-links');
					if (element) {
						if (!mUtil.hasClass(element, 'm-dropdown--open')) {
							resetForm();
						}
					}
				});
			}, 200);
		}
	}

	buildSearchForm() {
		this.searchForm = this.formbuilder.group({
			schemePrefix: [''],
			account_number: [''],
			email: [''],
			provider: [''],
		});
	}

	clear() {
		this.userResults = [];
		this.results = [];
		this.accountsResults = [];
		this.providerResults = [];
	}

	setSearchResults(name, data, setSearch = false) {
		if (setSearch) {
			this.searchResult = true;
		}

		this[name] = data;
		this.isSearching = false;
	}

	search() {
		this.clear();
		this.isSearching = true;
		const { email, account_number, schemePrefix, provider } = this.searchForm.value;

		if (email && account_number) {
			this.advancedSearchService.searchEmailAndAccountNumber(email, account_number).then((data: any) => {
				this.setSearchResults('accountsResults', data);
			});
		} else {
			if (schemePrefix && email) {
				this.advancedSearchService.searchSchemesAndUsers(schemePrefix, email).then((data: any) => {
					this.setSearchResults('userResults', data);
				});
			} else {
				if (schemePrefix && !account_number) {
					this.schemesService.searchSchemes(schemePrefix).then((data: any) => {
						this.setSearchResults('results', data, true);
					});
				} else {
					if (account_number && !email) {
						this.advancedSearchService.searchFromAccountNumber(account_number).then((data: any) => {
							this.setSearchResults('accountsResults', data);
						});
					} else {
						if (email) {
							this.advancedSearchService.searchByEmail(email).then((data: any) => {
								this.setSearchResults('userResults', data);
							});
						} else {
							if (provider) {
								this.advancedSearchService.searchServiceProviders(provider).then((data: any) => {
									data = data.filter((value, index, self) => index === self.findIndex(t => t.id === value.id));
									this.setSearchResults('providerResults', data);
								});
							} else {
								this.clear();
								this.isSearching = false;
							}
						}
					}
				}
			}
		}
	}

	viewEntity(entity) {
		this.schemesService
			.getEntityDetails(entity.id)
			.pipe(take(1))
			.subscribe(entityDetails => {
				this.schemesService
					.setEntity(entityDetails)
					.then(() => {
						this.router.navigate([`/${entity.id}${this.config.headerQuickLinksDashboardUrl}`], { queryParams: { prefix: entityDetails.prefix } });
						this.searchResult = false;
						this.results = [];
					})
					.catch(err => {
						console.warn(err);
					});
			});
	}

	async goToSelected(entityId: string, type: string, itemId: string, entityData?: any) {
		// CHECK ENTITY
		if (!entityData) {
			await this.advancedSearchService.fetchEntityDetails(entityId).then(entity => {
				entityData = entity;
			});
		}
		if (type === 'user') {
			const exists = await this.advancedSearchService.checkEntityUserExists(entityId, itemId);
			if (!exists) {
				const index = this.userResults.findIndex(item => item.entityId === entityId);
				if (index && index !== -1) {
					this.userResults[index]['error'] = true;
				}
			} else {
				this.advancedSearchService.goToEntity(entityData, `/${entityId}/users/edit/${itemId}`);
			}
		} else {
			if (type === 'account') {
				this.advancedSearchService.goToEntity(entityData, `/${entityId}/fin/debtors/edit/${itemId}`);
			}
		}
		if (type == 'provider') {
			this.router.navigate([`whitfields/management/service-providers/edit/${itemId}`]);
		}
	}
}
