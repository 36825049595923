<!-- begin::Footer -->
<footer class="m-grid__item m-footer" appunwraptag="">
	<div class="m-container m-container--fluid m-container--full-height m-page__container">
		<div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
			<div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
				<span *ngIf="product !== 'whitfields'" class="m-footer__copyright">
					&copy; Copyright <a href="https://amiti.cloud/" class="m-link m--font-bolder" target="_blank">Amiti</a> {{ footerYear }} <span class="m--font-bolder"></span>.
					All Rights Reserved. [ {{ buildId }} ]
				</span>
				<span *ngIf="product === 'whitfields'" class="m-footer__copyright">
					&copy; Copyright {{ product.toUpperCase() }} {{ footerYear }} <span class="m--font-bolder"></span>. All Rights Reserved. [ {{ buildId }} ]
				</span>
			</div>
			<div *ngIf="product !== 'whitfields'" class="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
				<ul class="m-footer__nav m-nav m-nav--inline m--pull-right">
					<li class="m-nav__item">
						<a [attr.href]="'https://amiti.cloud/' + product" target="_blank" class="m-nav__link">
							<span class="m-nav__link-text">About Amiti {{ product | titlecase }}</span>
						</a>
					</li>
					<li class="m-nav__item">
						<a class="m-nav__link">
							<span class="m-nav__link-text">|</span>
						</a>
					</li>
					<li class="m-nav__item">
						<a href="https://amiti.cloud/" target="_blank" class="m-nav__link">
							<span class="m-nav__link-text">About Amiti</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</footer>

<!-- end::Footer -->
