export class Upload {
	$key?: string;
	file?: File;
	name?: string;
	downloadFile?: string;
	path?: string;
	size?: string;
	created?: any;
	createdByUID?: string;
	createdByName?: string;
	filetype?: string;
	ref?: any;
	saveRef?: any;
	active?: boolean;
	saved?: boolean;
	order?: number;
	downloadFileThumbnail?: string;

	constructor(file: File) {
		this.file = file;
	}
}
