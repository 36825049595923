import { AuthenticationService } from '../../../auth/_services/authentication.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { EntitiesService } from '../../pages/default/plex/entities/entities.service';
import { IntroStepsService } from '@services/intro-steps.service';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

@Component({
	selector: 'app-intro-steps',
	templateUrl: './intro-steps.component.html',
})
export class IntroStepsComponent implements OnInit, OnDestroy {
	@Input() componentStep: number;
	@Input() area: string;

	currentStep: number;
	userId: string;
	entityId: string;
	entityUserSubscription: Subscription;
	userSubscription: Subscription;

	customClass = environment.product === 'can' ? 'can' : 'plex';

	stepTwoType = this.getStepTwoType(environment.product);
	stepThreeType = this.getStepThreeType(environment.product);

	iconSteps = [2, 3];

	stepIcons = ['envelope', 'mobile', 'ellipsis-v'];

	scheme = environment.product === 'can' ? 'association' : 'scheme';
	productVerb = environment.product === 'can' ? 'your association' : 'PLEX';
	product = environment.product === 'can' ? 'association' : 'PLEX';

	stepData = {
		1: [
			`Your ${this.scheme} prefix and name will always be displayed here`,
			`Below you will also see the address of your ${this.scheme}’s website, you can click on it at any time to navigate to it`,
			`The prefix is the system’s unique identifier for your ${this.scheme}`,
		],
		4: [`These blocks will remind you if you haven’t added any ${this.stepTwoType} or ${this.stepThreeType} yet.`],
		5: ['These are your quick access buttons to access functions you might need more often.'],
		6: [`Click here to access our Frequently Asked Questions on advice, tips and hints when using ${this.productVerb}`],
		7: ['Switch the interactive help feature on and off by clicking on this button'],
		8: [
			'You can access your own profile by clicking on the letter that represents the first letter of your name',
			'You can update your details from your profile page',
			'You can view tasks assigned to you from the My tasks section.',
		],
		9: [
			`This is your main menu bar, from here you can access all the functions that the ${this.product} admin system offers`,
			'Hover your mouse over each icon to see a description of the function',
		],
	};

	iconStepData = {
		2: {
			type: `${this.stepTwoType.toLowerCase()}`,
			description: `${this.stepTwoType.toLowerCase()}, or email and sms ${this.stepThreeType.toLowerCase()} linked to these ${this.stepTwoType.toLowerCase()}.`,
		},
		3: {
			type: `${this.stepThreeType.toLowerCase()}`,
			description: `${this.stepThreeType.toLowerCase()}, or email and sms ${this.stepThreeType.toLowerCase()}`,
		},
	};

	constructor(public entitiesService: EntitiesService, private introStepService: IntroStepsService, private auth: AuthenticationService, private store: Store) {}

	ngOnInit() {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		this.userSubscription = this.auth.user.subscribe(({ uid }) => {
			this.userId = uid;
			this.entityUserSubscription = this.introStepService.fetchEntityUser(uid, this.entityId).subscribe(({ dashboardstep } = { dashboardstep: 1 }) => {
				this.currentStep = dashboardstep || 1;
			});
		});
	}

	dismissHelpMessages() {
		this.introStepService.completeHelpSteps(this.currentStep, this.userId, this.entityId);
	}

	goToNextStep(change) {
		const dashboardstep = this.currentStep + change;
		const userSteps = {
			dashboardsteps: '',
			dashboardstep,
		};
		this.introStepService.updateUserHelpSteps(userSteps, this.userId, this.entityId).then(() => {
			const direction = change > 0 ? 'next' : 'prev';
			this.introStepService.goToNextStep(this.currentStep, direction);
		});
	}

	getStepTwoType(product) {
		switch (product) {
			case 'can':
				return 'Subgroups';
			default:
				return 'Properties';
		}
	}

	getStepThreeType(product) {
		switch (product) {
			case 'can':
				return 'Members';
			default:
				return 'Users';
		}
	}

	ngOnDestroy() {
		if (this.userSubscription) {
			this.userSubscription.unsubscribe();
		}
		if (this.entityUserSubscription) {
			this.entityUserSubscription.unsubscribe();
		}
	}
}
