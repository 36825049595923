<!-- INTRO TEXT MESSAGE -->
<app-intro-steps [componentStep]="6" area="header-end"></app-intro-steps>
<app-intro-steps [componentStep]="7" area="header-end"></app-intro-steps>
<app-intro-steps [componentStep]="8" area="header-end"></app-intro-steps>
<app-intro-steps [componentStep]="10" area="body-right"></app-intro-steps>

<div id="step10" class="m-portlet m-portlet--head-sm" [ngClass]="showPopover ? 'help-pop' : 'no-show'">
	<!-- HELP -->
	<div class="help-pulse-icon">
		<div class="icon-wrap">
			<i class="icon fa fa-question-circle" aria-hidden="true"></i>
		</div>
		<div class="elements">
			<div class="circle circle-inner"></div>
		</div>
	</div>
	<!-- HELP TEXT -->
	<div class="popped left">
		You can add users to your management team, email or sms team members by pressing the
		<span class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill">
			<i class="la la-envelope"></i>
		</span>
		<span class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill">
			<i class="la la-mobile"></i>
		</span>
		<span class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill">
			<i class="la la-ellipsis-v"></i>
		</span>
		buttons
		<br />
		<br />
		You can find all of the User options by clicking the
		<button class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only btn-sm m-btn--pill m-dropdown__toggle">
			<i class="la la-angle-down"></i>
		</button>
		button
	</div>

	<div class="help-pulse-icon">
		<div class="icon-wrap">
			<i class="icon fa fa-question-circle" aria-hidden="true"></i>
		</div>
		<div class="elements">
			<div class="circle circle-inner"></div>
		</div>
	</div>
	<div class="m-portlet__head">
		<div class="m-portlet__head-caption">
			<div class="m-portlet__head-title">
				<h3 class="m-portlet__head-text">
					{{ config?.managementName }}
				</h3>
			</div>
		</div>
		<div class="m-portlet__head-tools">
			<ul class="m-portlet__nav">
				<!-- <li class="m-portlet__nav-item">
                    <a href="#" class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                        (click)="emailManagment()">
                        <i class="la la-envelope"></i>
                    </a>
                </li>
                <ng-template #generatingUserEmailSpinner>
                    <div class="m-loader m-loader--brand" style="width: 30px; display: inline-block;"></div>
                </ng-template>
                <li class="m-portlet__nav-item">
                    <a href="#" class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                        (click)="smsManagment()">
                        <i class="la la-mobile"></i>
                    </a>
                </li> -->
				<ng-template #generatingUserSMSSpinner>
					<div class="m-loader m-loader--brand" style="width: 30px; display: inline-block"></div>
				</ng-template>

				<li
					*ngxPermissionsOnly="['admin', 'addEntityManagers', 'editEntityManagers']"
					class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
					m-dropdown-toggle="hover"
					aria-expanded="true"
				>
					<a href="#" class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill m-dropdown__toggle">
						<i class="la la-ellipsis-v"></i>
					</a>

					<div class="m-dropdown__wrapper" style="z-index: 101">
						<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" style="left: auto; right: 21.5px"></span>
						<div class="m-dropdown__inner">
							<div class="m-dropdown__body">
								<div class="m-dropdown__content">
									<ul class="m-nav">
										<li class="m-nav__item">
											<a href="#" class="m-nav__link" (click)="emailManagment()">
												<i class="m-nav__link-icon flaticon-email"></i>
												<span class="m-nav__link-text">Email All Management</span>
											</a>
										</li>

										<li class="m-nav__item">
											<a href="#" class="m-nav__link" (click)="smsManagment()">
												<i class="m-nav__link-icon la la-mobile"></i>
												<span class="m-nav__link-text">SMS All Management</span>
											</a>
										</li>

										<hr />
										<li class="m-nav__item" *ngxPermissionsOnly="['admin', 'addEntityManagers']">
											<a href="#" class="m-nav__link" (click)="addSectionToggle()">
												<i class="m-nav__link-icon la la-plus"></i>
												<span class="m-nav__link-text">Add Member</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="m-portlet__body">
		<span *ngIf="!showAddSection && !showAddProfileSection && !showRemoveProfileSection && !addingSpinner">
			<span *ngFor="let user of users">
				<div class="row p-1">
					<div class="col-xl-10">
						<small
							><b>{{ user.firstname }} {{ user.surname }}</b></small
						>
						<span *ngIf="user.isAdmin" class="fa fa-star m--font-danger lp"></span>
						<ng-container *ngIf="enableSchemeExecutiveAndManagingAgent">
							<span *ngIf="user.isSchemeExecutive"
								><b class="m--font-info fa- lp">{{ config.managementTeamSchemeExecutiveIcon }}</b></span
							>
							<span *ngIf="user.isExecutiveManagingAgent"
								><b class="m--font-info fa- lp">{{ config.managementTeamExecutiveManagingAgentIcon }}</b></span
							>
						</ng-container>

						<ng-container *ngFor="let managementTeamAddition of config?.managementTeamAdditions">
							<span *ngIf="user[managementTeamAddition.profile]"
								><b class="m--font-info fa- lp">{{ managementTeamAddition.icon }}</b></span
							>
						</ng-container>

						<span *ngIf="user.isViewOnly" class="la la-eye m--font-success lp"></span>
						<span *ngIf="user.receiveUserUpdates" class="la la-bell m--font-warning lp"></span>
						<!-- <span *ngIf="user.online" class="la la-power-off m--font-metal lp"></span> -->
						<br />
						<div *ngFor="let profile of user?.profiles; let i = index">
							<span>
								<small>*{{ startCase(profile) }}</small>
							</span>
						</div>
						<span *ngIf="user?.profiles?.length === 0">
							<small class="no-profiles">[ has no profiles ]</small>
						</span>
					</div>
					<div class="col-xl-2">
						<div class="dropdown">
							<!-- <a aria-expanded="false" aria-haspopup="true" class="btn  btn-outline-info m-btn m-btn--icon btn-sm m-btn--icon-only"
                                href="#" ng-reflect-href="#" data-toggle="dropdown" id="dropdownMenuButton">
                                <i class="fa fa-angle-down"></i>
                            </a> -->
							<a
								class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only btn-sm m-btn--pill m-dropdown__toggle"
								data-toggle="dropdown"
								id="dropdownMenuButton"
							>
								<i class="la la-angle-down"></i>
							</a>
							<div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
								<a class="dropdown-item" routerLink="/{{ entityId }}/users/edit/{{ user.id }}">
									<i class="la la-edit"></i>
									View User
								</a>
								<a class="dropdown-item" href="#" ng-reflect-href="#" (click)="emailManager(user.email)">
									<i class="la la-envelope"></i>
									Email User
								</a>
								<a *ngIf="hasUsersCells" class="dropdown-item" href="#" ng-reflect-href="#" (click)="smsManager(user.cell)">
									<i class="la la-mobile"></i>
									SMS User
								</a>
								<hr *ngxPermissionsOnly="['admin', 'editEntityManagers']" />
								<ng-template [ngxPermissionsOnly]="['admin', 'editEntityManagers']">
									<a
										*ngIf="user.isAdmin && canRemoveAdmin"
										class="dropdown-item m--font-danger"
										(click)="setProfile(user, 'isAdmin', false)"
										style="cursor: pointer"
									>
										<i class="la la-times-circle m--font-danger"></i>
										Revoke Admin
									</a>

									<ng-container *ngFor="let managementTeamAddition of config?.managementTeamAdditions">
										<a
											*ngIf="user[managementTeamAddition.profile]"
											class="dropdown-item m--font-danger"
											(click)="setProfile(user, managementTeamAddition.profile, false)"
											style="cursor: pointer"
										>
											<i class="la la-times-circle m--font-danger"></i>
											Revoke {{ managementTeamAddition.text }}
										</a>
									</ng-container>

									<ng-container *ngIf="enableSchemeExecutiveAndManagingAgent">
										<a
											*ngIf="user.isSchemeExecutive"
											class="dropdown-item m--font-danger"
											(click)="setProfile(user, 'isSchemeExecutive', false)"
											style="cursor: pointer"
										>
											<i class="la la-times-circle m--font-danger"></i>
											Revoke {{ config.managementTeamSchemeExecutiveText }}
										</a>
									</ng-container>

									<!-- <a *ngIf="!user.isViewOnly && !user.isAdmin" class="dropdown-item m--font-danger" (click)="setProfile(user, 'isViewOnly', true)" style="cursor: pointer">
                                    <i class="la la-plus-circle m--font-danger"></i>
                                    Remove View Only
                                    </a> -->
									<a *ngIf="user.isViewOnly" class="dropdown-item m--font-danger" (click)="setProfile(user, 'isViewOnly', false)" style="cursor: pointer">
										<i class="la la-times-circle m--font-danger"></i>
										Revoke View Only
									</a>
									<a
										*ngIf="user.receiveUserUpdates"
										class="dropdown-item m--font-danger"
										(click)="setProfile(user, 'receiveUserUpdates', false)"
										style="cursor: pointer"
									>
										<i class="la la-times-circle m--font-danger"></i>
										Disable User updates
									</a>
									<hr />

									<ng-container *ngFor="let managementTeamAddition of config?.managementTeamAdditions">
										<a
											*ngIf="!user[managementTeamAddition.profile]"
											class="dropdown-item"
											(click)="setProfile(user, managementTeamAddition.profile, true)"
											style="cursor: pointer"
										>
											<b class="m--font-metal">{{ managementTeamAddition.icon }}</b>
											Make {{ managementTeamAddition.text }}
										</a>
									</ng-container>

									<a *ngIf="!user.isAdmin" class="dropdown-item" (click)="setProfile(user, 'isAdmin', true)" style="cursor: pointer">
										<i class="fa fa-star"></i>
										Make Admin
									</a>

									<ng-container *ngIf="enableSchemeExecutiveAndManagingAgent">
										<a *ngIf="!user.isSchemeExecutive" class="dropdown-item" (click)="setProfile(user, 'isSchemeExecutive', true)" style="cursor: pointer">
											<b class="m--font-metal">{{ config.managementTeamSchemeExecutiveIcon }}</b>
											Make {{ config.managementTeamSchemeExecutiveText }}
										</a>
									</ng-container>

									<a
										*ngIf="!user.isViewOnly && canRemoveAdmin && user.isAdmin"
										class="dropdown-item"
										(click)="setProfile(user, 'isViewOnly', true)"
										style="cursor: pointer"
									>
										<i class="la la-eye"></i>
										Make View Only
									</a>

									<a *ngIf="!user.receiveUserUpdates" class="dropdown-item" (click)="setProfile(user, 'receiveUserUpdates', true)" style="cursor: pointer">
										<i class="la la-bell"></i>
										Enable User Notifications
									</a>
									<hr />
									<a class="dropdown-item" (click)="showAddProfilesSection(user)" style="cursor: pointer">
										<i class="la la-plus"></i>
										Add *Profile
									</a>
									<a
										*ngIf="user?.profiles && user?.profiles?.length > 0"
										class="dropdown-item m--font-danger"
										(click)="showRemoveProfilesSection(user)"
										style="cursor: pointer"
									>
										<i class="la la-trash m--font-danger"></i>
										Remove *Profile
									</a>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</span>
		</span>
		<span *ngIf="showAddSection">
			<h5>Add Existing User</h5>
			<form [formGroup]="addExistingUserForm">
				<label
					>User
					<span class="m--font-danger">*</span>
				</label>
				<!-- <select class="form-control mb-3" id="existingUserID" name="existingUserID" ngModel required>
                    <option value="">--- Select User ---</option>
                    <option *ngFor="let existingUser of existingUsersList; let i = index" value="{{ i }}"> {{
                        existingUser.firstname }} {{ existingUser.surname }}</option>
                </select> -->
				<!-- <ng-select class="custom" id="existingUserID" name="existingUserID" formControlName="existingUserID" required>
                        <option *ngFor="let existingUser of existingUsersList; let i = index" [value]="existingUser.uid">{{
                             existingUser.firstname }} {{ existingUser.surname }}
                        </option>
                    </ng-select> -->

				<ng-select
					class="custom"
					[items]="existingUsersList"
					bindLabel="fullName"
					bindValue="id"
					placeholder="Select a user"
					name="existingUserID"
					required
					formControlName="existingUserID"
				>
				</ng-select>

				<div class="form-group m-form__group">
					<label class="mt-2">
						Type
						<span *ngIf="envType !== 'whitfields'" class="m--font-danger">*</span>
					</label>
					<ng-select
						class="custom"
						[items]="types"
						bindLabel="name"
						bindValue="value"
						placeholder="Select a type"
						name="existingUserType"
						formControlName="existingUserType"
					>
					</ng-select>
				</div>

				<div *ngIf="otherSelected">
					Enter Other Type
					<span class="m--font-danger">*</span>
					<input
						class="form-control mb-3"
						placeholder="Enter Other Type"
						id="existingUserOtherType"
						name="existingUserOtherType"
						formControlName="existingUserOtherType"
					/>
				</div>
				<ng-container *ngIf="envType !== 'whitfields'">
					<div class="checkbox">
						<input type="checkbox" id="existingUserAdmin" name="existingUserAdmin" formControlName="existingUserAdmin" (change)="SetDisabled('admin')" />
						Admin
					</div>
					<div class="checkbox mb-3">
						<input type="checkbox" id="existingUserViewOnly" name="existingUserViewOnly" formControlName="existingUserViewOnly" (change)="SetDisabled('view')" />
						View Only
					</div>
				</ng-container>
				<button *ngIf="!addExistingLoader" class="btn btn-brand btn-sm" (click)="addExistsingUser(addExistingUserForm.value)" [disabled]="!addExistingUserForm.valid">
					Add User
				</button>
				<button *ngIf="addExistingLoader" disabled class="btn btn-info btn-sm m-loader m-loader--light m-loader--right" ng-reflect-href="#">Adding</button>
			</form>
			<hr />
			<form [formGroup]="addNewUserForm">
				<h5>Invite User</h5>
				<label
					>Name
					<span class="m--font-danger">*</span>
				</label>
				<input class="form-control mb-3" placeholder="Enter Name" id="newUserFirstname" name="newUserFirstname" required formControlName="newUserFirstname" />
				<label
					>Surname
					<span class="m--font-danger">*</span>
				</label>
				<input class="form-control mb-3" placeholder="Enter Surname" id="newUserSurname" name="newUserSurname" required formControlName="newUserSurname" />
				<label
					>Email
					<span class="m--font-danger">*</span>
				</label>
				<input class="form-control mb-3" placeholder="Enter Email" id="newUserEmail" name="newUserEmail" email required formControlName="newUserEmail" />
				<label
					>Type
					<span *ngIf="envType !== 'whitfields'" class="m--font-danger">*</span>
				</label>
				<select class="form-control mb-3" id="newUserType" name="newUserType" formControlName="newUserType" required>
					<option value="" selected="selected">--- Select Type ---</option>
					<option *ngFor="let option of types" [value]="option?.value">{{ option?.name }}</option>
				</select>
				<div *ngIf="otherNewSelected">
					Enter Other Type
					<span class="m--font-danger">*</span>
					<input class="form-control mb-3" placeholder="Enter Other type" id="newUserOtherType" formControlName="newUserOtherType" />
				</div>
				<ng-container *ngIf="envType !== 'whitfields'">
					<div class="checkbox">
						<input type="checkbox" id="newUserAdmin" name="newUserAdmin" formControlName="newUserAdmin" (change)="SetDisabled('newadmin')" />
						Admin
					</div>
					<div class="checkbox mb-3">
						<input type="checkbox" id="newUserViewOnly" name="newUserViewOnly" formControlName="newUserViewOnly" (change)="SetDisabled('newview')" />
						View Only
					</div>
				</ng-container>
				<button *ngIf="!inviteLoader" class="btn btn-brand btn-sm" (click)="addNewUser(addNewUserForm.value)" [disabled]="!addNewUserForm.valid">Invite User</button>
				<button *ngIf="inviteLoader" disabled class="btn btn-info btn-sm m-loader m-loader--light m-loader--right" ng-reflect-href="#">Inviting</button>
			</form>
			<hr />
			<div>
				<button type="button" class="btn btn-default btn-sm mt-2 mb-3" (click)="addSectionToggle()">Cancel</button>
			</div>
		</span>
		<span *ngIf="addingSpinner">
			<h5 class="text-center">
				Adding
				<span class="fa fa-spinner fa-spin"></span>
			</h5>
		</span>
		<span *ngIf="showAddProfileSection" class="mt-3">
			<h6>Add Profile to {{ user.firstname }} {{ user.surname }}</h6>
			<form [formGroup]="addProfileForm">
				<hr />
				<div class="form-group m-form__group">
					<label>
						Type
						<span class="m--font-danger">*</span>
					</label>
					<select class="form-control" id="addProfileType" name="addProfileType" formControlName="addProfileType" required>
						<option value="">--- Select Type ---</option>
						<option *ngFor="let option of types" [value]="option?.value">{{ option?.name }}</option>
					</select>
				</div>

				<div *ngIf="addProfileOtherSelected" class="form-group m-form__group">
					<label for="exampleInputEmail1">Specify Profile Type <span class="m--font-danger">*</span></label>
					<input
						id="addProfileTypeOther"
						name="addProfileTypeOther"
						maxlength="35"
						formControlName="addProfileTypeOther"
						type="text"
						class="form-control m-input"
						placeholder="Enter Other Type"
					/>
				</div>

				<button
					*ngIf="!addProfileOtherSelected"
					class="btn btn-sm btn-outline-info btn-hover-text"
					(click)="addProfile(addProfileForm.value)"
					[disabled]="!addProfileForm.valid"
				>
					<i class="la la-plus"></i> Add Profile
				</button>

				<button
					*ngIf="addProfileOtherSelected"
					class="btn btn-sm btn-outline-info btn-hover-text"
					(click)="addProfile(addProfileForm.value)"
					[disabled]="!addProfileForm.valid || addProfileForm.controls['addProfileTypeOther'].value === ''"
				>
					<i class="la la-plus"></i> Add Profile
				</button>
			</form>
			<hr />
			<div class="col-md-12">
				<button type="button" class="btn btn-default btn-sm" (click)="hideAddProfileSection()">Cancel</button>
			</div>
		</span>
		<span *ngIf="showRemoveProfileSection">
			<h6>Remove Profile</h6>
			<div class="m-list-timeline" *ngFor="let profile of user?.profiles; let i = index">
				<div class="m-list-timeline__items">
					<div class="m-list-timeline__item">
						<span class="m-list-timeline__badge"></span>
						<span class="m-list-timeline__text">
							<small
								><b>{{ user.firstname }} {{ user.surname }}</b
								>&nbsp;<br />
								{{ profile }}
							</small>
						</span>
						<a class="m-list-timeline__icon m--font-danger" title="Remove Profile" (click)="removeProfile(user, i)" style="cursor: pointer">
							<i class="fa fa-times"></i>
						</a>
					</div>
				</div>
			</div>
			<hr />
			<button type="button" class="btn btn-default" (click)="hideRemoveProfileSection()">Close</button>
		</span>
		<hr />
		<div class="col-md-12 pl-0">
			<small>
				<span class="m--font-danger" style="padding-right: 3%"> <span class="fa fa-star"></span> Admin </span>
				<ng-container *ngIf="enableSchemeExecutiveAndManagingAgent">
					<br />
					<span class="m--font-info" style="padding-right: 3%">
						<b>{{ config.managementTeamSchemeExecutiveIcon }}</b> {{ config.managementTeamSchemeExecutiveText }}
					</span>
					<br />
					<span class="m--font-info" style="padding-right: 3%">
						<b>{{ config.managementTeamExecutiveManagingAgentIcon }}</b> {{ config.managementTeamExecutiveManagingAgentText }}
					</span>
				</ng-container>

				<ng-container *ngFor="let managementTeamAddition of config?.managementTeamAdditions">
					<br />
					<span class="m--font-info" style="padding-right: 3%">
						<b>{{ managementTeamAddition.icon }}</b> {{ managementTeamAddition.text }}
					</span>
				</ng-container>

				<br />
				<span class="m--font-success"> <span class="la la-eye"></span> View Only </span>
				<br />
				<span class="m--font-warning" style="padding-right: 3%"> <span class="la la-bell"></span> Receive User Notifications </span>
				<!-- <br>
                <span class="m--font-metal">
                    <span class="la la-power-off"></span> User Online
                </span> -->
			</small>
		</div>
	</div>
</div>
