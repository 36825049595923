import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { FirebaseApp } from '@angular/fire';
import { Family } from './famalies.model';
import { Member } from '../members/member.model';
import { User } from '../../plex/users/user.model';
import { AuditLogService } from '../../plex/audit-log/audit-log.service';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare let toastr: any;

@Injectable()
export class FamiliesService {
	entityMembersCollection: AngularFirestoreCollection<Member[]>;
	memberDoc: AngularFirestoreDocument<Member>;
	familyDoc: AngularFirestoreDocument<Family>;
	usersCollection: AngularFirestoreCollection<User[]>;
	entityId: string;

	constructor(public afs: AngularFirestore, public router: Router, public fb: FirebaseApp, private auditLogService: AuditLogService, private store: Store) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	// TEAMS

	fetchEntityFamilies() {
		const familiesRef = this.afs.collection(`entities/${this.entityId}/families`, ref => ref.where('active', '==', true).orderBy('name', 'asc'));

		return familiesRef.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Family;
					data.uid = a.payload.doc.id;
					return data;
				});
			})
		);
	}

	fetchFamily(familyId: string) {
		return this.afs.doc(`entities/${this.entityId}/families/${familyId}`).valueChanges();
	}

	fetchFamilyMembers(familyId) {
		return this.afs.collection(`entities/${this.entityId}/families/${familyId}/members`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'uid' });
	}

	fetchFamilyMembersList(memberId) {
		return this.afs.doc(`entities/${this.entityId}/members/${memberId}`).valueChanges();
	}

	fetchAllMembers() {
		return this.afs
			.collection(`entities/${this.entityId}/members`, ref => ref.where('active', '==', true))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data: any = a.payload.doc.data();
						data.uid = a.payload.doc.id;
						data.fullName = data.firstname + ' ' + data.lastname;
						return data;
					});
				})
			);
	}

	addFamily(family: Family, memberId) {
		if (memberId != '') {
			return this.validationHandler(family, memberId).then(result => {
				return result;
			});
		} else {
			family.active = true;
			family.created = Date.now();
			return this.afs
				.collection(`entities/${this.entityId}/families`)
				.add(family)
				.then(result => {
					return result;
				});
		}
	}

	updateFamily(family: Family, familyId) {
		this.familyDoc = this.afs.doc(`entities/${this.entityId}/families/${familyId}`);

		return this.familyDoc.update(family);
	}

	addMemberToFamily(familyName: string, familyId: string, memberId: string, member) {
		return this.afs
			.collection(`entities/${this.entityId}/members`)
			.doc(`${memberId}`)
			.collection('families')
			.doc(familyId)
			.set({ uid: familyId, active: true }, { merge: true })
			.then(() => {
				return this.afs.collection(`entities/${this.entityId}/families/${familyId}/members`).doc(`${memberId}`).set(
					{
						active: true,
						memberId: memberId,
						familyId: familyId,
						firstname: member.firstname,
						lastname: member.lastname,
						memberTypes: member.memberTypes,
					},
					{ merge: true }
				);
			});
	}

	removeMemberFromFamily(familyId: string, memberId: string) {
		const memberDoc = this.afs.collection(`entities/${this.entityId}/members`).doc(`${memberId}/families/${familyId}`);
		const familyMemberDoc = this.afs.collection(`entities/${this.entityId}/families/${familyId}/members`).doc(`${memberId}`);
		return memberDoc.set({ active: false }, { merge: true }).then(() => {
			return familyMemberDoc.set({ active: false }, { merge: true });
		});
	}

	handleAddWithMember(memberId) {}

	validationHandler(handleData, memberId) {
		const fetchFamilies = () =>
			new Promise(resolve => {
				this.fetchEntityFamilies().subscribe(familiesData => {
					let tmpFamiliesArray = familiesData.map(family => {
						return family;
					});
					resolve(tmpFamiliesArray);
				});
			});

		const handleCombineRelated = (familiesData, getData, memberID) =>
			new Promise((resolve, reject) => {
				let tmpFamiliesData = familiesData;
				let formData = {
					active: true,
					created: Date.now(),
					name: getData.name,
				};

				const familiesCollection = this.afs.collection(`entities/${this.entityId}/families`);
				formData.active = true;

				let tmpArr = tmpFamiliesData.filter(item => {
					return item.name == formData.name;
				});
				if (tmpArr.length > 0) {
					reject(`Family ${formData.name} already exists!`);
				} else {
					// PLAYER CAN BE ADDED
					if (memberID != '') {
						// HAS TEAM ID - FIND TEAM
						this.fetchFamilyMembersList(memberID)
							.pipe(take(1))
							.subscribe(memberData => {
								if (memberData != null) {
									// TEAM FOUND
									familiesCollection.add(formData).then(result => {
										let tmpFamilyId = result.id;
										const familiesMembersDoc = this.afs.doc(`entities/${this.entityId}/families/${tmpFamilyId}/members/${memberID}`);
										return familiesMembersDoc
											.set({
												active: true,
												memberId: memberID,
												familyId: tmpFamilyId,
											})
											.then(() => {
												const memberDoc = this.afs.doc(`entities/${this.entityId}/members/${memberID}/families/${tmpFamilyId}`);
												memberDoc
													.set(
														{
															active: true,
															name: getData.name,
															uid: tmpFamilyId,
														},
														{ merge: true }
													)
													.then(() => {
														this.router.navigate([`/${this.entityId}/faith/members/edit/${memberID}`]);
														resolve('Family added to member');
													});
											});
									});
								} else {
									// TEAM NOT FOUND - REJECT
									reject(`Member ${memberID} was not found!`);
								}
							});
					} else {
						familiesCollection.add(formData).then(result => {
							resolve(result);
						});
					}
				}
			});

		const getResults = async (getData, memberId) => {
			let familiesData = await fetchFamilies();
			return handleCombineRelated(familiesData, getData, memberId);
		};

		return getResults(handleData, memberId)
			.then(result => {
				return result;
			})
			.catch(err => {
				toastr.error(err);
				return err;
			});
	}

	updateFamilyMember(familyId: string, member: Member) {
		const familyMember = this.afs.doc(`/entities/${this.entityId}/families/${familyId}/members/${member.uid}`);
		return familyMember.update(member);
	}

	setFamilyInactive(family: Family) {
		const familyRef = this.afs.doc(`/entities/${this.entityId}/families/${family.id}`);

		const updateFamily = familyRef.update({
			active: false,
		});

		return Promise.all([updateFamily]).then(() => {
			toastr.success('Family removed successfully!');
			let logData = {
				name: family.name,
				type: 'remove',
				description: 'Family was removed',
				category: 'families',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}
}
