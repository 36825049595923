<div class="row">
	<div class="col">
		<form [formGroup]="searchForm" (ngSubmit)="search()" class="m-form m-form--fit m-form--label-align-right">
			<div class="form-group">
				<input class="form-control form-control-sm m-input" placeholder="Search by complex..." formControlName="schemePrefix" />
			</div>

			<div class="form-group pt-3">
				<input class="form-control form-control-sm m-input" placeholder="and/or search by account number..." formControlName="account_number" />
			</div>
			<div class="form-group pt-3">
				<input class="form-control form-control-sm m-input" placeholder="and/or search by email..." formControlName="email" />
			</div>
			<div class="form-group pt-3">
				<input class="form-control form-control-sm m-input" placeholder="and/or search by service provider..." formControlName="provider" />
			</div>

			<div class="pt-2">
				<div class="row">
					<div class="col-md-4">
						<button *ngIf="source !== 'management'" class="btn btn-outline-brand btn-sm m-btn" [disabled]="isSearching" routerLink="/{{ entityId }}/management/search">
							Advanced
						</button>
					</div>
					<div class="col-md-8 text-right">
						<button
							*ngIf="accountsResults?.length > 0 || userResults?.length > 0 || results?.length > 0"
							class="btn btn-secondary btn-sm m-btn"
							[disabled]="isSearching"
							(click)="clear()"
							style="margin-right: 3%"
						>
							Clear
						</button>
						<button
							*ngIf="!isSearching"
							[disabled]="searchForm.value.schemePrefix === '' && searchForm.value.account_number === '' && searchForm.value.email === ''"
							type="submit"
							class="btn btn-brand btn-sm m-btn"
						>
							Find
						</button>
						<button *ngIf="isSearching" type="button" disabled class="btn btn-brand btn-sm m-btn btn-block m-loader m-loader--light m-loader--right">
							Searching...
						</button>
					</div>
				</div>
			</div>
		</form>
		<hr />
		<div class="text-center" *ngIf="searchResult">{{ results?.length }} result found!</div>
		<perfect-scrollbar style="max-height: 400px" [config]="scrollConfig">
			<div *ngIf="results">
				<div *ngFor="let entity of results">
					<hr />
					<div class="row">
						<div class="col">
							<label class="m--font-bolder">
								<a class="m-link m--font-bolder" style="cursor: pointer" (click)="viewEntity(entity)">
									{{ entity?.name }}
								</a>
							</label>
						</div>
					</div>
					<hr />
				</div>
			</div>

			<div *ngIf="userResults?.length > 0">
				<div *ngFor="let results of userResults">
					<div class="row">
						<div class="col">
							<label class="m--font-bolder">{{ results?.entityData?.name }}</label
							><br />
							<label>
								<a class="m-link" (click)="goToSelected(results?.entityId, 'user', results?.user?.id, results?.entityData)">{{ results?.user?.email }}</a>
								<!-- <small>
                  <div *ngFor="let account of accounts">
                    - {{account?.name | uppercase}}
                  </div>
                </small> --> </label
							><br />
							<div *ngIf="results?.error">
								<label><b class="text-danger">User is not part of this scheme</b></label>
							</div>
						</div>
					</div>
					<hr />
				</div>
			</div>

			<div *ngIf="providerResults?.length > 0">
				<div *ngFor="let results of providerResults">
					<div class="row">
						<div class="col">
							<label>
								<a class="m-link" (click)="goToSelected(null, 'provider', results?.id, results)">{{ results?.name }}</a>
							</label>
						</div>
					</div>
					<hr />
				</div>
			</div>

			<div *ngIf="accountsResults?.length > 0">
				<label class="m--font-bolder">{{ accountsResults[0]?.entityName }}</label>
				<div class="row">
					<div class="col">
						<div *ngFor="let account of accountsResults">
							<label>
								<a class="m-link" (click)="goToSelected(account?.entityId, 'account', account?.id)"> - {{ account?.name | uppercase }} </a>
							</label>
						</div>
					</div>
				</div>
				<hr />
			</div>
		</perfect-scrollbar>
	</div>
</div>
