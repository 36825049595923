import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Notice } from '../../notices/notices.model';
import { User } from '../../users/user.model';
import { AuthenticationService } from '../../../../../../auth/_services/authentication.service';
import { map } from 'rxjs/operators';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class NotificationService {
	loggedInUser: string;
	notificationDocument: AngularFirestoreDocument<any>;
	notificationDoc: AngularFirestoreDocument<Notification[]>;
	noticesCollection: AngularFirestoreCollection<Notice[]>;
	authSubscription: Subscription;
	entityId: any;

	constructor(public afs: AngularFirestore, private auth: AuthenticationService, public router: Router, private store: Store) {
		// Get Logged in User
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		this.loggedInUser = sessionStorage.getItem('userId');
	}

	//ADD NOTICE
	addLastSeenDate() {
		//GET SCHEME ID

		//GET NOTICE COLLECTION
		this.notificationDocument = this.afs.doc(`users/${this.loggedInUser}/entities/${this.entityId}/notifications/lastReadDate`);

		//ADD NOTICE
		this.notificationDocument
			.set({ date: Date.now() })
			.then(() => {
				// console.log('User has read notifications');
			})
			.catch(error => {
				console.log('Error updating read notifications');
			});
	}

	fetchLastSeenDate() {
		//GET SCHEME ID

		//GET NOTICE COLLECTION
		this.notificationDoc = this.afs.doc(`users/${this.loggedInUser}/entities/${this.entityId}/notifications/lastReadDate`);

		return this.notificationDoc.valueChanges();
	}

	fetchAllNotices(limitCount) {
		//GET SCHEME ID

		//GET NOTICES COLLECTION
		this.noticesCollection = this.afs.collection(`entities/${this.entityId}/communications/notices/list`, ref =>
			ref.where('active', '==', true).limit(limitCount).orderBy('created', 'desc')
		);

		return this.noticesCollection.valueChanges({ idField: 'uid' });
	}

	fetchLatestNotices(lastReadDate) {
		//GET SCHEME ID

		//GET NOTICES COLLECTION
		this.noticesCollection = this.afs.collection(`entities/${this.entityId}/communications/notices/list`, ref =>
			ref.where('active', '==', true).where('created', '>', lastReadDate.date).orderBy('created', 'desc')
		);

		return this.noticesCollection.valueChanges({ idField: 'uid' });
	}
}
