import { Injectable } from '@angular/core';

declare const $: any;
declare const mUtil: any;

@Injectable()
export class InitService {
	constructor() {}

	public initMDropdowns = (selectors: Array<string>) =>
		selectors.forEach(selector => {
			setTimeout(() => $(`#${selector}`).selectpicker('refresh'), 0);
		});

	public initClassMDropdowns = (selectors: Array<string>) =>
		selectors.forEach(selector => {
			setTimeout(() => $(`.${selector}`).selectpicker('refresh'), 0);
		});

	public initMDatePickers(pickers: Array<string>, callback: (picker: string, date: Date) => void, helperBtns?: boolean) {
		pickers.forEach(picker => {
			const isRTL = mUtil.isRTL();

			const t = {
				leftArrow: `<i class="la la-angle-${isRTL ? 'right' : 'left'}"></i>`,
				rightArrow: `<i class="la la-angle-${isRTL ? 'left' : 'right'}"></i>`,
			};

			setTimeout(() => {
				$(`#${picker}`).datepicker({
					format: 'yyyy/mm/dd',
					rtl: mUtil.isRTL(),
					todayBtn: 'linked',
					clearBtn: false,
					todayHighlight: false,
					templates: t,
				});

				$(`#${picker}`).on('changeDate', ({ date }) => callback(picker, date));
			}, 0);
		});
	}

	public initOptGroups(optGroups: Array<string>, data: any, callback: (val: any) => void, name: string = 'an item') {
		optGroups.forEach(optGroup => {
			setTimeout(
				() =>
					$(`#${optGroup}`).select2({
						data,
						tokenSeparators: [','],
						placeholder: ` Type ${name} and press enter, or pick one...`,
						tags: true,
						width: '100%',
					}),
				0
			);

			$(`#${optGroup}`).on('change', ({ target: { value } }: Event & { target: HTMLInputElement }) => callback(value));
		});
	}
}
