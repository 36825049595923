import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SchemesService } from '../theme/pages/default/management/entities/entities.service';
import { Store } from '@ngrx/store';
import { selectEntityId } from '../_state/entity/entity.selectors';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class EntityResolverService implements Resolve<any> {
	constructor(private schemesService: SchemesService, private router: Router, private store: Store) {}
	resolve(route: ActivatedRouteSnapshot) {
		const entityId$ = this.store.select(selectEntityId).pipe(take(1));

		return entityId$
			.toPromise()
			.then(id => {
				let entityId = id;

				if (environment.product === 'olympus') {
					entityId = 'olympus';
				}

				return this.schemesService.setEntityId(entityId).catch(err => {
					this.router.navigate(['entities']);
				});
			})
			.catch(err => {
				this.router.navigate(['entities']);
			});
	}
}
