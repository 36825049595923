<app-header-nav [entity]="entity" [entityId]="entityId"></app-header-nav>

<!-- begin::Body -->
<router-outlet></router-outlet>
<!-- end:: Body -->
<div class="modal fade" id="createTaskModal" tabindex="-1" role="dialog" aria-labelledby="createTaskModal" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		<div class="modal-content">
			<span *ngIf="createTask">
				<app-tasks-create [entityId]="entityId"></app-tasks-create>
			</span>
		</div>
	</div>
</div>
<app-footer></app-footer>
