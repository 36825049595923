import { Injectable } from '@angular/core';
import firebase from 'firebase';

import { WhitfieldsCallLampyPayload, LampyPayloadDate, LampyGeneralLedgerPayload, SchemeBalancesData, TrialBalanceData } from './whitfields-call-lampy-payload.model';
import { SchemeAccountBalanceData, AccountTransactionsData, SchemeAccountsData } from './whitfields-call-lampy-data.model';
import { environment } from '@environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
	providedIn: 'root',
})
export class WhitfieldsCallLampyService {
	private env: string;

	constructor(private functions: AngularFireFunctions) {
		const isDev = environment.firebase.projectId.toLowerCase().includes('dev');
		this.env = isDev ? 'dev' : 'live';
	}

	private httpsCallableWhitfieldsCallLampy = firebase.functions().httpsCallable('whitfields-callLampy');

	private async whitfieldsCallLampy<T>(functionName: string, payload: WhitfieldsCallLampyPayload) {
		const httpsCallableResult = await this.httpsCallableWhitfieldsCallLampy({
			payload,
			functionName,
		});

		if (httpsCallableResult) {
			if (httpsCallableResult.data == null) {
				console.error({ httpsCallableResult });
				console.error({ payload });
				throw new Error(`Endpoint ${functionName} unreachable.`);
			}
		}

		return httpsCallableResult.data as T;
	}

	public async getLampyFireflySchemeAccountSummary(accountNumber, startDate, endDate) {
		const accountBalance = await this.whitfieldsCallLampy<any>(`lampy-ff-${this.env}-getAccountSummaryReport`, {
			account: accountNumber || 'FIREFLY00001',
			startDate: startDate || '2022-07-22',
			endDate: endDate || '2022-08-21',
			accountType: 'debtors',
		});
		return accountBalance;
	}

	/**
	 * Get an account's balances from Lampy using httpsCallable cloud function whitfieldsCallLampy.
	 *
	 * @param account - Account to get transactions for. whitfieldsPrefix/accountNumber e.g. AMV/8400, VTY/8400.
	 * @param endDate - Formatted 'YYYY-MM-DD'.
	 * @returns Balances for a scheme account.
	 */
	public getLampySchemeAccountBalance(account: string, endDate: LampyPayloadDate, accountType?): Promise<SchemeAccountBalanceData> {
		return this.whitfieldsCallLampy<SchemeAccountBalanceData>(`lampy-ff-${this.env}-schemeAccountBalance`, {
			endDate,
			account,
			accountType: accountType || 'postgl',
		});
	}

	/**
	 * Get an account's transactions from Lampy using httpsCallable cloud function whitfieldsCallLampy.
	 *
	 * @param account - Account to get transactions for. whitfieldsPrefix/accountNumber e.g. AMV/8400, VTY/8400.
	 * @param startDate - Formatted 'YYYY-MM-DD'.
	 * @param endDate - Formatted 'YYYY-MM-DD'.
	 * @returns Specific account's transactions.
	 */
	public getLampyAccountTransactions(account: string, startDate: LampyPayloadDate, endDate: LampyPayloadDate): Promise<AccountTransactionsData> {
		return this.whitfieldsCallLampy<AccountTransactionsData>(`lampy-ff-${this.env}-schemeAccountTransactions`, {
			account,
			startDate,
			endDate,
			accountType: 'postgl',
		});
	}

	/**
	 * Get a scheme's accounts from Lampy using httpsCallable cloud function whitfieldsCallLampy.
	 *
	 * @param scheme - whitfieldsPrefix to get accounts for.
	 * @returns Array of acccounts.
	 */
	public async getLampyAccounts(scheme: string, isFirefly?: boolean): Promise<SchemeAccountsData> {
		const project = isFirefly ? 'ff' : 'wod';
		return this.whitfieldsCallLampy<SchemeAccountsData>(`lampy-${project}-${this.env}-schemeAccounts`, {
			scheme,
			accountType: 'postgl',
		});
	}

	/**
	 * Get an account's transactions from Lampy using httpsCallable cloud function whitfieldsCallLampy.
	 * @param entityId
	 * @param account - Account to get transactions for. whitfieldsPrefix/accountNumber e.g. AMV/8400, VTY/8400.
	 * @param startDate - Formatted 'YYYY-MM-DD'.
	 * @param endDate - Formatted 'YYYY-MM-DD'.
	 * @returns Specific account's transactions.
	 */
	public async getLampyAccountStatement(data): Promise<any> {
		// return Promise.resolve();
		if (data.type === 'email') {
			const { entityId, accountName, accountId, startDate, endDate, type, statementType } = data;
			const result = await this.functions
				.httpsCallable<any>('whitfields-statementDataHandler')({
					entityId,
					accountName,
					accountId,
					startDate,
					endDate,
					type,
					url: `lampy-ff-${this.env}-handleFireflyStatement`,
					statementType,
				})
				.toPromise();

			if (result) {
				return Promise.resolve(result);
			} else {
				return Promise.reject('No statement entries found for the selected time frame.');
			}
		} else {
			const { name, startDate, endDate, statementType } = data;
			return this.whitfieldsCallLampy<any>(`lampy-ff-${this.env}-accountStatementGet`, {
				account: name,
				startDate,
				endDate,
				accountType: 'debtors',
				statementType,
			});
		}
	}

	public getLampyGeneralLedger(accounts, startDate, endDate) {
		return this.whitfieldsCallLampy<LampyGeneralLedgerPayload>(`lampy-ff-${this.env}-fireflyGeneralLedgerReport`, {
			accounts,
			startDate,
			endDate,
			accountType: 'postgl',
		});
	}

	public getLampySchemeBalances(prefix, accounts: string, endDate: LampyPayloadDate, buffer, amountBuffer, overdraft): Promise<any> {
		return this.whitfieldsCallLampy<SchemeBalancesData>(`lampy-ff-${this.env}-getCurrentBalances`, {
			prefix,
			accounts,
			endDate,
			buffer,
			amountBuffer,
			overdraft,
			accountType: 'opengl',
		});
	}

	getLampyTrialBalance(prefix: string, date: string, financialYearStart: string) {
		return this.whitfieldsCallLampy<TrialBalanceData>(`lampy-ff-${this.env}-fireflyTrialBalanceReport`, {
			prefix,
			date,
			financialYearStart,
		});
	}
}
