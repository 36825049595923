import { ChipsModule } from 'primeng/chips';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppCoreComponent } from './app-core.component';
import { CommunicationsService } from '../communications/communications.service';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AgmCoreModule } from '@agm/core';
import { NgPipesModule } from 'ngx-pipes';

import { NotesModule } from '../notes/notes.module';
import { UsersService } from '../users/users.service';
import { FilteringPipe } from '../components/pipes/filtering.pipe';
import { KeysPipe } from '../components/pipes/keys.pipe';
import { DocsService } from '../documents/services/docs.service';
import { EntitiesService } from '../entities/entities.service';
import { PropertiesService } from '../properties/properties.service';
import { NotificationsModule } from '../components/notifications/notifications.module';
import { RegisteredOwnersService } from '../registered-owners/registered-owners.service';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { AuditLogService } from '../audit-log/audit-log.service';
import { TableModule } from 'primeng/table';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { TasksService } from '../tasks/tasks.service';
import { NewsArticlesService } from '../news-articles/services/news-articles.service';
import { NotesService } from '../notes/notes.service';
import { FilesService } from '../components/files/files.service';
import { SortablejsModule } from 'ngx-sortablejs';
import { CustomFieldsService } from '../components/custom-fields/custom-fields.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TasksCreateComponent } from '../tasks/tasks-create/tasks-create.component';
import { DataService } from '../../../../../_services/data.service';
import { IntroStepsService } from './../../../../../_services/intro-steps.service';
import { EntityAccountsModule } from '../components/entity-accounts/entity-accounts.module';
import { FinService } from '../fin/services/fin.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule } from 'primeng/editor';
import { PlayersService } from '../../sports/players/players.service';
import { TeamsService } from '../../sports/teams/teams.service';
import { MembersService } from '../../faith/members/members.service';
import { GroupsService } from '../../faith/groups/groups.service';
import { FamiliesService } from '../../faith/families/families.service';
import { FfPropertiesService } from '../properties/ff-properties.service';
import { AdvancedSearchService } from '../../management/components/advanced-search/advanced-search.service';
import { environment } from '../../../../../../../src/environments/environment';
import { JournalsService } from '../../management/journals/journals.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { FieldsetModule } from 'primeng/fieldset';
import { ChartModule } from 'primeng/chart';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { TreeModule } from 'primeng/tree';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AngularDokaModule } from 'angular-doka';
import { ServeEntitiesAddComponent } from '../../serve/components/serve-entities-add/serve-entities-add.component';
import { ManagementServiceProvidersService } from '../../management/service-providers/service-providers.service';
import { MeetingsService } from '../../meetings/meetings.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelPropagation: true,
};

@NgModule({
	imports: [
		CommonModule,
		//LayoutModule,
		HttpClientModule,
		ImageCropperModule,
		AgmCoreModule.forRoot({
			apiKey: environment.googleMapAPI,
			libraries: ['places'],
		}),
		RouterModule,
		FormsModule,
		NgxPermissionsModule,
		ReactiveFormsModule,
		ChipsModule,
		CodeHighlighterModule,
		ColorPickerModule,
		CheckboxModule,
		MenuModule,
		FieldsetModule,
		AccordionModule,
		ChartModule,
		ButtonModule,
		InputTextModule,
		PaginatorModule,
		TreeModule,
		DropdownModule,
		CalendarModule,
		InputSwitchModule,
		SelectButtonModule,
		CodeHighlighterModule,
		MultiSelectModule,
		PanelModule,
		RadioButtonModule,
		SplitButtonModule,
		TabViewModule,
		InputMaskModule,
		NgxPermissionsModule,
		NotesModule,
		NotificationsModule,
		InternationalPhoneNumberModule,
		PerfectScrollbarModule,
		KeyFilterModule,
		TableModule,
		EditorModule,
		NgPipesModule,
		SortablejsModule.forRoot({ animation: 150 }),
		NgbModule,
		EntityAccountsModule,
		NgSelectModule,
		AngularDokaModule,
	],
	declarations: [AppCoreComponent, TasksCreateComponent, FilteringPipe, KeysPipe, ServeEntitiesAddComponent],
	exports: [
		CommonModule,
		//LayoutModule,
		NgxPermissionsModule,
		HttpClientModule,
		ImageCropperModule,
		AgmCoreModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		ChipsModule,
		ColorPickerModule,
		CheckboxModule,
		MenuModule,
		FieldsetModule,
		AccordionModule,
		ChartModule,
		ButtonModule,
		InputTextModule,
		PaginatorModule,
		TreeModule,
		DropdownModule,
		CalendarModule,
		InputSwitchModule,
		SelectButtonModule,
		CodeHighlighterModule,
		MultiSelectModule,
		PanelModule,
		RadioButtonModule,
		SplitButtonModule,
		TabViewModule,
		InputMaskModule,
		NotesModule,
		KeyFilterModule,
		TableModule,
		EditorModule,
		NotificationsModule,
		InternationalPhoneNumberModule,
		PerfectScrollbarModule,
		SortablejsModule,
		NgbModule,
		KeysPipe,
		TasksCreateComponent,
		EntityAccountsModule,
		NgSelectModule,
		AngularDokaModule,
		ServeEntitiesAddComponent,
	],
})
export class AppCoreModule {
	static forRoot(): ModuleWithProviders<AppCoreModule> {
		return {
			ngModule: AppCoreModule,
			providers: [
				Title,
				DatePipe,
				CommunicationsService,
				EntitiesService,
				PropertiesService,
				PlayersService,
				TeamsService,
				MembersService,
				GroupsService,
				FamiliesService,
				UsersService,
				IntroStepsService,
				DocsService,
				AuthenticationService,
				AuditLogService,
				NgxPermissionsService,
				CurrencyPipe,
				TasksService,
				NotesService,
				RegisteredOwnersService,
				FilesService,
				NewsArticlesService,
				CustomFieldsService,
				DataService,
				FinService,
				JournalsService,
				{
					provide: PERFECT_SCROLLBAR_CONFIG,
					useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
				},
				FfPropertiesService,
				AdvancedSearchService,
				ManagementServiceProvidersService,
				MeetingsService,
			],
		};
	}
}
