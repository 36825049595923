<!-- begin::Page loader -->
<!-- <link href="./assets/demo/demo3/base/style.bundle.css" rel="stylesheet" type="text/css" /> -->
<div class="m-page-loader m-page-loader--base m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px" id="loadingSpinner">
	<div class="m-blockui">
		<span>Please wait...</span>
		<span>
			<div class="m-loader m-loader--brand"></div>
		</span>
	</div>
</div>

<!-- end::Page loader -->

<!-- begin:: Page -->
<router-outlet></router-outlet>

<!-- end:: Page -->

<app-scroll-top></app-scroll-top>
<!-- <app-tooltips></app-tooltips> -->

<!--begin::Base Scripts -->

<!--end::Base Scripts -->

<!--begin::Page Vendors -->

<!--end::Page Vendors -->

<!--begin::Page Snippets -->

<!--end::Page Snippets -->
