import { Component, OnInit, OnDestroy } from '@angular/core';
import { SchemesService } from '../../entities/entities.service';
import { Entity } from '../../../plex/entities/entities.model';
import { DashboardsService } from '../dashboards.service';

import { environment } from '../../../../../../../environments/environment';
import { config } from '../../../../../../../../src/configs/configs';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { take } from 'rxjs/operators';
declare const $: any;
declare const mUtil: any;
@Component({
	selector: 'app-entity-dashboard',
	templateUrl: './entity-dashboard.component.html',
	styleUrls: ['./entity-dashboard.component.scss'],
})
export class EntityDashboardComponent implements OnInit, OnDestroy {
	config = config[environment.product];
	entity: Entity;
	entityDetailsSubscription: Subscription;
	usersCount = 0;
	propertiesCount = 0;
	subs = new Subscription();
	isAgeAnalysisView = false;
	AgeAnalysisData;
	isLoading = true;
	entityId: string;

	constructor(private schemesService: SchemesService, private dashboardService: DashboardsService, public _router: Router, private store: Store) {}

	ngOnInit() {
		this.subs.add(
			this.store
				.select(selectEntityId)
				.pipe(take(1))
				.subscribe(entityId => {
					this.entityId = entityId;
				})
		);

		this.fetchEntityDetails();

		this.isLoading = true;
		this.subs.add(
			this._router.events.subscribe(evt => {
				if (evt instanceof NavigationEnd) {
					this.isLoading = true;
					const newId = evt.url.split('/')[1];
					if (newId !== this.entityId) {
						this.entityId = newId;
					}
					this.fetchEntityDetails();
					// trick the Router into believing it's last link wasn't previously loaded
					this._router.navigated = false;
					// this.isLoading = false;
					// if you need to scroll back to top, here is the right place
					window.scrollTo(0, 0);
				}
			})
		);
	}

	fetchEntityDetails() {
		this.subs.add(
			this.schemesService.getEntityDetails(this.entityId).subscribe(entity => {
				this.entity = entity;
				this.isLoading = false;
				this.subs.add(
					this.dashboardService.getPropertyCounts().subscribe(data => {
						this.propertiesCount = data.length;
					})
				);
				this.subs.add(
					this.dashboardService.getUserCounts().subscribe(data => {
						this.usersCount = data.length;
					})
				);
			})
		);
	}

	public isTableView(e: boolean): void {
		if (e) {
			$('#m_age_analysis').modal({ backdrop: 'static', keyboard: false });
			$('#m_age_analysis').modal('show');
		}
		this.isAgeAnalysisView = e;
	}

	scroll(el: HTMLElement) {
		el.scrollIntoView();
	}

	public toggleAgeAnalysisView = (): boolean => (this.isAgeAnalysisView = !this.isAgeAnalysisView);

	setViewData(e) {
		this.AgeAnalysisData = e;
	}

	addressURL() {
		return this.entity?.gps_coord1 && this.entity.gps_coord2 ? `https://www.google.com/maps/search/?api=1&query=${this.entity.gps_coord1},${this.entity.gps_coord2}` : '#';
	}

	hasSomeAddress() {
		return this.entity?.address || this.entity?.suburb || this.entity?.city;
	}

	navToClient() {
		if (this.entity.domains) {
			const URL = this.entity.domains[0];
			window.open(`https://${URL}`, '_blank');
		} else {
			window.open(`https://${this.entity.domain}.${environment.client}`, '_blank');
		}
	}

	navigateToTab(tabName) {
		// TODO: Just double check here
		this._router.navigate([`/${this.entityId}` + this.config.entityEditUrl], { queryParams: { tab: tabName } });
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
}
