import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState, EntityState } from '../app.state';

export const selectEntityId = createSelector(
	(state: AppState) => state.entity,
	(entity: EntityState) => entity.id
);

export const selectEntityData = createSelector(
	(state: AppState) => state.entity,
	(entity: EntityState) => entity.data
);

export const selectEntityPrefix = createSelector(
	(state: AppState) => state.entity,
	(entity: EntityState) => entity.whitfieldsPrefix
);

export const selectProductAllowedEntities = createSelector(
	(state: AppState) => state.entity,
	(entity: EntityState) => entity.allowedEntities
);
