import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';

import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy.service';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
		runGuardsAndResolvers: 'always',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
	providers: [
		{
			provide: RouteReuseStrategy,
			useClass: CustomRouteReuseStrategy,
		},
	],
})
export class AppRoutingModule {}
