export class Task {
	public subject: string;
	public description: string;
	public dueDate: Date;
	public active: boolean;
	public status: any;
	public category: string;
	public priority: number;
	public assignedToId: string;
	public assignedToName: string;
	public created: Date;
	public createdBy: string;
	public createdByName: string;
	public id?: string;
	public tags?: any;
	public refNo?: string;
	public updatedByName: string;
	public updatedBy: string;
	public type: string;
	public paymentTo: string;

	constructor() {
		this.created = new Date();
		this.active = true;
		this.dueDate = new Date();
		this.status = 'pending';
		this.category = 'general';
		this.priority = 3;
	}

	toObject() {
		return Object.assign({}, this);
	}
}
