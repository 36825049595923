import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EventsService } from './services/events.service';
import { InitService } from './services/init.service';
import { SubgroupsService } from './services/subgroups.service';

@NgModule({
	providers: [SubgroupsService, EventsService, InitService],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}
}
