import { config } from './../../../../configs/configs';
import { environment } from '../../../../environments/environment';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
	buildId: String;
	footerYear = new Date().getFullYear();
	config = config[environment.product];
	product = environment.product;

	constructor() {}
	ngOnInit() {
		if (config.hasOwnProperty('buildDate')) {
			this.buildId = config.buildDate + '-' + config.gitId;
		} else {
			this.buildId = '0-0';
		}
	}
}
