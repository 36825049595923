import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { Journal } from '../journals/models/journal.model';
import { map, take, merge } from 'rxjs/operators';
import { JournalTemplate } from '../journals/models/journalTemplate.model';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { Account } from '../../plex/fin/models/account';
import { User } from '../../plex/users/user.model';
import { FirebaseApp } from '@angular/fire';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare const toastr: any;
declare let moment: any;

@Injectable()
export class JournalsService {
	account = new Subject<Account>();
	accountDoc: AngularFirestoreDocument<Account>;
	accountsCollection: AngularFirestoreCollection<Account>;
	journalFileDoc: AngularFirestoreDocument<any>;
	accounts: Observable<Account[]>;
	loggedInUser: User;
	journalDoc: any;
	entityId: string;
	public generatedId = new BehaviorSubject<any>(this.afs.createId());

	constructor(public afs: AngularFirestore, private auth: AuthenticationService, public router: Router, public fb: FirebaseApp, private store: Store) {
		this.store.select(selectEntityId).subscribe(id => {
			this.entityId = id;
		});

		this.auth.user.subscribe(userDetails => {
			this.loggedInUser = userDetails;
		});
	}

	getAccountsForEntity() {
		this.accountsCollection = this.afs
			.collection('entities')
			.doc(this.entityId)
			.collection('fin')
			.doc('accounts')
			.collection('list', ref => ref.orderBy('name', 'asc').where('active', '==', true));
		this.accounts = this.accountsCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Account;
					data.id = a.payload.doc.id;
					data.value = a.payload.doc.id; // FOR DROPDOWNS
					data.label = data.name; // FOR DROPDOWNS
					return data;
				});
			})
		);

		return this.accounts;
	}

	//GET FILES UPLOADED TO JOURNALS
	getJournalFiles(journaldId) {
		return this.afs
			.collection<any>(`journals/batches/list/${journaldId}/docs/`, ref => ref.orderBy('created', 'asc').where('active', '==', true))
			.valueChanges();
	}

	//DOWNLOAD FILE
	downloadJournalFile(filePath, fileName, fileType): void {
		// console.log(fileName);
		let storageRef = this.fb.storage().ref();
		// console.log('storageRef', storageRef);
		storageRef
			.child(filePath)
			.getDownloadURL()
			.then(url => {
				const xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';
				xhr.onload = event => {
					/* Create a new Blob object using the response
					 *  data of the onload object.
					 */
					const blob = new Blob([xhr.response], { type: fileType });
					const a: any = document.createElement('a');
					a.style = 'display: none';
					document.body.appendChild(a);
					const url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = fileName;
					a.click();
					window.URL.revokeObjectURL(url);
				};
				xhr.open('GET', url);
				xhr.send();
			})
			.catch(function (error) {
				// Handle any errors
				console.log(error);
			});
	}

	removeJournal(journalId) {
		const journalFileRef = this.afs.collection(`/journals/batches/list/${journalId}/docs`).ref;
		return journalFileRef
			.get()
			.then(docs => {
				docs.forEach(doc => {
					doc.ref.delete();
				});
			})
			.catch(error => {
				console.log('ERROR', error);
			});
	}

	//DELETE JOURNAL FILE
	deleteJournalFile(journalId, file) {
		this.journalFileDoc = this.afs.doc(`/journals/batches/list/${journalId}/docs/${file.ref.id}`);
		return this.journalFileDoc.update({ active: false }).then(() => {
			return Promise.all([]).then(() => {
				toastr.success('Removed File');
			});
		});
	}

	//SAVE BATCH TEMPLATE
	saveJournalTemplate(templateName: string, batchId: string) {
		// console.log("Setting batch to save as template Batch for : ", templateName, batchId);
		return this.afs.collection('entities/whitfields/journals/templates/list').add({
			batchId: batchId,
			templateName: templateName,
			createdBy: this.loggedInUser.uid,
		});
	}

	getJournalTemplates() {
		return this.afs
			.collection('entities/whitfields/journals/templates/list')
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as JournalTemplate;
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}

	fetchUser(userId) {
		const userDoc = this.afs.doc(`users/${userId}`);
		return userDoc.valueChanges();
	}

	getJournals() {
		const tmpDate = new Date();
		const startDate = moment(tmpDate).subtract(2, 'week').toDate();

		const newJournalsRef = this.afs.collection('/journals/batches/list', ref => ref.where('status', '==', 'new').where('created', '>', startDate)).valueChanges();
		const closedJournalsRef = this.afs.collection('/journals/batches/list', ref => ref.where('status', '==', 'closed').where('created', '>', startDate)).valueChanges();

		return combineLatest<any[]>(newJournalsRef, closedJournalsRef).pipe(
			map(a => {
				const combinedList = a.reduce((acc, cur) => acc.concat(cur));

				return combinedList.map(journal => {
					this.fetchUser(journal.createdBy)
						.pipe(take(1))
						.subscribe((user: any) => {
							journal.createdByName = `${user.firstname} ${user.surname}`;
						});
					return journal;
				});
			})
		);
	}

	getAllJournals(value) {
		return this.afs.collection('/journals/batches/list', ref => ref.where('journalReference', '==', value)).valueChanges();
	}

	getJournalTemplateDetails(templateId: string) {
		return this.afs.collection('entities/whitfields/journals/templates/list').doc(templateId).valueChanges();
	}

	getJournalTemplateEntries(templateId: string) {
		return this.afs.collection('entities/whitfields/journals/templates/list').doc(templateId).collection('entries').valueChanges();
	}

	generateId() {
		return this.generatedId.next(this.afs.createId());
	}

	addJournal(journalData: Journal, generatedId: string) {
		let reason = '';

		if (journalData.type === 'legal') {
			reason = 'Legal Jounral';
		}

		if (journalData.reason) {
			reason = journalData.reason;
		}
		// SETU JOURNAL DATA
		const journal = {
			id: generatedId,
			createdBy: this.loggedInUser.uid,
			createdByName: `${this.loggedInUser.firstname} ${this.loggedInUser.surname}`,
			created: new Date(),
			budgetMonth: journalData.budgetMonth,
			entityId: journalData.entityId,
			entityPrefix: journalData.entityPrefix,
			reason: reason,
			status: 'new',
			type: journalData.type,
		};

		const osDate = new Date().toISOString().slice(0, 10);

		// SETUP JOURNAL ENTRIES
		let entries = [];

		// NORMAL
		if (journalData.journal === 'advertising') {
			entries = [
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: `WPM/${journalData.journalType}`,
					description: `${journalData.reference} ${journalData.accountName} ${osDate}`,
					debit: null,
					credit: +journalData.amount,
				},
			];
		}
		if (journalData.journal === 'charge-to-owner') {
			entries = [
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: 'WPM/8400',
					description: `${journalData.reference} ${journalData.accountName}`,
					debit: null,
					credit: +journalData.amount,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/8400`,
					description: `${journalData.reference} to ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/${journalData.journalType}`,
					description: `${journalData.accountName}`,
					debit: null,
					credit: +journalData.amount,
				},
			];
		}
		if (journalData.journal === 'gl-test') {
			entries = [
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: 'Direct Deposit Received',
					debit: null,
					credit: +journalData.amount,
				},
			];
		}
		if (journalData.journal === 'incorrect-allocation-complexes') {
			entries = [
				{
					date: journalData.date,
					account: journalData.accountName2,
					description: journalData.reference,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: 'Direct Deposit Received',
					debit: null,
					credit: +journalData.amount,
				},
			];
		}
		if (journalData.journal === 'os-allocation') {
			entries = [
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: `WPM/${journalData.journalType}`,
					description: `${journalData.reference} ${journalData.accountName} ${osDate}`,
					debit: null,
					credit: +journalData.amount,
				},
			];
		}
		if (journalData.journal === 'reversal-to-owner') {
			entries = [
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/${journalData.journalType}`,
					description: `${journalData.reference} ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/8400`,
					description: `${journalData.reference} ${journalData.accountName}`,
					debit: null,
					credit: +journalData.amount,
				},
				{
					date: journalData.date,
					account: 'WPM/8400',
					description: `${journalData.reference} ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference,
					debit: null,
					credit: +journalData.amount,
				},
			];
		}
		if (journalData.journal === 'reversal') {
			entries = [
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/${journalData.journalType}`,
					description: `${journalData.reference} ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference,
					debit: null,
					credit: +journalData.amount,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/2750`,
					description: `${journalData.reference} to ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/${journalData.journalType2}`,
					description: `${journalData.reference} ${journalData.accountName}`,
					debit: null,
					credit: +journalData.amount,
				},
			];
		}
		if (journalData.journal === 'transfers') {
			entries = [
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference2,
					debit: +journalData.amount2,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference3,
					debit: +journalData.amount3,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference4,
					debit: +journalData.amount4,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: journalData.reference5,
					debit: +journalData.amount5,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName2,
					description: journalData.reference,
					debit: null,
					credit: +journalData.amount,
				},
				{
					date: journalData.date,
					account: journalData.accountName2,
					description: journalData.reference2,
					debit: null,
					credit: +journalData.amount2,
				},
				{
					date: journalData.date,
					account: journalData.accountName2,
					description: journalData.reference3,
					debit: null,
					credit: +journalData.amount3,
				},
				{
					date: journalData.date,
					account: journalData.accountName2,
					description: journalData.reference4,
					debit: null,
					credit: +journalData.amount4,
				},
				{
					date: journalData.date,
					account: journalData.accountName2,
					description: journalData.reference5,
					debit: null,
					credit: +journalData.amount5,
				},
			];
		}

		// LEGALS
		if (journalData.journal === 'legal-fees') {
			entries = [
				{
					date: journalData.date,
					account: journalData.accountName,
					description: `Legal Fees ${journalData.attorneyName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.attorneyName,
					description: `${journalData.reference} ${journalData.accountName}`,
					debit: null,
					credit: +journalData.amount,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/3070`,
					description: `${journalData.attorney} ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/8400`,
					description: `${journalData.attorney} ${journalData.accountName}`,
					debit: null,
					credit: +journalData.amount,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/8400`,
					description: `Legal fees billed to ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/3070`,
					description: `Legal fees billed to ${journalData.accountName}`,
					debit: null,
					credit: +journalData.amount,
				},
			];
		}
		if (journalData.journal === 'lod-reversal') {
			entries = [
				{
					date: journalData.date,
					account: journalData.accountName,
					description: `Letter of demand rev - ${journalData.attorney}`,
					debit: null,
					credit: 362.25,
				},
				{
					date: journalData.date,
					account: journalData.attorneyName,
					description: `Letter of demand rev - ${journalData.accountName}`,
					debit: 242.25,
					credit: null,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/5110`,
					description: `${journalData.attorney} ${journalData.accountName}`,
					debit: 120.0,
					credit: null,
				},
			];
		}

		if (journalData.journal === 'legal-fee-reversal-scheme') {
			entries = [
				{
					date: journalData.date,
					account: `WPM/8730`,
					description: `Legal Fees Reversal`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: `Legal Fees Reversal - ${journalData.accountName}`,
					debit: null,
					credit: +journalData.amount,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/3070`,
					description: `Legal Fees Reversal - ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: `${journalData.entityPrefix}/8225`,
					description: `Legal Fees Reversal`,
					debit: null,
					credit: +journalData.amount,
				},
			];
		}

		if (journalData.journal === 'legal-fee-reversal-attorney') {
			entries = [
				{
					date: journalData.date,
					account: `${journalData.attorney}`,
					description: `Legal Fees Reversal - ${journalData.accountName}`,
					debit: +journalData.amount,
					credit: null,
				},
				{
					date: journalData.date,
					account: journalData.accountName,
					description: `Legal Fees Reversal`,
					debit: null,
					credit: +journalData.amount,
				},
			];
		}

		const batchesDoc = this.afs.doc(`journals/batches/list/${generatedId}`);
		return batchesDoc.set(journal).then(batchResult => {
			let promiseList = [];
			return entries.forEach(entryDetails => {
				entryDetails.batchId = generatedId;
				var batchEntriesRef = batchesDoc.collection('entries');
				promiseList.push(batchEntriesRef.add(entryDetails).then(() => {}));
			});
		});
	}

	cloneJournal(journal: any, entries) {
		const batchesCollection = this.afs.collection('journals/batches/list');
		journal.createdBy = this.loggedInUser.uid;
		journal.createdByName = `${this.loggedInUser.firstname} ${this.loggedInUser.surname}`;
		journal.created = new Date();
		const originalPost = journal.postNow;
		journal.postNow = false;
		journal.isPending = true;
		journal.isPosted = false;
		journal.referenceNumber = 'Pending';
		journal.active = true;
		return batchesCollection.add(journal).then(batchResult => {
			batchesCollection.doc(batchResult.id).set({ id: batchResult.id }, { merge: true });
			let promiseList = [];
			entries.forEach(entryDetails => {
				entryDetails.batchId = batchResult.id;
				var entitiesBatchEntriesRef = batchesCollection.doc(batchResult.id).collection('entries');
				promiseList.push(entitiesBatchEntriesRef.add(entryDetails).then(() => {}));
			});

			return Promise.all(promiseList).then(() => {
				if (originalPost === true) {
					this.postJournal(batchResult.id).then(() => {
						return Promise.resolve(batchResult.id);
					});
				} else {
					return Promise.resolve(batchResult.id);
				}
			});
		});
	}

	postJournal(journalId) {
		return this.afs.collection('/journals/batches/list').doc(journalId).update({ postNow: true });
	}

	//FETCH JOURNAL DETAILS
	fetchJournalDetails(journalId: string) {
		this.journalDoc = this.afs.collection('/journals/batches/list').doc(journalId);
		return this.journalDoc.valueChanges();
	}

	//FETCH ACCOUNT DETAILS
	fetchAccountDetails(accountId: string) {
		this.accountDoc = this.afs.doc(`entities/${this.entityId}/fin/accounts/list/${accountId}`);
		return this.accountDoc.valueChanges();
	}

	//UPDATE ACCOUNT DETAILS
	updateAccount(account: Account) {
		this.accountDoc = this.afs.collection('entities').doc(this.entityId).collection('fin').doc('accounts').collection('list').doc(account.id);
		return this.accountDoc.update(account).then(() => {
			toastr.success('Account has been updated!');
		});
	}

	//FETCH JOURNAL ENTRIES OF JOURNAL BATCHES
	fetchJournalEntries(journalId: string) {
		return this.afs
			.collection(`/journals/batches/list/${journalId}/entries`)
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as any;
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}

	//UPDATE BATCH
	updateBatch(journalId: string, journal: Journal, entries) {
		console.log('TCL: JournalsService -> updateBatch -> entries', entries);
		this.journalDoc = this.afs.doc(`/journals/batches/list/${journalId}`);
		return this.journalDoc
			.update(journal)
			.then(() => {
				//DELETE ALL THE PREVIOUS ENTRIES
				let entitiesBatchEntriesRef = this.journalDoc.collection('entries').ref;
				entitiesBatchEntriesRef
					.get()
					.then(snapshot => {
						if (!snapshot.empty) {
							// console.log("delete entries :");
							let oldEntriesPromiseList = [];
							snapshot.docs.forEach(doc => {
								oldEntriesPromiseList.push(doc.ref.delete());
							});
							return Promise.all(oldEntriesPromiseList).then(() => {
								Promise.resolve();
							});
						} else {
							return Promise.resolve();
						}
					})
					.then(() => {
						if (entries.length > 0) {
							let promiseList = [];
							entries.forEach(entryDetails => {
								// console.log("Adding entries for " + entityId, entryDetails.accountId, batchId);
								entryDetails.journalId = journalId;
								promiseList.push(entitiesBatchEntriesRef.add(entryDetails));
							});
							return Promise.all(promiseList).then(() => {
								Promise.resolve();
							});
						} else {
							return Promise.resolve();
						}
					});
			})
			.catch(error => {
				return Promise.reject('error' + error);
			});
	}

	async deleteJournal(journalId) {
		const batchRef = this.afs.doc(`/journals/batches/list/${journalId}`);
		await batchRef.update({
			status: 'deleted',
		});
	}

	//FETCH ENTITES FOR JOURNALS
	fetchEntities() {
		return this.afs.collection('entities', ref => ref.where('product', '==', 'whitfields').orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	fetchAccountsForEntity(entityId) {
		return this.afs.collection(`/entities/${entityId}/fin/debtors/list`, ref => ref.orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	//FETCH ATTORNEYS FOR DROPDOWN IN JOURNALS LEGAL SECTION
	fetchAttorneys() {
		return this.afs.collection('/entities/whitfields/serviceProviders', ref => ref.where('type', '==', 'attorney').orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	//PROCESS THE JOURNALS TO RUN
	async processJournals() {
		const requestData = {
			createdBy: this.loggedInUser.uid,
			createdByName: `${this.loggedInUser.firstname}  ${this.loggedInUser.surname}`,
			createdByEmail: this.loggedInUser.email,
			request: 'processJournals',
		};

		await this.afs.collection('pending').add(requestData);
	}

	processJournalsStatus() {
		return this.afs.collection(`/journals/processing/list`).valueChanges({ idField: 'id' });
	}
}
