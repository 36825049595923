import { createReducer, on, Action } from '@ngrx/store';
import { EntityState } from '../app.state';

import { setEntityData, setEntityId, setEntityWhitfieldsPrefix, setProductAllowedEntities } from './entity.actions';

export const initialState: Readonly<EntityState> = { id: '', whitfieldsPrefix: '', allowedEntities: [], data: {} };

export const entityReducer = createReducer(
	initialState,
	on(setEntityId, (state, { entityId }) => {
		return { ...state, id: entityId };
	}),
	on(setEntityWhitfieldsPrefix, (state, { whitfieldsPrefix }) => {
		return { ...state, whitfieldsPrefix: whitfieldsPrefix };
	}),
	on(setProductAllowedEntities, (state, { allowedEntities }) => {
		return { ...state, allowedEntities: allowedEntities };
	}),
	on(setEntityData, (state, { entityData }) => {
		return { ...state, data: { ...entityData } };
	})
);
