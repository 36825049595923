/*
	YYYY-MM-DD

	https://stackoverflow.com/a/51448473/13180651
	FIXME: type LampyPayloadDate = `${number}${number}${number}${number}-${number}${number}-${number}${number}`;
*/
type LampyPayloadDate = string;
const lampyPayloadDateFormat = 'YYYY-MM-DD';

/*
	whitfieldsPrefix/accountNumber
	e.g. AMV/8400, VTY/8400
	
	https://stackoverflow.com/a/51448473/13180651
	FIXME: type LampyPayloadAccount = `${string}${string}${string}/${number}${number}${number}${number}`;
*/
type LampyPayloadAccount = string;

type LampyPayloadAccountType = 'debtors' | 'postar' | 'postgl';

interface LampySchemeAccountBalancePayload {
	endDate: LampyPayloadDate;
	account: LampyPayloadAccount;
	accountType: LampyPayloadAccountType;
}

interface LampyAccountTransactionsPayload {
	startDate: LampyPayloadDate;
	endDate: LampyPayloadDate;
	account: LampyPayloadAccount;
	accountType: LampyPayloadAccountType;
}

interface LampyAccountsPayload {
	accountType: LampyPayloadAccountType;
	scheme: string;
}

interface SchemeBalancesData {
	prefix: string;
	accounts: any;
	endDate: string;
	buffer: string;
	amountBuffer: string | number;
	overdraft: string | number;
	accountType: string;
}

interface TrialBalanceData {
	prefix: string;
	date: string;
	financialYearStart: string;
}

interface LampyFireflyStatementPayload {
	name?: string;
	startDate?: LampyPayloadDate;
	endDate?: LampyPayloadDate;
	accountId?: LampyPayloadAccount;
	accountName?: LampyPayloadAccount;
	entityId?: string;
	statementType?: string;
}

interface LampyGeneralLedgerPayload {
	startDate: LampyPayloadDate;
	endDate: LampyPayloadDate;
	accounts: LampyPayloadAccount;
	accountType: LampyPayloadAccountType;
}

type WhitfieldsCallLampyPayload =
	| LampySchemeAccountBalancePayload
	| LampyAccountTransactionsPayload
	| LampyAccountsPayload
	| SchemeBalancesData
	| LampyGeneralLedgerPayload
	| TrialBalanceData
	| LampyFireflyStatementPayload;

export {
	lampyPayloadDateFormat,
	WhitfieldsCallLampyPayload,
	LampyPayloadDate,
	LampyPayloadAccount,
	LampyPayloadAccountType,
	LampySchemeAccountBalancePayload,
	LampyAccountTransactionsPayload,
	LampyAccountsPayload,
	LampyGeneralLedgerPayload,
	SchemeBalancesData,
	TrialBalanceData,
	LampyFireflyStatementPayload,
};
