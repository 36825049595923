<div class="m-portlet m-portlet--head-sm">
	<div class="m-portlet__head">
		<div class="m-portlet__head-caption">
			<div class="m-portlet__head-title">
				<h3 class="m-portlet__head-text">Managers</h3>
			</div>
		</div>
		<div class="m-portlet__head-tools">
			<ul class="m-portlet__nav">
				<li
					*ngxPermissionsOnly="['admin', 'editEntityManagers']"
					class="m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
					m-dropdown-toggle="hover"
					aria-expanded="true"
				>
					<a href="#" class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill m-dropdown__toggle">
						<i class="la la-ellipsis-v"></i>
					</a>
					<div class="m-dropdown__wrapper" style="z-index: 101">
						<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" style="left: auto; right: 21.5px"></span>
						<div class="m-dropdown__inner">
							<div class="m-dropdown__body">
								<div class="m-dropdown__content">
									<ul class="m-nav">
										<li class="m-nav__item">
											<a href="#" class="m-nav__link" (click)="emailManagment()">
												<i class="m-nav__link-icon flaticon-email"></i>
												<span class="m-nav__link-text">Email All Managers</span>
											</a>
										</li>

										<li class="m-nav__item">
											<a href="#" class="m-nav__link" (click)="smsManagment()">
												<i class="m-nav__link-icon la la-mobile"></i>
												<span class="m-nav__link-text">SMS All Managers</span>
											</a>
										</li>
										<hr />
										<li class="m-nav__item">
											<a class="m-nav__link" (click)="addManagerSectionToggle()">
												<i class="m-nav__link-icon la la-plus"></i>
												<span class="m-nav__link-text">Add Manager</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="m-portlet__body">
		<span *ngFor="let user of users">
			<div *ngIf="!showAddSection && !showAddProfileSection && !showRemoveProfileSection && !addingSpinner" class="row p-1">
				<ng-container *ngIf="users?.length > 0">
					<div class="col-xl-10">
						<small
							><b>{{ user?.firstname }} {{ user?.surname }}</b></small
						>
						<span *ngIf="user.isAdmin" class="fa fa-star m--font-danger lp"></span>
						<span *ngIf="!user.isSchemeExecutive"
							><b class="m--font-info fa- lp">{{ config?.managementTeamSchemeManagerIcon }}</b></span
						>
						<span *ngIf="user.isViewOnly" class="la la-eye m--font-success lp"></span>
						<span *ngIf="user.receiveUserUpdates" class="la la-bell m--font-warning lp"></span>
						<br />
						<div *ngFor="let profile of user?.profiles; let i = index">
							<span>
								<small>*{{ profile }}</small>
							</span>
						</div>
						<span *ngIf="user?.profiles?.length === 0">
							<small class="no-profiles">[ has no profiles ]</small>
						</span>
					</div>
					<div class="col-xl-2">
						<div class="dropdown">
							<a
								class="btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only btn-sm m-btn--pill m-dropdown__toggle"
								data-toggle="dropdown"
								id="dropdownMenuButton"
							>
								<i class="la la-angle-down"></i>
							</a>
							<div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
								<a class="dropdown-item" routerLink="/{{ entityId }}/users/edit/{{ user.id }}">
									<i class="la la-edit"></i>
									View User
								</a>
								<a class="dropdown-item" ng-reflect-href="#" (click)="emailManager(user.email)">
									<i class="la la-envelope"></i>
									Email User
								</a>
								<a *ngIf="hasUsersCells" class="dropdown-item" ng-reflect-href="#" (click)="smsManager(user.cell)">
									<i class="la la-mobile"></i>
									SMS User
								</a>
								<hr *ngxPermissionsOnly="['admin', 'editEntityManagers']" />
								<ng-template [ngxPermissionsOnly]="['admin', 'editEntityManagers']">
									<a
										*ngIf="user.isSchemeManager"
										class="dropdown-item m--font-danger"
										(click)="setProfile(user, 'isSchemeManager', false)"
										style="cursor: pointer"
									>
										<i class="la la-times-circle m--font-danger"></i>
										Revoke Scheme Manager
									</a>
									<hr />
									<a class="dropdown-item" (click)="showAddProfilesSection(user)" style="cursor: pointer">
										<i class="la la-plus"></i>
										Add *Profile
									</a>
									<a
										*ngIf="user?.profiles && user?.profiles?.length > 0"
										class="dropdown-item m--font-danger"
										(click)="showRemoveProfilesSection(user)"
										style="cursor: pointer"
									>
										<i class="la la-trash m--font-danger"></i>
										Remove *Profile
									</a>
								</ng-template>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</span>

		<span *ngIf="showAddSection">
			<h5>Add Existing User</h5>
			<form [formGroup]="addExistingUserForm">
				<label
					>User
					<span class="m--font-danger">*</span>
				</label>
				<ng-select
					class="custom"
					[items]="existingUsersList"
					bindLabel="fullName"
					bindValue="id"
					placeholder="Select a user"
					name="existingUserID"
					required
					formControlName="existingUserID"
				>
				</ng-select>

				<div class="form-group m-form__group">
					<label class="mt-2">
						Type
						<span *ngIf="envType !== 'whitfields'" class="m--font-danger">*</span>
					</label>
					<ng-select
						class="custom"
						[items]="types"
						bindLabel="name"
						bindValue="value"
						placeholder="Select a type"
						name="existingUserType"
						formControlName="existingUserType"
					>
					</ng-select>
				</div>
				<div class="m-form__group form-group">
					<!-- <label for="">Inline Radios</label> -->
					<div class="m-radio-inline">
						<label class="m-radio">
							<input type="radio" name="permission" formControlName="permission" value="admin" /> Admin
							<span></span>
						</label>
						<!-- <label class="m-radio">
                  <input type="radio" name="example_3" value="2"> Option 2
                  <span></span>
                </label>
                <label class="m-radio">
                  <input type="radio" name="example_3" value="3"> Option 3
                  <span></span>
                </label> -->
					</div>
					<!-- <span class="m-form__help">Some help text goes here</span> -->
				</div>

				<button *ngIf="!addExistingLoader" class="btn btn-brand btn-sm" (click)="addExistsingUser(addExistingUserForm.value)" [disabled]="!addExistingUserForm.valid">
					Add User
				</button>
				<button *ngIf="addExistingLoader" disabled class="btn btn-info btn-sm m-loader m-loader--light m-loader--right" ng-reflect-href="#">Adding</button>
			</form>
			<hr />
			<div>
				<button type="button" class="btn btn-default btn-sm mt-2 mb-3" (click)="addManagerSectionToggle()">Cancel</button>
			</div>
		</span>
		<span *ngIf="addingSpinner">
			<h5 class="text-center">
				Adding
				<span class="fa fa-spinner fa-spin"></span>
			</h5>
		</span>
		<span *ngIf="showAddProfileSection" class="mt-3">
			<h6>Add Profile to {{ user.firstname }} {{ user.surname }}</h6>
			<form [formGroup]="addProfileForm">
				<hr />
				<div class="form-group m-form__group">
					<label>
						Type
						<span class="m--font-danger">*</span>
					</label>
					<select class="form-control" id="addProfileType" name="addProfileType" formControlName="addProfileType" required>
						<option value="">--- Select Type ---</option>
						<option *ngFor="let option of types" [value]="option?.value">{{ option?.name }}</option>
					</select>
				</div>

				<div *ngIf="addProfileOtherSelected" class="form-group m-form__group">
					<label for="exampleInputEmail1">Specify Profile Type <span class="m--font-danger">*</span></label>
					<input
						id="addProfileTypeOther"
						name="addProfileTypeOther"
						maxlength="35"
						formControlName="addProfileTypeOther"
						type="text"
						class="form-control m-input"
						placeholder="Enter Other Type"
					/>
				</div>

				<button
					*ngIf="!addProfileOtherSelected"
					class="btn btn-sm btn-outline-info btn-hover-text"
					(click)="addProfile(addProfileForm.value)"
					[disabled]="!addProfileForm.valid"
				>
					<i class="la la-plus"></i> Add Profile
				</button>

				<button
					*ngIf="addProfileOtherSelected"
					class="btn btn-sm btn-outline-info btn-hover-text"
					(click)="addProfile(addProfileForm.value)"
					[disabled]="!addProfileForm.valid || addProfileForm.controls['addProfileTypeOther'].value === ''"
				>
					<i class="la la-plus"></i> Add Profile
				</button>
			</form>
			<hr />
			<div class="col-md-12">
				<button type="button" class="btn btn-default btn-sm" (click)="hideAddProfileSection()">Cancel</button>
			</div>
		</span>
		<span *ngIf="showRemoveProfileSection">
			<h6>Remove Profile</h6>
			<div class="m-list-timeline" *ngFor="let profile of user?.profiles; let i = index">
				<div class="m-list-timeline__items">
					<div class="m-list-timeline__item">
						<span class="m-list-timeline__badge"></span>
						<span class="m-list-timeline__text">
							<small
								><b>{{ user.firstname }} {{ user.surname }}</b
								>&nbsp;<br />
								{{ profile }}
							</small>
						</span>
						<a class="m-list-timeline__icon m--font-danger" title="Remove Profile" (click)="removeProfile(user, i)" style="cursor: pointer">
							<i class="fa fa-times"></i>
						</a>
					</div>
				</div>
			</div>
			<hr />
			<button type="button" class="btn btn-default" (click)="hideRemoveProfileSection()">Close</button>
		</span>
		<hr />
		<div class="col-md-12 pl-0">
			<small>
				<span class="m--font-info" style="padding-right: 3%">
					<b>{{ config?.managementTeamSchemeManagerIcon }}</b> {{ config?.managementTeamSchemeManagerText }}
				</span>
			</small>
		</div>
	</div>
</div>
