import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrefPreventDefaultDirective } from './_directives/href-prevent-default.directive';
import { MetronicModalComponent } from './components/metronic-modal/metronic-modal.component';

@NgModule({
	imports: [CommonModule],
	declarations: [HrefPreventDefaultDirective, MetronicModalComponent],
	exports: [HrefPreventDefaultDirective, MetronicModalComponent],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [],
		};
	}
}
