import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Injectable } from '@angular/core';
import { map, take, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

import { Player, HistoryEntry } from './player.model';
import { Team } from '../teams/team.model';
import { TeamsService } from '../teams/teams.service';
import { User } from '../../plex/users/user.model';
import { UsersService } from '../../plex/users/users.service';
import { AuditLogService } from '../../plex/audit-log/audit-log.service';
import { GenerateThumbnailService } from '../../plex/_services/generate-thumbnail.service';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';
declare var toastr: any;

@Injectable()
export class PlayersService {
	entityPlayersCollection: AngularFirestoreCollection<Player[]>;
	playerDoc: AngularFirestoreDocument<Player>;
	usersCollection: AngularFirestoreCollection<User[]>;

	task: AngularFireUploadTask;
	progressBarValue;
	entityId: any;

	constructor(
		private auditLogService: AuditLogService,
		public afs: AngularFirestore,
		public router: Router,
		public usersService: UsersService,
		public teamsService: TeamsService,
		private storage: AngularFireStorage,
		public generateThumbnailService: GenerateThumbnailService,
		private store: Store
	) {
		this.store.select(selectEntityId).subscribe(entityId => {
			this.entityId = entityId;
		});
	}

	fetchEntityPlayers() {
		if (this.entityId) {
			this.entityPlayersCollection = this.afs.collection(`entities/${this.entityId}/players`, ref => ref.where('active', '==', true));
			const playersRef = this.afs.doc(`entities/${this.entityId}`).ref;
			return this.entityPlayersCollection.snapshotChanges().pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as any;
						data.fullname = `${data.firstname} ${data.lastname}`;
						data.uid = a.payload.doc.id;
						return data;
					});
				}),
				take(1)
			);
		}
	}

	addPlayer(player) {
		// console.log('TCL: player', player)

		// CHECK IF PLAYER ALREADY ADDED TO ENTITY
		this.entityPlayersCollection = this.afs.collection(`entities/${this.entityId}/players`, ref => ref.where('firstname', '==', player.firstname));

		return this.entityPlayersCollection
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as Player;
						data.uid = a.payload.doc.id;
						return data;
					});
				}),
				take(1)
			)
			.toPromise()
			.then(entityPlayersList => {
				// PLAYER NOT ADDED TO ENTITY SO CAN ADD PLAYER
				const playersRef = this.afs.collection(`entities/${this.entityId}/players`);

				const playerData = {
					active: true,
					firstname: player.firstname,
					lastname: player.lastname,
					age: player.age,
					idNumber: player.idNumber,
					sportsType: player.sportsType,
				};

				return playersRef
					.add(playerData)
					.then(ref => {
						const tmpPlayerId = ref.id;
						const entityRef = this.afs.doc(`entities/${this.entityId}`);
						let playersCount = 0;
						const playerName = `${player.firstname} ${player.lastname}`;
						if (player.img !== '') {
							this.uploadPlayerImage(this.entityId, player.img, playerName, tmpPlayerId);
						}

						// INCREMENT PROPERTIES COUNT
						entityRef
							.snapshotChanges()
							.pipe(take(1))
							.toPromise()
							.then(snap => {
								const entityData = snap.payload.data();
								playersCount = entityData['playersCount'];
								playersCount++;

								entityRef.set(
									{
										playersCount: playersCount,
									},
									{ merge: true }
								);
							});

						// AUDIT LOG
						let logData = {
							name: `${player.firstname} ${player.lastname}`,
							description: 'Player was added',
							type: 'added',
							category: 'players',
							created: Date.now(),
						};
						this.auditLogService.addAudit(logData);

						return tmpPlayerId;
					})
					.catch(err => {
						Promise.reject(err);
					});
			});
	}

	uploadPlayerImage(entityID, img, playerName, refId) {
		const imgName = playerName.replace(/\s/g, '_').toLowerCase();
		const fileName = `${new Date().getTime()}_${imgName}`;
		const path = `entities/${entityID}/players/${fileName}.png`;
		const ref = this.storage.ref(path);
		const task = ref.putString(img, 'data_url');

		return new Promise((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							this.generateThumbnailService.generateFromImage(img, 333, 443, 1, thumbnailData => {
								const file = {
									img: url,
									img_thumbnail: thumbnailData,
								};
								this.saveDataToFirestore(entityID, file, refId)
									.then(() => {
										return resolve('');
									})
									.catch(err => {
										return reject('Failed to save data to firestore: ' + err);
									});
							});
						});
					})
				)
				.subscribe();
		});
	}

	private saveDataToFirestore(entityID, downloadData, refId) {
		const entityRef = this.afs.doc(`entities/${entityID}/players/${refId}`);

		return entityRef.update(downloadData);
	}

	addTeamToPlayer(playerData: Team, playerId: string) {
		return this.teamsService.addTeam(playerData, playerId);
	}

	setPlayerInactive(player) {
		const entityRef = this.afs.collection('entities').doc(this.entityId).ref;
		const entityPlayerRef = this.afs.collection('entities').doc(this.entityId).collection('players').doc(player.uid);
		const playerUsersRef = this.afs.collection('entities').doc(this.entityId).collection('players').doc(player.uid).collection('users').ref;

		//REMOVE PLAYER FROM ENTITY
		const updateEntityPlayer = entityPlayerRef.update({
			active: false,
		});

		// Remove player from team
		let updateTeamPlayer;
		const playerTeamsSubscription = this.fetchPlayerTeams(player.uid).subscribe(teams => {
			teams.forEach(team => {
				const teamRef = this.afs.collection('entities').doc(this.entityId).collection('teams').doc(team['uid']).collection('players').doc(player.uid);
				updateTeamPlayer = teamRef.update({
					active: false,
				});
			});
		});

		// UPDATE ENTITY COUNT
		const updatePlayersCount = entityRef.get().then(entityDetails => {
			const entityData = entityDetails.data() as any;
			let playersCount = 0;

			if (entityData.playersCount) {
				playersCount = entityData.playersCount;
				playersCount--;
			}

			return entityRef.update({
				playersCount: playersCount,
			});
		});

		// GET PLAYER USERS
		const updatePlayerUsers = playerUsersRef.get().then(users => {
			return users.forEach(user => {
				const userData = user.data();

				return this.removeUserFromPlayer(userData.uid, player.uid, userData.type);
			});
		});

		return Promise.all([updateTeamPlayer, updateEntityPlayer, updatePlayersCount, updatePlayerUsers]).then(() => {
			toastr.success('Player removed successfully!');
			let logData = {
				name: player.name,
				description: 'Player was removed',
				type: 'remove',
				category: 'players',
				created: Date.now(),
			};
			playerTeamsSubscription.unsubscribe();
			this.auditLogService.addAudit(logData);
		});
	}

	fetchUserPlayers(userUID: string) {
		return this.afs
			.collection('users')
			.doc(userUID)
			.collection('entities')
			.doc(this.entityId)
			.collection('players', ref => ref.where('active', '==', true).orderBy('firstname', 'asc'))
			.valueChanges({ idField: 'uid' });
	}

	fetchPlayerUsers(playerUID: string) {
		return this.afs.collection(`entities/${this.entityId}/players/${playerUID}/users`, ref => ref.where('active', '==', true)).valueChanges();
	}

	fetchPlayerDetails(uid: string) {
		this.playerDoc = this.afs.doc(`entities/${this.entityId}/players/${uid}`);
		return this.playerDoc.valueChanges();
	}

	fetchTeam(teamId, playerId) {
		const teamPlayerDoc = this.afs.collection(`entities/${this.entityId}/teams`).doc(`${teamId}`);
		return teamPlayerDoc.valueChanges();
	}

	fetchAllTeams() {
		const teamsCollection = this.afs.collection(`entities/${this.entityId}/teams`, ref => ref.where('active', '==', true));

		return teamsCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as Team;
					data.uid = a.payload.doc.id;
					return data;
				});
			}),
			take(1)
		);
	}

	fetchPlayerTeams(playerId) {
		return this.afs.collection(`entities/${this.entityId}/players/${playerId}/teams`, ref => ref.where('active', '==', true)).valueChanges();
	}

	addExistingTeamToPlayer(team, playerId, player) {
		// add team to player
		return this.afs
			.collection(`entities/${this.entityId}/players`)
			.doc(`${playerId}`)
			.collection('teams')
			.doc(team.uid)
			.set({ name: team.name, uid: team.uid, active: true }, { merge: true })
			.then(() => {
				return this.afs.collection(`entities/${this.entityId}/teams/${team.uid}/players`).doc(`${playerId}`).set(
					{
						active: true,
						playerId: playerId,
						teamId: team.uid,
						firstname: player.firstname,
						lastname: player.lastname,
						position: player.position,
						age: player.age,
						number: player.number,
					},
					{ merge: true }
				);
			});

		// add player to team
	}

	removeTeamFromPlayer(teamId, playerId) {
		const playerDoc = this.afs.collection(`entities/${this.entityId}/players`).doc(`${playerId}/teams/${teamId}`);
		const teamPlayerDoc = this.afs.collection(`entities/${this.entityId}/teams/${teamId}/players`).doc(`${playerId}`);
		return playerDoc.set({ active: false }, { merge: true }).then(() => {
			return teamPlayerDoc.set({ active: false }, { merge: true });
		});
	}

	updatePlayer(player: Player) {
		const playerData = {
			firstname: player.firstname,
			lastname: player.lastname,
			age: player.age,
			sportsType: player.sportsType,
		};

		this.playerDoc = this.afs.doc(`entities/${this.entityId}/players/${player.uid}`);
		if (player.img !== '') {
			const playerName = `${player.firstname} ${player.lastname}`;
			this.uploadPlayerImage(this.entityId, player.img, playerName, player.uid);
		}
		return this.playerDoc
			.update(playerData)
			.then(() => {
				let logData = {
					name: `${player.firstname} ${player.lastname}`,
					description: 'Player was updated',
					type: 'update',
					category: 'players',
					created: Date.now(),
				};
				this.auditLogService.addAudit(logData);
			})
			.catch(error => {
				toastr.error('Player could not be updated! Please try again.');
			});
	}

	addUserToPlayer(userID: string, playerID: string, type: string, parent: boolean) {
		const userRef = this.afs.collection('users').doc(userID);
		const playerRef = this.afs.collection('entities').doc(this.entityId).collection('players').doc(playerID);
		let userData: User = {};

		// FETCH USER DETAILS
		return userRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(userDetails => {
				if (userDetails.payload.data()) {
					userData = userDetails.payload.data();

					// CHECK IF USER ALREADY ADDED TO PLAYER
					return playerRef
						.collection('users', ref => ref.where('uid', '==', userDetails.payload.id).where('active', '==', true))
						.snapshotChanges()
						.pipe(
							map(changes => {
								return changes.map(a => {
									const data = a.payload.doc.data() as User;
									data.uid = a.payload.doc.id;
									return data.uid;
								});
							}),
							take(1)
						)
						.toPromise()
						.then(usersUidCheckCollection => {
							if (usersUidCheckCollection.length === 0) {
								// CHECK IF TYPE ALREADY EXISTS
								return playerRef
									.collection('users', ref => ref.where('type', '==', type).where('active', '==', true))
									.snapshotChanges()
									.pipe(
										map(changes => {
											return changes.map(a => {
												const data = a.payload.doc.data() as User;
												data.uid = a.payload.doc.id;
												return data.uid;
											});
										}),
										take(1)
									)
									.toPromise()
									.then(usersCollection => {
										if (usersCollection.length === 0) {
											// ADD PLAYER REFERENCE TO USER PLAYERS COLLECTION
											return this.associateUserToPlayer(playerID, userID, type, parent, userData);
										} else {
											return Promise.reject(`A ${type} contact already exists on this player`);
										}
									})
									.catch(error => {
										toastr.error(error);
									});
							} else {
								return Promise.reject(`User already added to this player`);
							}
						})
						.catch(error => {
							toastr.error(error);
						});
				} else {
					// console.log("No user found!");
				}
			})
			.catch(function (error) {
				console.log('Error getting user:', error);
			});
	}

	associateUserToPlayer(playerID, userID, type, parent, userData) {
		const userRef = this.afs.collection('users').doc(userID);
		const playerRef = this.afs.collection('entities').doc(this.entityId).collection('players').doc(playerID);
		const addPlayerToUser = playerRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(playerDetails => {
				if (playerDetails.payload.data()) {
					const playerData = playerDetails.payload.data();
					// ASSOCIATE PLAYER TO USER
					userRef.collection('entities').doc(this.entityId).collection('players').doc(playerID).set(
						{
							firstname: playerData['firstname'],
							lastname: playerData['lastname'],
							ref: playerRef.ref,
							uid: playerID,
							type: type,
							parent: parent,
							active: true,
						},
						{ merge: true }
					);
				} else {
					// console.log("No player found!");
				}
			})
			.catch(function (error) {
				console.log('Error getting player:', error);
			});

		// SET BLANK CELL NUMBER IF UNDEFINED
		if (!userData.cell) {
			userData.cell = '';
		}

		// ADD USER REFERENCE TO PLAYER USERS COLLECTION
		const addUserToPlayer = playerRef.collection('users').doc(userID).set(
			{
				firstname: userData.firstname,
				surname: userData.surname,
				ref: userRef.ref,
				uid: userID,
				type: type,
				parent: parent,
				active: true,
				created: Date.now(),
				email: userData.email,
				cell: userData.cell,
			},
			{ merge: true }
		);

		return Promise.all([addPlayerToUser, addUserToPlayer]).then(() => {
			// SET PLAYER PRIMARY OR ALTERNATE OR TENANT USER FOR TABLE
			const fullname = userData.firstname + ' ' + userData.surname;
			let typeObj = {};

			typeObj[type] = fullname;
			playerRef.set(typeObj, { merge: true });

			let logData = {
				name: 'player',
				description: 'User associated to player',
				type: 'added',
				category: 'players',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}

	addNewUserToPlayer(user, player: Player) {
		const userData = {
			firstname: user.newUserFirstname,
			surname: user.newUserSurname,
			email: user.newUserEmail,
		};

		return this.usersService.addUser(userData).then(userUID => {
			// console.log('userUID after add', userUID);
			this.addUserToPlayer(userUID, player.uid, user.newUserType, user.newUserOccupant);
		});
	}

	removeUserFromPlayer(userID: string, playerID: string, type: string) {
		const userRef = this.afs.collection('users').doc(userID);
		const playerRef = this.afs.collection('entities').doc(this.entityId).collection('players').doc(playerID);

		const deactivateUserPlayer = userRef.collection('entities').doc(this.entityId).collection('players').doc(playerID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		const deactivatePlayerUser = playerRef.collection('users').doc(userID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		return Promise.all([deactivateUserPlayer, deactivatePlayerUser]).then(() => {
			let typeObj = {};

			typeObj[type] = '';
			playerRef.set(typeObj, { merge: true });

			let logData = {
				name: 'player',
				description: 'User removed from player',
				type: 'remove',
				category: 'players',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}

	editUserPlayerAssociation(userUID: string, playerUID: string, type: string, parent: boolean): Promise<any | void> {
		const playerUsersRef = this.afs.collection(`/entities/${this.entityId}/players/${playerUID}/users`, ref => ref.where('type', '==', type).where('active', '==', true));
		const playerUserRef = this.afs.doc(`/entities/${this.entityId}/players/${playerUID}/users/${userUID}`).ref;

		// CHECK IF TYPE CHANGED
		return playerUserRef.get().then(user => {
			const userData = user.data() as User;
			// console.log('userData', userData);

			if (userData.type === type) {
				// IF TYPE NOT CHANGED UPDATE PARENT
				return this.updatePlayerForUser(userUID, playerUID, type, parent);
			} else {
				// CHECK IF TYPE ALREADY EXISTS
				return playerUsersRef
					.snapshotChanges()
					.pipe(take(1))
					.toPromise()
					.then(usersCollection => {
						if (usersCollection.length === 0) {
							// TYPE DOES NOT EXIST
							// console.log("type does not exist");
							return this.updatePlayerForUser(userUID, playerUID, type, parent);
						} else {
							// TYPE EXISTS SO RETURN ERROR
							// console.log("Type already exists");
							return Promise.reject(`A ${type} contact already exists on this player`);
						}
					})
					.catch(error => {
						console.log('No users found with that type - ', error);
						toastr.error(error);
					});
			}
		});
	}

	updatePlayerForUser(userUID: string, playerUID: string, type: string, parent: boolean) {
		const userPlayerRef = this.afs.doc(`users/${userUID}/entities/${this.entityId}/players/${playerUID}`);
		const playerUserRef = this.afs.doc(`/entities/${this.entityId}/players/${playerUID}/users/${userUID}`);

		const updateUserPlayer = userPlayerRef.update({
			type: type,
			parent: parent,
		});

		const updatePlayerUser = playerUserRef.update({
			type: type,
			parent: parent,
		});

		return Promise.all([updateUserPlayer, updatePlayerUser]);
	}

	addHistoryLogToPlayer(playerLog, playerId, userId) {
		const playerHistoryCollection = this.afs.collection(`entities/${this.entityId}/players/${playerId}/history`);
		let logData = {
			playerId: playerId,
			userId: userId,
			created: Date.now(),
			changed: playerLog,
		};
		return playerHistoryCollection.add(logData).catch(err => {
			console.log(err);
		});
	}

	fetchPlayerHistory(playerId) {
		const historyCollection = this.afs.collection(`entities/${this.entityId}/players/${playerId}/history`, ref => ref.orderBy('created', 'desc'));

		return historyCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as HistoryEntry;
					data.id = a.payload.doc.id;
					return data;
				});
			}),
			take(1)
		);
	}

	fetchPlayerHistoryUser(userId) {
		const userDoc = this.afs.doc(`users/${userId}`);
		return userDoc.valueChanges();
	}
}
