import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class WodApiService {
	constructor(private http: HttpClient) {}

	getData(url) {
		const headers_object = new HttpHeaders();
		headers_object.append('Authorization', 'Basic ' + btoa('ksi:6q99zJQ7'));
		headers_object.append('Access-Control-Allow-Headers', '*');
		headers_object.append('Access-Control-Allow-Origin', '*');
		headers_object.append('Access-Control-Allow-Method', '*');
		headers_object.append('Access-Control-Allow-Credentials', 'true');
		headers_object.append('Content-Type', 'application/json');
		return this.http.get(url, { headers: headers_object });
	}

	getCSVData(url) {
		const headers_object = new HttpHeaders();
		headers_object.append('Authorization', 'Basic ' + btoa('ksi:6q99zJQ7'));
		headers_object.append('Access-Control-Allow-Headers', '*');
		headers_object.append('Access-Control-Allow-Origin', '*');
		headers_object.append('Access-Control-Allow-Method', '*');
		headers_object.append('Access-Control-Allow-Credentials', 'true');
		headers_object.append('Content-Type', 'text/plain; charset=utf-8');
		return this.http.get(url, { headers: headers_object, responseType: 'text' });
	}

	postData(url, data) {
		let postData = new FormData();
		for (var k in data) {
			postData.append(k, data[k]);
		}
		const headers_object = new HttpHeaders();
		headers_object.append('Authorization', 'Basic ' + btoa('ksi:6q99zJQ7'));
		headers_object.append('Access-Control-Allow-Headers', '*');
		headers_object.append('Access-Control-Allow-Origin', '*');
		headers_object.append('Access-Control-Allow-Method', '*');
		headers_object.append('Access-Control-Allow-Credentials', 'true');
		headers_object.append('Content', 'application/json');
		headers_object.append('Content-Type', 'application/x-www-form-urlencoded');

		//var postData = JSON.stringify(data);
		console.log('postData', postData);

		return this.http.post(url, postData, { headers: headers_object });
	}
}
