import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { map, take, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Subgroup } from 'src/app/_shared/subgroups/models/subgroup.model';
import { User } from 'src/app/theme/pages/default/plex/users/user.model';
import { UsersService } from 'src/app/theme/pages/default/plex/users/users.service';
import { AuthenticationService } from '@auth/_services';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';
declare var toastr: any;

@Injectable()
export class SubgroupsService<T extends Subgroup> {
	entityId;
	entitySubgroupsCollection: AngularFirestoreCollection<T[]>;
	subgroupDoc: AngularFirestoreDocument<T>;
	pendingCollection: AngularFirestoreCollection;
	currentUser: User;
	constructor(public afs: AngularFirestore, public usersService: UsersService, public auth: AuthenticationService, private store: Store) {
		this.pendingCollection = afs.collection('pending');
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		this.auth.user.subscribe(userDetails => {
			if (userDetails) {
				this.currentUser = userDetails;
			}
		});
	}

	fetchEntitySubgroups(entityId: string) {
		this.entitySubgroupsCollection = this.afs.collection(`entities/${entityId}/subgroups`, ref => ref.where('active', '==', true));
		return this.entitySubgroupsCollection.valueChanges({ idField: 'uid' });
	}

	fetchSubgroupDetails(uid: string, entityId: string) {
		this.subgroupDoc = this.afs.doc(`entities/${entityId}/subgroups/${uid}`);
		return this.subgroupDoc.valueChanges();
	}

	addSubgroup(subgroup, entityId) {
		subgroup.active = true;
		subgroup.created = new Date();
		subgroup.modified = null;
		subgroup.createdBy = this.currentUser.uid;
		return this.afs
			.collection(`entities/${entityId}/subgroups`)
			.add(subgroup)
			.then(ref => {
				const tempId = ref.id;
				const { firstname, surname, uid } = this.currentUser;
				const pendingData = {
					request: 'subgroupAdded',
					entityId: entityId,
					subgroup: { ...subgroup, uid: tempId },
					user: { firstname, surname, uid },
					product: environment.product,
				};
				return this.pendingCollection.add(pendingData).then(() => {
					return tempId;
				});
			})
			.catch(err => {
				Promise.reject(err);
			});
	}

	updateSubgroup(subgroup: Subgroup, entityId: string) {
		const { firstname, surname, uid } = this.currentUser;
		const pendingData = {
			request: 'subgroupUpdated',
			entityId: entityId,
			subgroup: { ...subgroup },
			user: { firstname, surname, uid },
			product: environment.product,
		};
		subgroup.modified = new Date();
		const subgroupDoc = this.afs.doc(`entities/${entityId}/subgroups/${subgroup.uid}`).set(subgroup, { merge: true });
		const pendingRef = this.pendingCollection.add(pendingData);
		return Promise.all([subgroupDoc, pendingRef]);
	}

	addHistoryLogToSubgroup(subgroupLog, subgroupId, entityId) {
		const subgroupHistoryCollection = this.afs.collection(`entities/${entityId}/subgroups/${subgroupId}/history`);
		const logData = {
			subgroupId: subgroupId,
			userId: this.currentUser.uid,
			created: Date.now(),
			changed: subgroupLog,
		};
		return subgroupHistoryCollection.add(logData);
	}

	addNewUserToSubgroup(userValues, subgroupID: string, entityId: string) {
		const userData = {
			firstname: userValues.firstname,
			surname: userValues.surname,
			email: userValues.email,
		};

		return this.usersService.addUser(userData).then(userUID => {
			userValues.uid = userUID;
			this.addUserToSubgroup(userValues, subgroupID, entityId);
		});
	}

	addUserToSubgroup(userValues, subgroupId: string, entityId: string) {
		const userRef = this.afs.collection('users').doc(userValues.uid);
		const subgroupRef = this.afs.collection('entities').doc(entityId).collection('subgroups').doc(subgroupId);
		let userData: User = {};

		// FETCH USER DETAILS
		return userRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then(userDetails => {
				if (userDetails.payload.data()) {
					userData = userDetails.payload.data();

					// CHECK IF USER ALREADY ADDED TO MEMBER
					return subgroupRef
						.collection('users', ref => ref.where('uid', '==', userDetails.payload.id).where('active', '==', true))
						.snapshotChanges()
						.pipe(
							map(changes => {
								return changes.map(a => {
									const data = a.payload.doc.data() as User;
									data.uid = a.payload.doc.id;
									return data.uid;
								});
							}),
							take(1)
						)
						.toPromise()
						.then(usersUidCheckCollection => {
							if (usersUidCheckCollection.length === 0) {
								if (userValues.type !== '') {
									// CHECK IF TYPE ALREADY EXISTS
									return subgroupRef
										.collection('users', ref => ref.where('type', '==', userValues.type).where('active', '==', true))
										.snapshotChanges()
										.pipe(
											map(changes => {
												return changes.map(a => {
													const data = a.payload.doc.data() as User;
													data.uid = a.payload.doc.id;
													return data.uid;
												});
											}),
											take(1)
										)
										.toPromise()
										.then(usersCollection => {
											if (usersCollection.length === 0) {
												// ADD MEMBER REFERENCE TO USER MEMBERS COLLECTION
												return this.associateUserToSubgroup(userValues, userData, subgroupId, entityId);
											} else {
												return Promise.reject(`A ${userValues.type} contact already exists on this subgroup`);
											}
										})
										.catch(error => {
											toastr.error(error);
										});
								} else {
									return this.associateUserToSubgroup(userValues, userData, subgroupId, entityId);
								}
							} else {
								return Promise.reject(`User already added to this subgroup`);
							}
						})
						.catch(error => {
							toastr.error(error);
						});
				} else {
					return Promise.reject(`No user found!`);
				}
			})
			.catch(error => {
				toastr.error(error);
			});
	}

	associateUserToSubgroup(userValues, userData, subgroupId, entityId) {
		const { firstname, surname, uid } = this.currentUser;
		const subgroupRef = this.afs.collection('entities').doc(entityId).collection('subgroups').doc(subgroupId);
		const pendingData = {
			request: 'subgroupUserAdded',
			userValues: {
				...userValues,
				firstname: userData.firstname,
				surname: userData.surname,
			},
			subgroupId,
			entityId,
			user: { firstname, surname, uid },
			product: environment.product,
		};
		const subgroupUserDetails = {
			firstname: userData.firstname,
			surname: userData.surname,
			ref: `users/${userValues.uid}`,
			uid: userValues.uid,
			type: userValues.type,
			active: true,
			created: Date.now(),
			email: userData.email,
			cell: userData.cell || '',
		};
		const addUserToSubgroup = subgroupRef.collection('users').doc(userValues.uid).set(subgroupUserDetails, { merge: true });
		const addPendingDoc = this.pendingCollection.add(pendingData);
		return Promise.all([addUserToSubgroup, addPendingDoc]);
	}

	removeUserFromSubgroup(userId: string, subgroupId: string, type: string, entityId: string) {
		const { firstname, surname, uid } = this.currentUser;
		const subgroupRef = this.afs.collection('entities').doc(entityId).collection('subgroups').doc(subgroupId);
		const deactivatSubgroupUser = subgroupRef.collection('users').doc(userId).set(
			{
				active: false,
			},
			{ merge: true }
		);
		const pendingData = {
			request: 'subgroupUserRemoved',
			userValues: {
				uid: userId,
				type,
			},
			subgroupId,
			entityId,
			user: { firstname, surname, uid },
			product: environment.product,
		};
		const addPendingDoc = this.pendingCollection.add(pendingData);
		return Promise.all([deactivatSubgroupUser, addPendingDoc]);
	}

	fetchSubgroupUsers(subgroupUID: string) {
		return this.afs.collection(`entities/${this.entityId}/subgroups/${subgroupUID}/users`, ref => ref.where('active', '==', true)).valueChanges();
	}

	setSubgroupInactive(entityId, subgroupId) {
		const { uid: modifiedBy } = this.currentUser;
		const entitySubgroupRef = this.afs.collection('entities').doc(entityId).collection('subgroups').doc(subgroupId);
		return entitySubgroupRef.set(
			{
				active: false,
				modified: new Date(),
				modifiedBy,
			},
			{ merge: true }
		);
	}

	fetchUserSubgroups(userId: string, entityId: string) {
		return this.afs
			.collection('users')
			.doc(userId)
			.collection('entities')
			.doc(entityId)
			.collection('subgroups', ref => ref.where('active', '==', true).orderBy('name', 'asc'))
			.valueChanges({ idField: 'uid' });
	}

	editUserSubgroupAssociation(userId: string, subgroupId: string, type: string, entityId: string): Promise<any | void> {
		const subgroupUsersRef = this.afs.collection(`/entities/${entityId}/subgroups/${subgroupId}/users`, ref => ref.where('type', '==', type).where('active', '==', true));
		const subgroupUserRef = this.afs.doc(`/entities/${entityId}/subgroups/${subgroupId}/users/${userId}`).ref;

		// CHECK IF TYPE CHANGED
		return subgroupUserRef.get().then(user => {
			const userData = user.data() as User;

			if (userData.type === type) {
				// IF TYPE NOT CHANGED UPDATE PARENT
				return this.updateSubgroupForUser(userId, subgroupId, type, entityId);
			} else {
				// CHECK IF TYPE ALREADY EXISTS
				return subgroupUsersRef
					.snapshotChanges()
					.pipe(take(1))
					.toPromise()
					.then(usersCollection => {
						if (usersCollection.length === 0) {
							// TYPE DOES NOT EXIST
							return this.updateSubgroupForUser(userId, subgroupId, type, entityId);
						} else {
							// TYPE EXISTS SO RETURN ERROR
							return Promise.reject(`A ${type} contact already exists on this subgroup`);
						}
					})
					.catch(error => {
						console.log('No users found with that type - ', error);
						toastr.error(error);
					});
			}
		});
	}

	updateSubgroupForUser(userId: string, subgroupId: string, type: string, entityId: string) {
		const userSubgroupRef = this.afs.doc(`users/${userId}/entities/${entityId}/subgroups/${subgroupId}`);
		const subgroupUserRef = this.afs.doc(`/entities/${entityId}/subgroups/${subgroupId}/users/${userId}`);

		const updateUserSubgroup = userSubgroupRef.update({
			type: type,
		});

		const updateSubgroupUser = subgroupUserRef.update({
			type: type,
		});

		return Promise.all([updateUserSubgroup, updateSubgroupUser]);
	}
}
